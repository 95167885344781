import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PublicRoute = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const path = location.pathname;

  return (
    <>
      {token && path === "/application-form"
        ? localStorage.removeItem("token")
        : ""}
      {!token ? <Outlet /> : <Navigate to="/application-form" />}
    </>
  );
};

export default PublicRoute;
