import React from "react";
import Index from "../../components/users/AddUser";
const AddUser = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default AddUser;
