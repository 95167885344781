export const savePaymentButton = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#d52027",
  height: "100%",
  color: "white",
  padding: "10px",
  fontFamily: "Poppins-semibold",
  fontSize: "14px",
  // marginTop: "30px",
  borderRadius: 2,
  width: "200px",
  "&:hover": {
    backgroundColor: "red",
    transition: ".7s ease-in",
    boxShadow: 2,
  },
};
