import { Drawer, IconButton, List, Box } from "@mui/material";
import React, { useState } from "react";
import logo from "../assets/icons/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import {
  drawerResponsiveButton,
  responsiveSidebarListBg,
  sidebarDrawerResponsive,
} from "../utils/muiComponentStyles";
import Routes from "./routesList";
import SubMenu from "./SubMenu";
const SidebarResponsive = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Box>
        <Drawer
          PaperProps={{
            sx: {
              width: 240,
            },
          }}
          sx={sidebarDrawerResponsive}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          anchor="left"
          variant="temporary"
        >
          <Box
            component="img"
            sx={sidebarDrawerResponsive}
            alt="Logo"
            src={logo}
            style={{
              width: "40%",
            }}
          />
          <List sx={responsiveSidebarListBg}>
            {Routes.map((obj, index) => (
              <SubMenu sidebarData={obj} key={index} />
            ))}
          </List>
        </Drawer>
        <IconButton
          sx={drawerResponsiveButton}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon />
        </IconButton>
      </Box>
    </>
  );
};
export default SidebarResponsive;
