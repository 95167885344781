import React, { useContext, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  CardHeader,
  TextField,
  Box,
  SvgIcon,
  Stack,
} from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  applicationCardStyle,
  miniScrollBarStyles,
  textfield,
  textfieldDate,
  userHeading,
} from "../../../utils/muiComponentStyles";
import ToasterContext from "../../../utils/context/toasterContext";
import { applicantTypos } from "../../applicationRecord/applicationDetailForm/ApplicationDetailFormStyle";
import { updateContractor } from "../../../redux/slices/contractorSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedContractor = JSON.parse(
    localStorage.getItem("selectedContractor")
  );
  const [loader, setLoader] = useState(false);
  const [electricalObject, setElectricalObject] = useState(selectedContractor);
  const fireToasterContext = useContext(ToasterContext);
  const [electricalEmailError, setElectricalEmailError] = useState(false);
  const [electricalMasterEmailError, setElectrcialMasterEmailError] =
    useState(false);
  const [saveElectricalContractorPressed, setSaveElectricalContractorPressed] =
    useState(false);

  const handleElectricalChange = (event) => {
    setElectricalObject({
      ...electricalObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleApiResponse = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Update successfully"
      );
      navigate("/contractors");
      // localStorage.removeItem("selectedContractor");
    } else {
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Something went wrong"
      );
    }
  };
  const handleCreateElectricContractorSubmit = () => {
    setSaveElectricalContractorPressed(true);
    if (electricalObject?.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(electricalObject?.email)) {
        setElectricalEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setElectricalEmailError(false);
      }
    }
    if (electricalObject?.masterElectricianEmail) {
      var patternMasterEmail = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!patternMasterEmail.test(electricalObject?.masterElectricianEmail)) {
        setElectrcialMasterEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setElectrcialMasterEmailError(false);
      }
    }
    if (
      // electricalObject?.name &&
      electricalObject?.email &&
      electricalObject?.phoneNumber &&
      electricalObject?.licenseNumber &&
      electricalObject?.licenseExpirationDate &&
      electricalObject?.address &&
      electricalObject?.city &&
      electricalObject?.zip &&
      electricalObject?.masterElectricianName &&
      electricalObject?.masterElectricianEmail &&
      electricalObject?.masterElectricianPhoneNumber &&
      electricalObject?.masterElectricianLicenseNumber &&
      electricalObject?.masterElectricianLicenseExpirationDate &&
      electricalObject?.masterElectricianAddress &&
      electricalObject?.masterElectricianCity &&
      electricalObject?.masterElectricianZip
    ) {
      const finalElectricalContractorObject = {
        type: selectedContractor.type,
        id: electricalObject.id,
        name: null,
        email: electricalObject?.email,
        companyName: electricalObject?.companyName,
        phoneNumber: electricalObject?.phoneNumber,
        licenseNumber: electricalObject?.licenseNumber,
        licenseExpirationDate: electricalObject?.licenseExpirationDate,
        address: electricalObject?.address,
        city: electricalObject?.city,
        zip: electricalObject?.zip,
        masterElectricianName: electricalObject?.masterElectricianName,
        masterElectricianEmail: electricalObject?.masterElectricianEmail,
        masterElectricianPhoneNumber:
          electricalObject?.masterElectricianPhoneNumber,
        masterElectricianLicenseNumber:
          electricalObject?.masterElectricianLicenseNumber,
        masterElectricianLicenseExpirationDate:
          electricalObject?.masterElectricianLicenseExpirationDate,
        masterElectricianAddress: electricalObject?.masterElectricianAddress,
        masterElectricianCity: electricalObject?.masterElectricianCity,
        masterElectricianZip: electricalObject?.masterElectricianZip,
        temporary: false,
      };
      setLoader(true);
      dispatch(
        updateContractor(finalElectricalContractorObject, handleApiResponse)
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Electrical Contractor"
      );
    }
  };

  return (
    <Container>
      <Card>
        <CardHeader
          disableTypography
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>{`Update ${selectedContractor?.type} Contractor details`}</Box>
              <Box
                sx={{
                  display:
                    selectedContractor.temporary === false ? "none" : "flex",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <SvgIcon>
                  <ErrorOutlineRoundedIcon sx={{ color: "#d52127" }} />
                </SvgIcon>
                <Typography
                  sx={{ fontFamily: "Poppins-Medium", fontSize: "15px", ml: 1 }}
                >
                  {selectedContractor.temporary === true
                    ? "Add contractor to Database"
                    : null}
                </Typography>
              </Box>
            </Stack>
          }
          sx={applicationCardStyle}
        />
        <ValidatorForm
          style={{ width: "100%" }}
          onSubmit={handleCreateElectricContractorSubmit}
          onError={(errors) => console.log(errors)}
        >
          <CardContent
            sx={{
              mt: 2,
              mx: 2,
              height: "580px",
              overflowY: "auto",
              [`::-webkit-scrollbar`]: {
                width: "0px",
                background: "transparent",
              },
              ...miniScrollBarStyles,
            }}
          >
            <Grid container spacing={2} sx={{ my: "5px" }}>
              {/* Electrical name */}

              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={
                    !electricalObject?.name && saveElectricalContractorPressed
                  }
                  helperText={
                    !electricalObject?.name && saveElectricalContractorPressed
                      ? "Name is required"
                      : ""
                  }
                  id="electrical-contractor-name"
                  label="Electrical Contractor Name"
                  value={electricalObject?.name || ""}
                  name="name"
                  sx={textfield}
                  onChange={handleElectricalChange}
                />
              </Grid> */}

              {/* Electrical company name */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="electrical-contract-company"
                  label="Electrical Contractor Company"
                  value={electricalObject?.companyName || ""}
                  name="companyName"
                  sx={textfield}
                  onChange={handleElectricalChange}
                />
              </Grid>

              {/* Electrical company email */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="electrical-company-email"
                  label="Electrical Contractor Email"
                  type="email"
                  value={electricalObject?.email || ""}
                  name="email"
                  sx={textfield}
                  onChange={handleElectricalChange}
                  error={
                    !!electricalEmailError ||
                    (!electricalObject?.email &&
                      saveElectricalContractorPressed)
                  }
                  helperText={
                    electricalEmailError ||
                    (!electricalObject?.email &&
                      saveElectricalContractorPressed)
                      ? "Please enter a valid email address"
                      : ""
                  }
                />
              </Grid>

              {/* electrical phone number */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={
                    !electricalObject?.phoneNumber &&
                    saveElectricalContractorPressed
                  }
                  helperText={
                    !electricalObject?.phoneNumber &&
                    saveElectricalContractorPressed
                      ? "Phone number is required"
                      : ""
                  }
                  id="electrical-phone-number"
                  label="Electrical Contractor Phone No."
                  value={electricalObject?.phoneNumber || ""}
                  name="phoneNumber"
                  sx={textfield}
                  onChange={handleElectricalChange}
                />
              </Grid>

              {/* master electrical License number */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={
                    !electricalObject?.licenseNumber &&
                    saveElectricalContractorPressed
                  }
                  helperText={
                    !electricalObject?.licenseNumber &&
                    saveElectricalContractorPressed
                      ? "License number is required"
                      : ""
                  }
                  id="electrician-license-number"
                  label="Electrical Contractor License No."
                  value={electricalObject?.licenseNumber || ""}
                  name="licenseNumber"
                  sx={textfield}
                  onChange={handleElectricalChange}
                />
              </Grid>

              {/* electrician license expire date */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  error={
                    !electricalObject?.licenseExpirationDate &&
                    saveElectricalContractorPressed
                  }
                  helperText={
                    !electricalObject?.licenseExpirationDate &&
                    saveElectricalContractorPressed
                      ? "License expiration date is required"
                      : ""
                  }
                  id="electrician-license-expire-date"
                  value={electricalObject?.licenseExpirationDate?.split("T")[0]}
                  name="licenseExpirationDate"
                  onChange={handleElectricalChange}
                  disableUnderline
                  fullWidth
                  sx={textfieldDate}
                  label="License expiration date"
                  inputProps={{
                    max: "9999-12-31",
                  }}
                  type="date"
                  variant="filled"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>

              {/* electrical street address */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="electrical-street-address"
                  label="Electrical Contractor Street Address"
                  value={electricalObject?.address || ""}
                  name="address"
                  sx={textfield}
                  onChange={handleElectricalChange}
                  error={
                    !electricalObject?.address &&
                    saveElectricalContractorPressed
                  }
                  helperText={
                    !electricalObject?.address &&
                    saveElectricalContractorPressed
                      ? "Street address is required"
                      : ""
                  }
                />
              </Grid>

              {/* electrical city field */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="electrical-city"
                  label="Electrical Contractor City"
                  value={electricalObject?.city || ""}
                  name="city"
                  sx={textfield}
                  onChange={handleElectricalChange}
                  error={
                    !electricalObject?.city && saveElectricalContractorPressed
                  }
                  helperText={
                    !electricalObject?.city && saveElectricalContractorPressed
                      ? "City is required"
                      : ""
                  }
                />
              </Grid>

              {/* electrical zip code */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="electrical-zip-code"
                  label="Electrical Contractor Zip code"
                  value={electricalObject?.zip || ""}
                  name="zip"
                  type="number"
                  sx={textfield}
                  onChange={handleElectricalChange}
                  error={
                    !electricalObject?.zip && saveElectricalContractorPressed
                  }
                  helperText={
                    !electricalObject?.zip && saveElectricalContractorPressed
                      ? "Zip is required"
                      : ""
                  }
                />
              </Grid>
            </Grid>

            <Box component="div" sx={{ marginTop: "20px" }}>
              <Typography sx={applicantTypos}>
                Master Electrician Contractor&apos;s Information
              </Typography>
              <Grid container spacing={2} sx={{ my: "5px" }}>
                {/* master electrician name */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="master-electrician-name"
                    label="Master Electrician Name"
                    value={electricalObject?.masterElectricianName || ""}
                    name="masterElectricianName"
                    sx={textfield}
                    onChange={handleElectricalChange}
                    error={
                      !electricalObject?.masterElectricianName &&
                      saveElectricalContractorPressed
                    }
                    helperText={
                      !electricalObject?.masterElectricianName &&
                      saveElectricalContractorPressed
                        ? "Master electrician Name is required"
                        : ""
                    }
                  />
                </Grid>

                {/* master electrician email */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="master-electrical-email"
                    label="Master Electrician Email"
                    value={electricalObject?.masterElectricianEmail || ""}
                    name="masterElectricianEmail"
                    type="email"
                    sx={textfield}
                    onChange={handleElectricalChange}
                    error={
                      !!electricalMasterEmailError ||
                      (!electricalObject?.masterElectricianEmail &&
                        saveElectricalContractorPressed)
                    }
                    helperText={
                      electricalMasterEmailError ||
                      (!electricalObject?.masterElectricianEmail &&
                        saveElectricalContractorPressed)
                        ? "Please enter a valid email address"
                        : ""
                    }
                  />
                </Grid>

                {/* master electrician phonenumber */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="master-electrician-phoneNumber"
                    label="Master Electrician Phone Number"
                    value={electricalObject?.masterElectricianPhoneNumber || ""}
                    name="masterElectricianPhoneNumber"
                    sx={textfield}
                    onChange={handleElectricalChange}
                    error={
                      !electricalObject?.masterElectricianPhoneNumber &&
                      saveElectricalContractorPressed
                    }
                    helperText={
                      !electricalObject?.masterElectricianPhoneNumber &&
                      saveElectricalContractorPressed
                        ? "Master electrician phone is required"
                        : ""
                    }
                  />
                </Grid>

                {/* master electrician certificate no */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !electricalObject?.masterElectricianLicenseNumber &&
                      saveElectricalContractorPressed
                    }
                    helperText={
                      !electricalObject?.masterElectricianLicenseNumber &&
                      saveElectricalContractorPressed
                        ? "Master electrican license number is required"
                        : ""
                    }
                    id="master-electrician-certificate"
                    label="Master Electrician License/Certificate No."
                    value={
                      electricalObject?.masterElectricianLicenseNumber || ""
                    }
                    name="masterElectricianLicenseNumber"
                    sx={textfield}
                    onChange={handleElectricalChange}
                  />
                </Grid>

                {/* master electrician license expiration date */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !electricalObject?.masterElectricianLicenseExpirationDate &&
                      saveElectricalContractorPressed
                    }
                    helperText={
                      !electricalObject?.masterElectricianLicenseExpirationDate &&
                      saveElectricalContractorPressed
                        ? "Master license expiration date is required"
                        : ""
                    }
                    id="master-electrical-license-expire-date"
                    // label="Master Electrician License Expire Date"
                    value={
                      electricalObject?.masterElectricianLicenseExpirationDate?.split(
                        "T"
                      )[0]
                    }
                    name="masterElectricianLicenseExpirationDate"
                    onChange={handleElectricalChange}
                    disableUnderline
                    fullWidth
                    sx={textfieldDate}
                    label="License Expiration Date"
                    inputProps={{
                      max: "9999-12-31",
                    }}
                    type="date"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Grid>

                {/* master electrical address */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="master-electrical-address"
                    label="Master Electrician Street Address"
                    value={electricalObject?.masterElectricianAddress || ""}
                    name="masterElectricianAddress"
                    sx={textfield}
                    onChange={handleElectricalChange}
                    error={
                      !electricalObject?.masterElectricianAddress &&
                      saveElectricalContractorPressed
                    }
                    helperText={
                      !electricalObject?.masterElectricianAddress &&
                      saveElectricalContractorPressed
                        ? "Master electrician street address is required"
                        : ""
                    }
                  />
                </Grid>

                {/* master Electrical city */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="master-electrical-city"
                    label="Master Electrician City"
                    value={electricalObject?.masterElectricianCity || ""}
                    name="masterElectricianCity"
                    sx={textfield}
                    onChange={handleElectricalChange}
                    error={
                      !electricalObject?.masterElectricianCity &&
                      saveElectricalContractorPressed
                    }
                    helperText={
                      !electricalObject?.masterElectricianCity &&
                      saveElectricalContractorPressed
                        ? "Master electrician city is required"
                        : ""
                    }
                  />
                </Grid>

                {/* master electrical Zip code */}
                <Grid item xs={12} sm={12} md={12} lg={12} align="right">
                  <TextField
                    id="master-electrical-Zip-code"
                    label="Master Electrician Zip code"
                    type="number"
                    value={electricalObject?.masterElectricianZip || ""}
                    name="masterElectricianZip"
                    sx={textfield}
                    onChange={handleElectricalChange}
                    error={
                      !electricalObject?.masterElectricianZip &&
                      saveElectricalContractorPressed
                    }
                    helperText={
                      !electricalObject?.masterElectricianZip &&
                      saveElectricalContractorPressed
                        ? "Master electrician zip code is required"
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button
              disableRipple
              type="submit"
              disabled={loader}
              sx={
                loader
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "187px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutB",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "187px",
                      "&:hover": { backgroundColor: "background.bgLayoutB" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography sx={userHeading} variant="userHeadingBoldButton">
                Update
              </Typography>
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </CardActions>
        </ValidatorForm>
      </Card>
    </Container>
  );
};

export default Index;
