import React from "react";

import { Box, Button, Container, Typography } from "@mui/material";
import { mainHeading, subCautionaryHeading } from "../utils/muiComponentStyles";

function Cautionary({ handleAgreeButton }) {
  return (
    <>
      <Container sx={{ mb: 8 }}>
        <Box sx={{ width: "100%" }}>
          <Typography sx={mainHeading}>
            Cautionary Statement and Instructions{" "}
          </Typography>
          <Typography
            sx={{ ...subCautionaryHeading, color: "background.bgLayoutB" }}
          >
            {" "}
            Permit Application Instructions
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box>
            <ul>
              <li>
                <Typography
                  sx={{ fontFamily: "Poppins-Bold", fontSize: 17, mb: 1 }}
                >
                  Submit one form per project.
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{ fontFamily: "Poppins-Bold", fontSize: 17, mb: 1 }}
                >
                  List all contractors and sub-contractors on the form.
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{ fontFamily: "Poppins-Bold", fontSize: 17, mb: 1 }}
                >
                  Include all necessary license numbers (business and
                  individual).
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{ fontFamily: "Poppins-Bold", fontSize: 17, mb: 1 }}
                >
                  To avoid any unnecessary delays, ensure the form is filled out
                  completely and accurately.
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{ fontFamily: "Poppins-Bold", fontSize: 17, mb: 1 }}
                >
                  All fees associated with the project will be charged and
                  collected at one time.
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{ fontFamily: "Poppins-Bold", fontSize: 17, mb: 1 }}
                >
                  Additional documentation such as plans and specifications may
                  be required for your project.
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontFamily: "Poppins-Bold",
              fontSize: 17,
              mb: 1,
              mt: 3,
            }}
          >
            {" "}
            Wetlands Notice to Permit Applicants
          </Typography>
          <Typography
            sx={{ fontFamily: "Poppins-Light", fontSize: 17, mb: 1, mt: 3 }}
          >
            You are responsible for complying with state and federal laws
            concerning the construction near or on wetlands, lakes, and streams.
            Wetlands that are not associated with open water can be difficult to
            identify. Failure to comply may result in removal or modification of
            construction that violates the law or other penalties or costs. For
            more information, visit the Department of Natural Resources wetlands
            identification web page or contact a Department of Natural Resources
            service center. If one acre or more of soil will be disturbed, this
            project is subject to ch. NR 151 regarding additional erosion
            control and stormwater management.
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontFamily: "Poppins-Bold",
              fontSize: 17,
              mb: 1,
              mt: 3,
            }}
          >
            <div style={{ display: "inline-block" }}>
              Cautionary Statement to Contractors for Projects Involving
              Building Built Before 1978
            </div>
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins-Light",
              fontSize: 17,
              mb: 1,
              mt: 3,
            }}
          >
            If this project is in a dwelling or child-occupied facility, built
            before 1978, and disturbs 6 sq. ft. or more of paint per room, 20
            sq. ft. or more of exterior paint, or involves windows, then the
            requirements of ch. DHS 163 requiring Lead-Safe Renovation Training
            and Certification apply. Call (608)261-6876 or go to this {""}
            <a
              href="http://dhs.wisconsin.gov/lead/WisconsinRRPRule.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "#D52027",
                fontWeight: "bold",
              }}
            >
              link
            </a>{" "}
            for details of how to be in compliance.
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontFamily: "Poppins-Bold",
              fontSize: 17,
              mb: 1,
              mt: 3,
            }}
          >
            {" "}
            Cautionary Statement to Owners Obtaining Building Permits (Uniform
            Dwelling Code)
          </Typography>
          <Typography
            sx={{ fontFamily: "Poppins-Light", fontSize: 17, mb: 1, mt: 3 }}
          >
            101.65 (lr) of the Wisconsin Statutes requires municipalities that
            enforce the Uniform Dwelling Code to provide an owner who applies
            for a building permit with a statement advising the owner that If
            the owner hires a contractor to perform work under the building
            permit and the contractor is not bonded or insured as required under{" "}
            <br />
            <Typography
              sx={{
                fontFamily: "Poppins-Bold",
                fontSize: 17,
                color: "background.bgLayoutB",
              }}
              component="span"
            >
              s.101.654(2)(a), the following consequences might occur: <br />
            </Typography>
            <br />
            <span
              style={{
                color: "#D52027",
                paddingRight: "10px",
                fontWeight: "bold",
              }}
            >
              a)
            </span>
            The owner may be held liable for any bodily injury to or death of
            others or for any damage to the property of others that arises out
            of the work performed under the building permit or that is caused by
            any negligence by the contractor that occurs in connection with the
            work performed under the building permit. <br /> <br />
            <span
              style={{
                color: "#D52027",
                paddingRight: "10px",
                fontWeight: "bold",
              }}
            >
              b)
            </span>
            The owner may not be able to collect from the contractor damages for
            any loss sustained by the owner because of a violation by the
            contractor of the one and 2 family dwelling code or an ordinance
            enacted under sub. (1)(a), because of any bodily injury to or death
            of others or damage to the property of others that arose out of the
            work performed under the building permit or because of any bodily
            injury to or death of others or damage to the property of others
            that is caused by any negligence by the contractor that occurs in
            connection with the work performed under the building permit.
            <br /> <br />I vouch that I am or will be an owner-occupant of this
            dwelling for which I am applying for an erosion control or
            construction permit without a Dwelling Contractor Certification and
            have read the cautionary statement regarding contractor
            responsibility above.
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ mt: 6, mb: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins-Bold",
                fontSize: 17,
                textAlign: "center",
                color: "background.bgLayoutB",
              }}
            >
              Statement of Owner or Owner’s Authorized Agent
            </Typography>

            <Typography
              sx={{
                fontFamily: "Poppins-Light",
                fontSize: 17,
                mt: 2,
              }}
            >
              I represent and warrant that I am the owner or the owner’s
              authorized agent and I understand that I am subject to all
              applicable codes, laws, statutes, and ordinances, including those
              described on this form; am subject to any conditions of this
              permit; understand that the issuance of this permit creates no
              legal liability, express or implied, on the state or municipality;
              and certify that all the information within this application is
              accurate. <br /> I expressly grant the building inspector, or the
              inspector&apos;s authorized agent, permission to enter the
              premises for which this permit is sought at all reasonable hours
              and for any proper purpose to inspect the work which is being
              done. Furthermore, I represent and warrant that I will provide a
              copy of this Uniform Application for Permit to all parties
              identified on this application and that an amended permit
              application will be filed with the Building Inspector if any of
              the parties identified on this application do not perform work on
              this project or another party performs work in place of the
              identified party.
            </Typography>
            <br />
            <Typography
              sx={{
                fontFamily: "Poppins-Light",
                fontSize: 17,
              }}
            >
              I agree to the terms and conditions of this form and further,
              agree that completion and submission of this form electronically
              constitutes my signature for purposes of applying for a Permit and
              that such signature shall be legally binding as though executed in
              person.
            </Typography>
          </Box>
        </Box>
        {/* <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  // checked={gilad}
                  // onChange={handleChange}
                  name="agree"
                />
              }
              label="By Clicking the link below you have read and agree to the Cautionary Statement."
            />
          </FormGroup>
        </FormControl> */}
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ fontFamily: "Poppins-Bold", fontSize: 17, mt: 10 }}>
            By Clicking the link {""}
            {/* <Typography
            onClick={() => handleAgreeButton()}
            component="span"
            sx={{
              mx: 0.5,
              fontFamily: "Poppins-Light",
              fontSize: 17,
              color: "#0f99ee",
              cursor: "pointer",
            }}
          >
            link
          </Typography>{" "} */}
            below you have read and agree to the Cautionary Statement.
          </Typography>
        </Box>
        <Box sx={{ width: "100%", mt: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => handleAgreeButton()}
              sx={{
                width: "178px",
                height: "46px",
                backgroundColor: "background.bgLayoutB",
                borderRadius: "10px",
                mr: 4,
                color: "white",
                fontFamily: "Poppins-Bold",
                "&:hover": {
                  backgroundColor: "red",
                  boxShadow: 2,
                  transition: ".7s ease-in",
                },
                "&:disabled": {
                  backgroundColor: "#979797",
                },
              }}
            >
              Agree
            </Button>
            <Button
              onClick={() => (window.location.href = "https://wipermit.com/")}
              sx={{
                width: "178px",
                height: "46px",
                backgroundColor: "background.bgLayoutA",
                borderRadius: "10px",
                color: "white",
                fontFamily: "Poppins-Bold",
                "&:hover": {
                  backgroundColor: "background.bgLayoutA",
                  boxShadow: 2,
                  transition: ".7s ease-in",
                },
                "&:disabled": {
                  backgroundColor: "#979797",
                },
              }}
            >
              Go back
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Cautionary;
