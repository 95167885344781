import React from "react";
import {
  Font,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
// import data from "./BuildingPlacardPdfData.json";
import poppinsRegular from "../../../assets/fonts/Poppins-Regular.ttf";
import poppinsBold from "../../../assets/fonts/Poppins-Bold.ttf";
import poppinsMedium from "../../../assets/fonts/Poppins-SemiBold.ttf";
// import logo from "../../../assets/icons/logo.png";
import checkboxFilled from "../../../assets/pdf-checkbox-show/check-box-filled.png";
import checkboxOutlined from "../../../assets/pdf-checkbox-show/check-box-outlined.png";

Font.register({
  family: "PoppinsFamily",
  fonts: [
    {
      src: poppinsRegular,
    },
    {
      src: poppinsBold,
      fontWeight: "bold",
    },
    { src: poppinsMedium, fontWeight: "semibold" },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    width: "100%",
    padding: "10px",
  },
  mainViewTag: {
    padding: "15px",
    width: "100%",
  },
  firstHalfMainTag: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
  },
  secondHalfMainTag: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
  },
  firstViewTag: {
    width: "45%",
  },
  secondViewTag: {
    width: "55%",
  },
  thirdViewTag: {
    width: "50%",
  },
  forthViewTag: { width: "50%" },

  ispectionTableTextFirst: {
    fontFamily: "PoppinsFamily",
    fontSize: "12px",
    width: "150%",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    padding: "5px",
  },
  ispectionTableTextMiddle: {
    fontFamily: "PoppinsFamily",
    fontSize: "12px",
    width: "100%",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    padding: "5px",
  },
  ispectionTableLastText: {
    fontFamily: "PoppinsFamily",
    fontSize: "12px",
    width: "100%",
    borderBottom: "1px solid black",
    padding: "5px",
  },
  ispectionTableLastRowTextFirst: {
    fontFamily: "PoppinsFamily",
    fontSize: "12px",
    width: "150%",
    borderRight: "1px solid black",
    padding: "5px",
  },
  ispectionTableLastRowTextMiddle: {
    fontFamily: "PoppinsFamily",
    fontSize: "12px",
    width: "100%",
    textAlign: "center",
    borderRight: "1px solid black",
  },
  ispectionTableLastRowLastText: {
    fontFamily: "PoppinsFamily",
    fontSize: "12px",
    width: "100%",
    textAlign: "center",
  },
  boldStyle: {
    fontFamily: "PoppinsFamily",
    fontWeight: "semibold",
  },

  tableData: {
    // border: "1px solid lightgray",
    fontFamily: "PoppinsFamily",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    border: "1px solid black",
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
  },
  tableDataRow: {
    border: "1px solid black",
    fontFamily: "PoppinsFamily",
    fontSize: "14px",
    padding: "7px",
    width: "100%",
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 1,
  },
  checkBoxViewStyle: {
    width: "100%",
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "7px",
    fontSize: "16px",
  },
  checkboxImageStyle: {
    width: "15px",
    height: "15px",
  },
  issuedToFields: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
    fontFamily: "PoppinsFamily",
    fontSize: "10px",
  },
});

const BuildingPlacardPdf = ({ data }) => {
  return (
    <Document pageMode="fullScreen" pageLayout="twoPageLeft">
      <Page size="A3" style={styles.page} orientation="landscape">
        <View style={styles.mainViewTag}>
          {/* <View style={styles.heading}>
            <Image src={logo} style={{ height: "50px", width: "70px" }} />
            <Text>Building Placard Detail</Text>
          </View> */}
          <View style={styles.firstHalfMainTag}>
            <View style={styles.firstViewTag}>
              <View style={{ width: "100%", border: "1px solid black" }}>
                <View
                  style={{
                    borderBottom: "1px solid black",
                    fontWeight: "bold",
                    fontFamily: "PoppinsFamily",
                    textAlign: "center",
                  }}
                >
                  <Text>Site Info</Text>
                </View>
                <View style={{ padding: "5px" }}>
                  <View
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "12px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ width: "20%" }}>Subdivision</Text>
                    <Text
                      style={{ borderBottom: "1px solid black", width: "100%" }}
                    >
                      {data?.siteInfo?.subDivision}
                    </Text>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "100%",
                      paddingTop: "5px",
                    }}
                  >
                    <View
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontSize: "12px",
                        display: "flex",
                        flexDirection: "row",
                        width: "50%",
                      }}
                    >
                      <Text style={{ width: "20%" }}>Lot no</Text>
                      <Text
                        style={{
                          borderBottom: "1px solid black",
                          width: "70%",
                        }}
                      >
                        {data?.siteInfo?.plotNumber}
                      </Text>
                    </View>
                    <View
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontSize: "12px",
                        display: "flex",
                        flexDirection: "row",
                        width: "50%",
                      }}
                    >
                      <Text style={{ width: "25%" }}>Block no</Text>
                      <Text
                        style={{
                          borderBottom: "1px solid black",
                          width: "80%",
                        }}
                      >
                        {data?.siteInfo?.blockNumber}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "12px",
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "5px",
                      // gap: "5px",
                    }}
                  >
                    <Text style={{ width: "20%" }}>Zoning District</Text>
                    <Text
                      style={{ borderBottom: "1px solid black", width: "80%" }}
                    >
                      {data?.siteInfo?.zoningDistrict}
                    </Text>
                  </View>

                  <View
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "12px",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      paddingTop: "5px",
                      // gap: "5px",
                    }}
                  >
                    <Text style={{ width: "20%" }}>Parcel number</Text>
                    <Text
                      style={{ borderBottom: "1px solid black", width: "80%" }}
                    >
                      {data?.siteInfo?.parcelNumber}
                    </Text>
                  </View>
                  <View
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "10px",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      paddingTop: "5px",
                    }}
                  >
                    <Text style={{ width: "15%" }}>SetBacks:</Text>
                    <Text>{data?.siteInfo?.setBacks}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "100%",
                      paddingTop: "5px",
                      gap: "10px",
                    }}
                  >
                    <View
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontSize: "12px",
                        display: "flex",
                        flexDirection: "row",
                        width: "50%",
                      }}
                    >
                      <Text style={{ width: "20%" }}>Front</Text>
                      <Text
                        style={{
                          borderBottom: "1px solid black",
                          width: "80%",
                        }}
                      >
                        {data?.siteInfo?.front}
                      </Text>
                      <Text style={{ marginRight: "5px" }}>ft</Text>
                    </View>
                    <View
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontSize: "12px",
                        display: "flex",
                        flexDirection: "row",
                        width: "50%",
                      }}
                    >
                      <Text style={{ width: "20%" }}>Rear</Text>
                      <Text
                        style={{
                          borderBottom: "1px solid black",
                          width: "80%",
                        }}
                      >
                        {data?.siteInfo?.rear}
                      </Text>
                      <Text>ft</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "100%",
                      paddingTop: "5px",
                      gap: "10px",
                    }}
                  >
                    <View
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontSize: "12px",
                        display: "flex",
                        flexDirection: "row",
                        width: "50%",
                      }}
                    >
                      <Text style={{ width: "20%" }}>Left</Text>
                      <Text
                        style={{
                          borderBottom: "1px solid black",
                          width: "80%",
                        }}
                      >
                        {data?.siteInfo?.left}
                      </Text>
                      <Text style={{ marginRight: "5px" }}>ft</Text>
                    </View>
                    <View
                      style={{
                        fontFamily: "PoppinsFamily",
                        fontSize: "12px",
                        display: "flex",
                        flexDirection: "row",
                        width: "50%",
                      }}
                    >
                      <Text style={{ width: "20%" }}>Right</Text>
                      <Text
                        style={{
                          borderBottom: "1px solid black",
                          width: "80%",
                        }}
                      >
                        {data?.siteInfo?.right}
                      </Text>
                      <Text>ft</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ border: "1px solid black", marginTop: "5px" }}>
                <View style={{ textAlign: "center" }}>
                  <Text style={styles.boldStyle}>INSPECTIONS</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    border: "1px solid black",
                    borderTop: "1px solid black",
                    borderRight: 0,
                    borderLeftWidth: 0,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "12px",
                      width: "150%",
                      textAlign: "center",
                      // padding: "5px",
                      borderRight: "1px solid black",
                    }}
                  >
                    PHASE
                  </Text>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "12px",
                      width: "100%",
                      textAlign: "center",
                      borderRight: "1px solid black",
                      // padding: "5px",
                    }}
                  >
                    ROUGH
                  </Text>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "12px",
                      width: "100%",
                      textAlign: "center",
                      // padding: "5px",
                      borderRight: "1px solid black",
                    }}
                  >
                    FINAL
                  </Text>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "12px",
                      width: "100%",
                      textAlign: "center",
                      // padding: "5px",
                    }}
                  >
                    EROSION
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text style={styles.ispectionTableTextFirst}>Footing</Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableLastText}></Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text style={styles.ispectionTableTextFirst}>Foundation</Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableLastText}></Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text style={styles.ispectionTableTextFirst}>
                    Bsmt Drain Tiles
                  </Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableLastText}></Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text style={styles.ispectionTableTextFirst}>
                    Construction
                  </Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableLastText}></Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text style={styles.ispectionTableTextFirst}>PLUMBING</Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableLastText}></Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text style={styles.ispectionTableTextFirst}>
                    Heat/Vent/Ac
                  </Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableLastText}></Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text style={styles.ispectionTableTextFirst}>Electrical</Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableLastText}></Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text style={styles.ispectionTableTextFirst}>Insulation</Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableTextMiddle}></Text>
                  <Text style={styles.ispectionTableLastText}></Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text style={styles.ispectionTableLastRowTextFirst}>
                    Occupancy
                  </Text>
                  <Text style={styles.ispectionTableLastRowTextMiddle}></Text>
                  <Text style={styles.ispectionTableLastRowTextMiddle}></Text>
                  <Text style={styles.ispectionTableLastRowLastText}></Text>
                </View>
              </View>
            </View>
            <View style={styles.secondViewTag}>
              <View
                style={{
                  fontSize: "12px",
                  borderBottom: "1px solid black",
                  fontFamily: "PoppinsFamily",
                }}
              >
                <Text>
                  Work shall not proceed until the inspector has approved the
                  various stages of construction or two business days have
                  elapsed since the day of inspection request. This permit will
                  expire 24 months after the date of issuance if the building’s
                  exterior has not been completed.{" "}
                  <Text style={styles.boldStyle}>
                    Keep this card posted until final inspection has been made.
                  </Text>{" "}
                  (WI Stats. 101.63)
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  position: "relative",
                  fontFamily: "PoppinsFamily",
                  marginTop: "10px",
                }}
              >
                <View
                  style={{
                    fontFamily: "PoppinsFamily",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <View>
                    <Text
                      style={{
                        FontSize: "35px",
                        padding: "0px",
                        margin: "0px",
                        fontFamily: "PoppinsFamily",
                        ...styles.boldStyle,
                      }}
                    >
                      WISCONSIN UNIFORM{" "}
                    </Text>
                    <Text
                      style={{
                        fontSize: "50px",
                        fontFamily: "PoppinsFamily",
                        ...styles.boldStyle,
                      }}
                    >
                      BUILDING
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "-10px",
                      marginTop: "-10px",
                      fontFamily: "PoppinsFamily",
                      ...styles.boldStyle,
                    }}
                  >
                    <View style={{ width: "45%" }}>
                      <Text
                        style={{
                          fontSize: "50px",
                          marginTop: "-10px",
                        }}
                      >
                        PERMIT #:{" "}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "55%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "35px",
                          textDecoration: "underline",
                          // marginTop: "30px",
                          marginLeft: "-20px",
                        }}
                      >
                        {data?.application?.permitNumber}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: "25%",
                    border: "1px solid black",
                    height: "110px",
                    fontSize: "12px",
                    fontFamily: "PoppinsFamily",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <View style={{ textAlign: "center", marginTop: "15px" }}>
                    <Text>Affix uniform</Text>
                    <Text>permit seal here</Text>
                    <Text>(when applicable)</Text>
                    <Text>Seal No.:</Text>
                    <Text
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid black",
                        padding: "5px",
                        margin: "5px",
                      }}
                    >
                      {data?.siteInfo?.sealNumber}
                    </Text>
                  </View>
                </View>
              </View>
              {/* checkbox contractor view */}
              <View style={styles.checkBoxViewStyle}>
                <View style={styles.checkBox}>
                  {data?.application?.dwellingContractorByOwner === true ? (
                    <Image
                      src={checkboxFilled}
                      style={styles.checkboxImageStyle}
                    />
                  ) : (
                    <Image
                      src={checkboxOutlined}
                      style={styles.checkboxImageStyle}
                    />
                  )}
                  <Text>Construction</Text>
                </View>
                <View style={styles.checkBox}>
                  {data?.application?.electricalContractorByOwner ? (
                    <Image
                      src={checkboxFilled}
                      style={styles.checkboxImageStyle}
                    />
                  ) : (
                    <Image
                      src={checkboxOutlined}
                      style={styles.checkboxImageStyle}
                    />
                  )}
                  <Text>Electric</Text>
                </View>
                <View style={styles.checkBox}>
                  {data?.application?.plumbingContractorByOwner ? (
                    <Image
                      src={checkboxFilled}
                      style={styles.checkboxImageStyle}
                    />
                  ) : (
                    <Image
                      src={checkboxOutlined}
                      style={styles.checkboxImageStyle}
                    />
                  )}
                  <Text>Plumbing</Text>
                </View>
                <View style={styles.checkBox}>
                  {data?.application?.hvacContractorByOwner ? (
                    <Image
                      src={checkboxFilled}
                      style={styles.checkboxImageStyle}
                    />
                  ) : (
                    <Image
                      src={checkboxOutlined}
                      style={styles.checkboxImageStyle}
                    />
                  )}
                  <Text>HVAC</Text>
                </View>
                <View style={styles.checkBox}>
                  {data?.application?.permitRequested.includes(5) ? (
                    <Image
                      src={checkboxFilled}
                      style={styles.checkboxImageStyle}
                    />
                  ) : (
                    <Image
                      src={checkboxOutlined}
                      style={styles.checkboxImageStyle}
                    />
                  )}
                  <Text>Erosion Control</Text>
                </View>
              </View>

              <View
                style={{
                  ...styles.boldStyle,
                  fontFamily: "PoppinsFamily",
                  marginTop: "20px",
                  fontSize: "16px",
                }}
              >
                <Text>Project:</Text>
              </View>
              <View
                style={{
                  fontFamily: "PoppinsFamily",
                  // marginTop: "20px",
                }}
              >
                <Text style={{ fontSize: "12px" }}>{data?.jobDescription}</Text>
              </View>
              {/* table of issued to */}
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "PoppinsFamily",
                  fontSize: "10px",
                }}
              >
                <View
                  style={{
                    width: "30%",
                    fontFamily: "PoppinsFamily",
                    fontSize: "25px",
                    textAlign: "center",
                    fontWeight: "bold",
                    border: "1px solid black",
                    justifyContent: "center",
                  }}
                >
                  <Text>Issued To</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "70%",
                    border: "1px solid",
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                    borderTop: "1px solid black",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        width: "60%",
                        border: "1px solid",
                        borderBottom: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <Text style={{ ...styles.boldStyle, fontSize: "12px" }}>
                        Owner (Agent)
                      </Text>
                      <Text style={{ fontSize: "12px" }}>
                        {data?.issuedTo?.ownerName}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "40%",
                        border: "1px solid",
                        borderLeft: "1px solid black",
                        borderBottom: "1px solid black",
                        padding: "3px",
                      }}
                    >
                      <Text style={{ ...styles.boldStyle, fontSize: "12px" }}>
                        Phone
                      </Text>
                      <Text style={{ fontSize: "12px" }}>
                        {data?.issuedTo?.ownerPhoneNumber}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      border: "1px solid",
                      borderBottom: "1px solid black",
                      padding: "3px",
                    }}
                  >
                    <Text style={{ ...styles.boldStyle, fontSize: "12px" }}>
                      Building, site, address
                    </Text>
                    <Text style={{ fontSize: "12px" }}>
                      {data?.issuedTo?.buildingSiteAddress}
                    </Text>
                  </View>
                  <View
                    style={{
                      border: "1px solid",
                      padding: "3px",
                    }}
                  >
                    <Text style={{ ...styles.boldStyle, fontSize: "12px" }}>
                      City, Village, Town
                    </Text>
                    <Text style={{ fontSize: "12px" }}>
                      {data?.issuedTo?.cityVillageTown}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.secondHalfMainTag}>
            <View style={{ ...styles.thirdViewTag, marginTop: "10px" }}>
              {/* Contractors table detail */}
              <View style={{ width: "100%", border: "1px solid black" }}>
                <View style={{ textAlign: "center" }}>
                  <Text style={styles.boldStyle}>Contractors</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    border: "1px solid black",
                    borderTop: "1px solid black",
                    borderRight: 0,
                    borderLeftWidth: 0,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "16px",
                      width: "100%",
                      textAlign: "center",
                      // padding: "5px",
                      borderRight: "1px solid black",
                    }}
                  >
                    Name
                  </Text>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "16px",
                      width: "100%",
                      textAlign: "center",
                      borderRight: "1px solid black",
                      // padding: "5px",
                    }}
                  >
                    Credential No
                  </Text>
                  <Text
                    style={{
                      fontFamily: "PoppinsFamily",
                      fontSize: "16px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Phone Number
                  </Text>
                </View>
                {data?.placardContractors?.map((contractor) => (
                  <View style={styles.tableData} key={contractor?.id}>
                    <View
                      style={{ ...styles.tableDataRow, borderLeftWidth: 0 }}
                    >
                      <Text>{contractor?.name}</Text>
                    </View>
                    <View
                      style={{ ...styles.tableDataRow, borderLeftWidth: 0 }}
                    >
                      <Text>{contractor?.credential}</Text>
                    </View>
                    <View
                      style={{
                        ...styles.tableDataRow,
                        borderRight: 0,
                        borderLeft: 0,
                      }}
                    >
                      <Text>{contractor?.phoneNumber}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <View style={styles.forthViewTag}>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "PoppinsFamily",
                  fontSize: "10px",
                  marginTop: "10px",
                }}
              >
                <View
                  style={{
                    width: "35%",
                    fontFamily: "PoppinsFamily",
                    fontSize: "20px",
                    textAlign: "center",
                    fontWeight: "bold",
                    border: "1px solid black",
                    justifyContent: "center",
                  }}
                >
                  <Text>Issued By</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "65%",
                    border: "1px solid",
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                    borderTop: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        width: "60%",
                        border: "1px solid",
                        borderBottom: "1px solid black",
                        padding: "5px",
                      }}
                    >
                      <Text style={{ ...styles.boldStyle }}>
                        Person issuing
                      </Text>
                      <Text>{data?.issuedBy?.personIssuingName}</Text>
                    </View>
                    <View
                      style={{
                        width: "40%",
                        border: "1px solid",
                        borderLeft: "1px solid black",
                        borderBottom: "1px solid black",
                        padding: "5px",
                      }}
                    >
                      <Text style={styles.boldStyle}>Cert. N.o</Text>
                      <Text>{data?.issuedBy?.certificateNumber}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        border: "1px solid",
                        borderRight: "1px solid black",
                        padding: "5px",
                        width: "40%",
                      }}
                    >
                      <Text style={styles.boldStyle}>Date Issued</Text>
                      <Text>{data?.issuedBy?.dateIssued?.split("T")[0]}</Text>
                    </View>
                    <View
                      style={{
                        border: "1px solid",
                        padding: "5px",
                        width: "60%",
                      }}
                    >
                      <Text style={styles.boldStyle}>Phone Number</Text>
                      <Text>{data?.issuedBy?.phoneNumber}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  ...styles.boldStyle,
                  fontFamily: "PoppinsFamily",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Text style={{ fontSize: "20px", width: "50%" }}>
                  Comments:
                </Text>
                <Text
                  style={{
                    fontSize: "20px",
                    borderBottom: "1px solid black",
                    width: "50%",
                    marginLeft: "15px",
                  }}
                ></Text>
              </View>
              <View
                style={{
                  ...styles.boldStyle,
                  fontFamily: "PoppinsFamily",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    fontSize: "20px",
                    borderBottom: "1px solid black",
                    width: "100%",
                    marginLeft: "15px",
                  }}
                ></Text>
              </View>
              <View
                style={{
                  ...styles.boldStyle,
                  fontFamily: "PoppinsFamily",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    fontSize: "20px",
                    borderBottom: "1px solid black",
                    width: "100%",
                    marginLeft: "15px",
                  }}
                ></Text>
              </View>
              <View
                style={{
                  ...styles.boldStyle,
                  fontFamily: "PoppinsFamily",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "20px",
                  fontSize: "12px",
                }}
              >
                <Text>
                  <Text style={styles.boldStyle}>NOTICE OF NONCOMPLIANCE:</Text>{" "}
                  This issuing jurisdiction shall notify the applicant in
                  writing of any violations to be corrected. All cited
                  violations, except erosion control ones, shall be corrected
                  within 30 days of notification, unless extension time is
                  granted.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default BuildingPlacardPdf;
