import React, { useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import Loader from "../../../Loader/Loader";

const drawerWidth = 240;

const EmailSideNav = ({ loader, record, showData }) => {
  const [selectedId, setSelectedId] = useState(null);

  const handleSelectedData = (data, index) => {
    setSelectedId(index);
    showData(data);
  };

  const dateTimeApiformat = (currentDate) => {
    // Split the input date into date and time components
    const [datePart, timePart] = currentDate.split("T");
    // Extract date components (year, month, day)
    const [yyyy, mm, dd] = datePart.split("-");
    // Extract time components (hours, minutes)
    const [hh, min] = timePart.split(":");
    // Determine whether it's AM or PM based on the hour
    const isAM = parseInt(hh, 10) < 12;
    // Format the date
    const formattedDate = `${mm}/${dd}/${yyyy}`;
    // Format the time in 12-hour format with AM/PM
    const formattedTime = `${hh % 12 || 12}:${min} ${isAM ? "AM" : "PM"}`;

    return `${formattedDate} || ${formattedTime}`;
  };

  return (
    <Box
      sx={{
        width: drawerWidth,
        backgroundColor: "white",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        borderRight: "1px solid lightgray",
        overflowY: "scroll",
        scrollbarColor: "ligthgrey",
        scrollbarWidth: "1px",
        height: "100vh",
        "& .MuiBox-root": {
          width: drawerWidth,
        },
      }}
    >
      {loader ? (
        <Box
          sx={{
            width: drawerWidth,
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiBox-root": {
              width: drawerWidth,
            },
          }}
        >
          <Loader />
        </Box>
      ) : record.length < 1 ? (
        <Box
          sx={{
            width: drawerWidth,
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiBox-root": {
              width: drawerWidth,
            },
          }}
        >
          No record found
        </Box>
      ) : (
        <>
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                fontFamily: "Poppins-semibold",
                fontWeight: 700,
                color: "inherit",
              }}
            >
              Mail Box
            </Typography>
          </Toolbar>
          <Divider />
          <List>
            {record.map((email, index) => (
              <ListItem
                key={email?.id}
                disablePadding
                // sx={{
                //   "&.MuiListItemButton-root": {
                //     textDecoration: "none",
                //     borderRadius: "10px",
                //     width: 200,
                //     maxHeight: 50,
                //     padding: 1,
                //     margin: "2px",
                //     // marginLeft: sidebarData.children ? "20px" : "10px",
                //     marginRight: "10px",
                //     fontFamily: "Poppins",
                //     color: "#040F25",
                //   },
                //   "&:hover": {
                //     backgroundColor: "background.bgSidebarHover",
                //     borderRadius: "10px",
                //     transition: "background 600ms linear",
                //   },
                //   "&.active": {
                //     fontWeight: 1000,
                //     backgroundColor: "primary.main",
                //     color: "#FFFFFF",
                //     transition: "background 600ms linear",
                //     fontFamily: "Poppins-SemiBold",
                //   },
                //   "& .Mui-selected": {
                //     backgroundColor: "red !important",
                //     transition: "background 600ms ease-in-out",
                //     "&:hover": {
                //       backgroundColor: "red",
                //     },
                //   },
                // }}
              >
                <ListItemButton
                  sx={{
                    "&.MuiListItemButton-root": {
                      textDecoration: "none",
                      borderRadius: "10px",
                      width: 200,
                      maxHeight: 50,
                      padding: 1,
                      margin: "12px",
                      marginRight: "10px",
                      fontFamily: "Poppins",
                      color: "#040F25",
                    },
                    "&:hover": {
                      backgroundColor: "background.bgSidebarHover",
                      borderRadius: "10px",
                      transition: "background 600ms linear",
                    },
                    "&.active": {
                      fontWeight: 1000,
                      backgroundColor: "#DE2C34",
                      color: "#FFFFFF",
                      transition: "background 600ms linear",
                      fontFamily: "Poppins-SemiBold",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#d52027 !important",
                      color: "white",
                      transition: "background 600ms ease-in-out",
                      "&:hover": {
                        backgroundColor: "#d52027",
                      },
                    },
                  }}
                  selected={index === selectedId}
                  onClick={() => handleSelectedData(email, index)}
                >
                  <ListItemIcon>
                    <Avatar>
                      {email?.purpose ? email?.purpose?.slice(0, 1) : "#"}
                    </Avatar>
                  </ListItemIcon>
                  <Box component="div">
                    <ListItemText primary={email?.purpose} />
                    <ListItemText
                      sx={{
                        "&.MuiListItemText-root": {
                          ".MuiTypography-root": {
                            fontFamily: "Poppins",
                            fontSize: "10px",
                          },
                        },
                      }}
                      // primary={
                      //   email?.createdAt.split("T")[0] +
                      //   " - " +
                      //   email?.createdAt.split("T")[1].split(".")[0]
                      // }
                      primary={dateTimeApiformat(email?.createdAt)}
                    />
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

export default EmailSideNav;
