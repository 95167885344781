import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import {
  applicationCardStyle,
  miniScrollBarStyles,
  textfield,
  textfieldDate,
} from "../../../utils/muiComponentStyles";
import { getAllMuncipalities } from "../../../redux/slices/muncipalitySlice";
import { dispatch, useSelector } from "../../../redux/store";
import toasterContext from "../../../utils/context/toasterContext";
import {
  applicantTypos,
  saveButton,
  saveContractorButton,
  cancelContractorButton,
  applicationFormSubmitButton,
  applicationFormDownloadButton,
  applicationFormStateButton,
  applicationFormDeleteButton,
} from "./ApplicationDetailFormStyle";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  getSingleApplicationData,
  updateApplicationDetail,
  submitDataToState,
  updateContractorApplicationSlice,
  getApplicationPdf,
} from "../../../redux/slices/dashboard/applicantsSlice";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import ApplicationDetailFormPdf from "./ApplicationDetailFormPdf";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { uploadPdf } from "../../../redux/slices/PdfDataSlice";
import EmailToApplicantModal from "../EmailToApplicantModal";
import { AddBoxOutlined } from "@mui/icons-material";
import {
  applicationFormCreateButton,
  applicationDetailFormSearchButton,
  tableDataCell,
  tableDataRow,
  tableHeadCell,
} from "../../applicationForm/ApplicationFormStyle";
import CustomPagination from "../../../hooks/useTablePagination";
import {
  createContractor,
  getAllContractorsForListing,
} from "../../../redux/slices/contractorSlice";
import ApplicationFormContractorDetail from "./ApplicationDetailFormContractorDetail";
import DeleteApplicationModal from "./DeleteApplicationModal";
import { useNavigate } from "react-router-dom";

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
  forcePathStyle: true,
  region: process.env.REACT_APP_SPACES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
  },
});

const projectTypeList = [
  { id: 1, name: "New Building" },
  { id: 2, name: "Addition" },
  { id: 3, name: "Alteration/Repair" },
  { id: 4, name: "Deck" },
  { id: 5, name: "Demolition" },
  { id: 6, name: "Driveway" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Pool" },
];

const buildingTypeList = [
  { id: 1, name: "Single Family" },
  { id: 2, name: "Duplex" },
  { id: 3, name: "Multi-Family" },
  { id: 4, name: "Commercial-Industrial" },
  { id: 5, name: "Garage/Shed" },
  { id: 6, name: "Does not apply" },
];

const permitRequested = [
  { id: 1, name: "Construction" },
  { id: 2, name: "HVAC" },
  { id: 3, name: "Electric" },
  { id: 4, name: "Plumbing" },
  { id: 5, name: "Erosion Control" },
  { id: 6, name: "Deck" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Driveway" },
  { id: 9, name: "Pool" },
  { id: 10, name: "Other" },
];

const personSubmittingApplication = [
  {
    id: 1,
    name: "Contractor",
  },
  {
    id: 2,
    name: "Owner",
  },
];

const applicationStatusArr = [
  { id: 1, name: "New Application" },
  { id: 2, name: "Pending Approval" },
  { id: 3, name: "Approved" },
  // { id: 4, name: "Paid" },
  { id: 4, name: "Waiting for Payment" },
  { id: 5, name: "Void/Rejected" },
];

const ApplicationDetailForm = () => {
  const selectedApplicationInfo = JSON.parse(
    localStorage.getItem("selectedApplication")
  );
  const navigate = useNavigate();
  const permissions = localStorage.getItem("permissions");
  const permissionArray = permissions ? permissions.split(",") : [];
  const [manipulationPermission, setManipulationPermission] = useState(false);
  const fireToasterContext = useContext(toasterContext);
  const [applicationFormObject, setApplicationFormObject] = useState({});
  const [contractorObject, setContractorObject] = useState();
  const [createContractorObject, setCreateContractorObject] = useState({});
  const [generalObject, setGeneralObject] = useState({});
  const [electricalObject, setElectricalObject] = useState({});
  const [plumberObject, setPlumberObject] = useState({});
  const [heatObject, setHeatObject] = useState({});
  const [muncipalityId, setMuncipalityId] = useState();
  const [projectType, setProjectType] = useState();
  const [buildingType, setBuildingType] = useState();
  const [permitIds, setPermitIds] = useState([]);
  const [personSubmittingApplicationName, setPersonSubmittingApplicationName] =
    useState();
  const [contractorId, setContractorId] = useState();
  const [generalContractorId, setGeneralContractorId] = useState();
  const [electricalId, setElectricalId] = useState();
  const [plumberId, setPlumberId] = useState();
  const [hvacId, setHvacId] = useState();
  const [showPermitOtherField, setShowPermitOtherField] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [saveContractorPressed, setSaveContractorPressed] = useState(false);
  const [saveGeneralContractorPressed, setSaveGeneralContractorPressed] =
    useState(false);
  const [saveElectricalContractorPressed, setSaveElectricalContractorPressed] =
    useState(false);
  const [savePlumberContractorPressed, setSavePlumberContractorPressed] =
    useState(false);
  const [saveHvacContractorPressed, setSaveHvacContractorPressed] =
    useState(false);
  const [loader, setLoader] = useState(false);
  const [contractorLoader, setContractorLoader] = useState(false);
  const [generalContractorLoader, setGeneralContractorLoader] = useState(false);
  const [electricalContractorLoader, setElectricalContractorLoader] =
    useState(false);
  const [plumberContractorLoader, setPlumberContractorLoader] = useState(false);
  const [submitToStateLoader, setSubmitToStateLoader] = useState(false);
  const [hvacContractorLoader, setHvacContractorLoader] = useState(false);
  const [openEmailModal, setOpenEmailDialog] = useState(false);
  const [ownerEmailError, setOwnerEmailError] = useState(false);
  const [dwellEmailError, setDwellEmailError] = useState(false);
  const [generalEmailError, setGeneralEmailError] = useState(false);
  const [dwellQualifierEmailError, setDwellQualifierEmailError] =
    useState(false);
  const [electricalEmailError, setElectricalEmailError] = useState(false);
  const [electricalMasterEmailError, setElectrcialMasterEmailError] =
    useState(false);
  const [plumberEmailError, setPlumberEmailError] = useState(false);
  const [hvacEmailError, setHvacEmailError] = useState(false);
  const [applicantEmailError, setApplicantEmailError] = useState(false);
  const [emailModalOpen, setModalOpen] = useState(true);
  const [disablePdfButton, setDisablePdfButton] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState(null);
  const [dwellingContractorSearchString, setDwellingContractorSearchString] =
    useState("");
  const [showDwellContractor, setShowDwellContractor] = useState(false);
  const [showDwellContractorForm, setShowDwellContractorForm] = useState(false);
  const [dwellContractors, setDwellContractors] = useState([]);
  const [dwellContractorPage, setDwellContractorPage] = useState(0);
  const [rowsPerPageForDwellContractorPage, setRowsPerPageForDwellContractors] =
    useState(10);
  const [generalContractorSearchString, setGeneralContractorSearchString] =
    useState("");
  const [showGeneralTable, setShowGeneralTable] = useState(false);
  const [showGeneralForm, setShowGeneralForm] = useState(false);
  const [dwellingContractorToBeUpdated, setDwellingContractorToBeUpdated] =
    useState(true);
  const [generalContractors, setGeneralContractors] = useState([]);
  const [generalContractorPage, setGeneralContractorPage] = useState(0);
  const [rowsPerPageForGeneralContractor, setRowsPerPageForGeneralContractor] =
    useState(10);
  const [
    electricalContractorSearchString,
    setElectricalContractorSearchString,
  ] = useState("");
  const [showElectricalTable, setShowElectricalTable] = useState(false);
  const [showElecrticalForm, setShowElectricalForm] = useState(false);
  const [electricalContractors, setElectricalContractors] = useState([]);
  const [electricalContractorPage, setElectricalContractorPage] = useState(0);
  const [
    rowsPerPageForElectricalContractor,
    setRowsPerPageForElectricalContractor,
  ] = useState(10);
  const [plumbingContractorSearchString, setPlumbingContractorSearchString] =
    useState("");
  const [plumberContractors, setPlumberContractors] = useState([]);
  const [plumberContractorPage, setPlumberContractorPage] = useState(0);
  const [rowsPerPageForPlumberContractor, setRowsPerPageForPlumberContractor] =
    useState(10);
  const [showPlumbingTable, setShowPlumbingTable] = useState(false);
  const [showPlumbingForm, setShowPlumbingForm] = useState(false);
  const [hvacContractorSearchString, setHvacContractorSearchString] =
    useState("");
  const [hvacContractors, setHVACContractors] = useState([]);
  const [hvacContractorPage, setHvacContractorPage] = useState(0);
  const [rowsPerPageForHvacContractor, setRowsPerPageForHvacContractor] =
    useState(10);
  const [showHVACForm, setShowHVACForm] = useState(false);
  const [showHVACTable, setShowHVACTable] = useState(false);
  const [selectedContractorDataShow, setSelectedContractorDataShow] =
    useState(false);
  const [selectedContractorData, setSelectedContractorData] = useState();
  const [
    selectedGeneralContractorDataShow,
    setSelectedGeneralContractorDataShow,
  ] = useState(false);
  const [selectedGeneralContractorData, setSelectedGeneralContractorData] =
    useState();
  const [
    selectedElectricalContractorDataShow,
    setSelectedElectricalContractorDataShow,
  ] = useState(false);
  const [
    selectedElectricalContractorData,
    setSelectedElectricalContractorData,
  ] = useState();
  const [
    selectedPlumberContractorDataShow,
    setSelectedPlumberContractorDataShow,
  ] = useState(false);
  const [selectedPlumberContractorData, setSelectedPlumberContractorData] =
    useState();
  const [selectedHvacContractorDataShow, setSelectedHvacContractorDataShow] =
    useState(false);
  const [selectedHvacContractorData, setSelectedHvacContractorData] =
    useState();
  const [
    expireDwellingContractorLicenseDate,
    setExpireDwellingContractorLicenseDate,
  ] = useState(false);
  const [
    expireDwellingContractorQualifierLicenseDate,
    setExpireDwellingContractorQualifierLicenseDate,
  ] = useState(false);
  const [
    electricianLicenseExpirationDate,
    setELectricianLicenseExpirationDate,
  ] = useState(false);
  const [
    masterElectricianLicenseExpirationDate,
    setMasterELectricianLicenseExpirationDate,
  ] = useState(false);
  const [plumbingLicenseExpirationDate, setPlumbingLicenseExpirationDate] =
    useState(false);
  const [hvacLicenseExpirationDate, setHvacLicenseExpirationDate] =
    useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { muncipalityList } = useSelector((state) => state.muncipalityReducer);
  const { contractorForListingData } = useSelector(
    (state) => state.contractorReducer
  );

  let { application, applicationPdfData } = useSelector(
    (state) => state.applicantsReducer
  );

  useEffect(() => {
    dispatch(getAllMuncipalities());
    dispatch(getAllContractorsForListing());
    dispatch(getSingleApplicationData(selectedApplicationInfo?.id));
    dispatch(getApplicationPdf(handleApiResponse));

    if (!permissionArray.includes("manipulate application details")) {
      setManipulationPermission(true);
    } else {
      setManipulationPermission(false);
    }
  }, []);

  useEffect(() => {
    setApplicationFormObject(application);
  }, [application, dispatch]);

  useEffect(() => {
    if (application.id === selectedApplicationInfo.id) {
      const statusObject = applicationStatusArr.find(
        (status) => status.name === application?.status
      );
      setApplicationStatus(statusObject);

      setMuncipalityId(application?.muncipalityId);
      setProjectType(parseInt(application?.projectType));
      setBuildingType(parseInt(application?.buildingType));
      setPermitIds(application?.permitRequested);
      setPersonSubmittingApplicationName(
        application?.personSubmittingApplication
      );
      setElectricalObject(application?.electricalContractor);
      setPlumberObject(application?.plumbingContractor);
      setHeatObject(application?.hvacContractor);
      if (application.buildingType < 3 && application.projectType < 5) {
        if (application?.dwellingContractor?.type === "Construction") {
          setContractorObject(application?.dwellingContractor);
          setContractorId(application?.dwellingContractorId);
        } else if (application?.dwellingContractor === null) {
          setContractorId(null);
          setContractorObject(null);
        }
      } else {
        setContractorId(null);
        setContractorObject(null);
      }

      setElectricalId(application?.electricalContractorId);
      setPlumberId(application?.plumbingContractorId);
      setHvacId(application?.hvacContractorId);
      if (
        application?.dwellingContractor?.type === "General" &&
        ((application.buildingType < 3 && application.projectType > 4) ||
          (application.buildingType > 2 && application.projectType < 5))
      ) {
        setGeneralContractorId(application?.dwellingContractorId);
        setGeneralObject(application?.dwellingContractor);
      } else if (application?.dwellingContractor === null) {
        setGeneralContractorId(null);
        setGeneralObject(null);
      } else {
        setGeneralContractorId(null);
        setGeneralObject(null);
      }
      for (var i = 0; i < permitRequested?.length; i++) {
        for (var j = 0; j < application?.permitRequested?.length; j++) {
          if (permitRequested[i].id == application?.permitRequested[j]) {
            if (permitRequested[i].name == "Other") {
              setShowPermitOtherField(true);
            } else {
              setShowPermitOtherField(false);
            }
          }
        }
      }
      if (application?.dwellingContractor?.licenseExpirationDate) {
        checkExpireDate(
          application?.dwellingContractor?.licenseExpirationDate,
          "dwellingContractorLicenseExpirationDate"
        );
      }
      if (
        application?.dwellingContractor
          ?.dwellingContractorQualifierLicenseExpirationDate
      ) {
        checkExpireDate(
          application?.dwellingContractor
            ?.dwellingContractorQualifierLicenseExpirationDate,
          "dwellingContractorQualifierLicenseExpirationDate"
        );
      }

      if (application?.electricalContractor?.licenseExpirationDate) {
        checkExpireDate(
          application?.electricalContractor?.licenseExpirationDate,
          "licenseExpirationDate"
        );
      }
      if (
        application?.electricalContractor
          ?.masterElectricianLicenseExpirationDate
      ) {
        checkExpireDate(
          application?.electricalContractor
            ?.masterElectricianLicenseExpirationDate,
          "masterElectricianLicenseExpirationDate"
        );
      }

      if (application?.plumbingContractor?.licenseExpirationDate) {
        checkExpireDate(
          application?.plumbingContractor?.licenseExpirationDate,
          "plumberLicenseExpirationDate"
        );
      }

      if (application?.hvacContractor?.licenseExpirationDate) {
        checkExpireDate(
          application?.hvacContractor?.licenseExpirationDate,
          "heatObjectExpirationDate"
        );
      }
    }
  }, [application]);

  const handleApiResponse = (data) => {
    if (data.status) {
      handlePdfData(data.data);
    }
  };

  const handleDwellContractorChangePage = (event, newPage) => {
    setDwellContractorPage(newPage - 1);
  };

  const handleGeneralContractorChangePage = (event, newPage) => {
    setGeneralContractorPage(newPage - 1);
  };

  const handleElectricalContractorChangePage = (event, newPage) => {
    setElectricalContractorPage(newPage - 1);
  };

  const handlePlumberContractorChangePage = (event, newPage) => {
    setPlumberContractorPage(newPage - 1);
  };

  const handleHvacContractorChangePage = (event, newPage) => {
    setHvacContractorPage(newPage - 1);
  };

  const handleChange = (event) => {
    setDisablePdfButton(true);
    setModalOpen(true);
    setModalOpen(true);
    let tempApplicationFormObject = { ...applicationFormObject };
    tempApplicationFormObject[event.target.name] = event.target.value;
    setApplicationFormObject(tempApplicationFormObject);
  };

  const handleContractorChange = (event) => {
    setDisablePdfButton(true);
    setModalOpen(true);
    setModalOpen(true);
    if (
      applicationFormObject?.dwellingContractor?.type === "Construction" &&
      dwellingContractorToBeUpdated
    ) {
      if (applicationFormObject.dwellingContractor) {
        let tempContractorObject = { ...contractorObject };
        tempContractorObject[event.target.name] = event.target.value;
        setContractorObject(tempContractorObject);
      }
    } else {
      setCreateContractorObject({
        ...createContractorObject,
        [event.target.name]: event.target.value,
      });
    }

    if (event.target.name === "licenseExpirationDate") {
      checkExpireDate(
        event.target.value,
        "dwellingContractorLicenseExpirationDate"
      );
    }
    if (
      event.target.name === "dwellingContractorQualifierLicenseExpirationDate"
    ) {
      checkExpireDate(
        event.target.value,
        "dwellingContractorQualifierLicenseExpirationDate"
      );
    }
  };

  const handleGeneralChange = (event) => {
    setDisablePdfButton(true);
    setModalOpen(true);
    setModalOpen(true);
    event.preventDefault();
    if (applicationFormObject.generalContractor) {
      let tempGeneralContractor = { ...generalObject };
      tempGeneralContractor[event.target.name] = event.target.value;
      setGeneralObject(tempGeneralContractor);
    } else {
      setGeneralObject({
        ...generalObject,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleElectricalChange = (event) => {
    setDisablePdfButton(true);
    setModalOpen(true);
    setModalOpen(true);
    event.preventDefault();
    if (applicationFormObject.electricalContractor) {
      let tempElectricalObject = { ...electricalObject };
      tempElectricalObject[event.target.name] = event.target.value;
      setElectricalObject(tempElectricalObject);
    } else {
      setElectricalObject({
        ...electricalObject,
        [event.target.name]: event.target.value,
      });
    }
    if (event.target.name === "licenseExpirationDate") {
      checkExpireDate(event.target.value, "licenseExpirationDate");
    }
    if (event.target.name === "masterElectricianLicenseExpirationDate") {
      checkExpireDate(
        event.target.value,
        "masterElectricianLicenseExpirationDate"
      );
    }
  };

  const handlePlumberChange = (event) => {
    setDisablePdfButton(true);
    setModalOpen(true);
    setModalOpen(true);
    event.preventDefault();
    if (applicationFormObject.plumbingContractor) {
      let tempPlumberObject = { ...plumberObject };
      tempPlumberObject[event.target.name] = event.target.value;
      setPlumberObject(tempPlumberObject);
    } else {
      setPlumberObject({
        ...plumberObject,
        [event.target.name]: event.target.value,
      });
    }

    if (event.target.name === "licenseExpirationDate") {
      checkExpireDate(event.target.value, "plumberLicenseExpirationDate");
    }
  };

  const handleHeatObjectChange = (event) => {
    setDisablePdfButton(true);
    setModalOpen(true);
    setModalOpen(true);
    event.preventDefault();
    if (applicationFormObject.hvacContractor) {
      let tempHeatObject = { ...heatObject };
      tempHeatObject[event.target.name] = event.target.value;
      setHeatObject(tempHeatObject);
    } else {
      setHeatObject({
        ...heatObject,
        [event.target.name]: event.target.value,
      });
    }

    if (event.target.name === "licenseExpirationDate") {
      checkExpireDate(event.target.value, "heatObjectExpirationDate");
    }
  };

  const handleChangeCheckBox = (event) => {
    setApplicationFormObject({
      ...applicationFormObject,
      [event.target.name]: event.target.checked,
    });
  };

  const handleContractorTableRowClick = (data) => {
    setContractorId(data.id);
    setContractorObject(data);
    setSelectedContractorDataShow(true);
    setSelectedContractorData(data);
    setShowDwellContractor(false);
  };

  const handleGeneralContractorTableRowClick = (data) => {
    setGeneralContractorId(data.id);
    setGeneralObject(data);
    setSelectedGeneralContractorData(data);
    setSelectedGeneralContractorDataShow(true);
  };

  const handleElectricalTableRowClick = (data) => {
    setElectricalId(data.id);
    setSelectedElectricalContractorDataShow(true);
    setSelectedElectricalContractorData(data);
    setShowElectricalTable(false);
  };

  const handlePlumberTableRowClick = (data) => {
    setPlumberId(data.id);
    setSelectedPlumberContractorDataShow(true);
    setSelectedPlumberContractorData(data);
    setShowPlumbingTable(false);
  };

  const handleHvacTableRowClick = (data) => {
    setHvacId(data.id);
    setSelectedHvacContractorDataShow(true);
    setSelectedHvacContractorData(data);
    setShowHVACForm(false);
  };

  const handleShowSelectedFormClose = () => {
    setShowDwellContractor(true);
    setContractorId(null);
    setSelectedContractorData();
    setSelectedContractorDataShow(false);
  };

  const handleShowSelectedGeneralFormClose = () => {
    setShowGeneralTable(true);
    setGeneralContractorId();
    setSelectedContractorData();
    setSelectedGeneralContractorDataShow(false);
  };

  const handleShowSelectedElectricalFormClose = () => {
    setElectricalId(null);
    setShowElectricalTable(true);
    setSelectedElectricalContractorData();
    setSelectedElectricalContractorDataShow(false);
  };

  const handleShowSelectedPlumberFormClose = () => {
    setShowPlumbingTable(true);
    setPlumberId();
    setSelectedPlumberContractorData();
    setSelectedPlumberContractorDataShow(false);
  };

  const handleShowSelectedHvacFormClose = () => {
    setShowHVACTable(true);
    setHvacId();
    setSelectedHvacContractorData();
    setSelectedHvacContractorDataShow(false);
  };

  const handleContractorSearchString = (searchString, contractorType) => {
    if (contractorType == "dwellingContractor") {
      setDwellingContractorSearchString(searchString);
    } else if (contractorType == "generalContractor") {
      setGeneralContractorSearchString(searchString);
    } else if (contractorType == "electricContractor") {
      setElectricalContractorSearchString(searchString);
    } else if (contractorType == "plumberContractor") {
      setPlumbingContractorSearchString(searchString);
    } else if (contractorType == "HVACContractor") {
      setHvacContractorSearchString(searchString);
    }
  };

  const handleFilterContractorType = (contractorType) => {
    let filterContractors = [];
    if (contractorType == "dwellingContractor") {
      filterContractors = contractorForListingData.filter(
        (contractor) =>
          contractor.type === "Construction" &&
          (contractor?.name
            ?.toLowerCase()
            .includes(dwellingContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.companyName
              ?.toLowerCase()
              .includes(dwellingContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.licenseNumber
              ?.toLowerCase()
              .includes(dwellingContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.phoneNumber
              ?.toLowerCase()
              .includes(dwellingContractorSearchString?.toLocaleLowerCase()))
      );
      setDwellContractors(filterContractors);
      setShowDwellContractor(true);
      setShowDwellContractorForm(false);
    } else if (contractorType == "generalContractor") {
      filterContractors = contractorForListingData.filter(
        (contractor) =>
          contractor.type === "General" &&
          (contractor?.name
            ?.toLowerCase()
            .includes(generalContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.companyName
              ?.toLowerCase()
              .includes(generalContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.phoneNumber
              ?.toLowerCase()
              .includes(generalContractorSearchString?.toLocaleLowerCase()))
      );
      setGeneralContractors(filterContractors);
      setShowGeneralTable(true);
      setShowGeneralForm(false);
    } else if (contractorType == "electricContractor") {
      filterContractors = contractorForListingData.filter(
        (contractor) =>
          contractor.type === "Electrical" &&
          (contractor?.masterElectricianLicenseNumber
            ?.toLowerCase()
            .includes(electricalContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.companyName
              ?.toLowerCase()
              .includes(
                electricalContractorSearchString?.toLocaleLowerCase()
              ) ||
            contractor?.licenseNumber
              ?.toLowerCase()
              .includes(
                electricalContractorSearchString?.toLocaleLowerCase()
              ) ||
            contractor?.phoneNumber
              ?.toLowerCase()
              .includes(electricalContractorSearchString?.toLocaleLowerCase()))
      );
      setElectricalContractors(filterContractors);
      setShowElectricalTable(true);
      setShowElectricalForm(false);
    } else if (contractorType == "plumberContractor") {
      filterContractors = contractorForListingData.filter(
        (contractor) =>
          contractor.type === "Plumbing" &&
          (contractor?.phoneNumber
            ?.toLowerCase()
            .includes(plumbingContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.companyName
              ?.toLowerCase()
              .includes(plumbingContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.licenseNumber
              ?.toLowerCase()
              .includes(plumbingContractorSearchString?.toLocaleLowerCase()))
      );
      setPlumberContractors(filterContractors);
      setShowPlumbingTable(true);
      setShowPlumbingForm(false);
    } else if (contractorType == "HVACContractor") {
      filterContractors = contractorForListingData.filter(
        (contractor) =>
          contractor.type === "HVAC" &&
          (contractor?.phoneNumber
            ?.toLowerCase()
            .includes(hvacContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.companyName
              ?.toLowerCase()
              .includes(hvacContractorSearchString?.toLocaleLowerCase()) ||
            contractor?.licenseNumber
              ?.toLowerCase()
              .includes(hvacContractorSearchString?.toLocaleLowerCase()))
      );
      setHVACContractors(filterContractors);
      setShowHVACTable(true);
      setShowHVACForm(false);
    }
  };

  const handleChangeForAddNewContractor = (type) => {
    if (type === "dwellingContractor") {
      setContractorId(null);
      setContractorObject({});
      setDwellingContractorSearchString("");
    } else if (type === "generalContractor") {
      setGeneralContractorId(null);
      setGeneralObject({});
      setGeneralContractorSearchString("");
    } else if (type === "electricalContractor") {
      setElectricalId(null);
      setElectricalObject({});
      setElectricalContractorSearchString("");
    } else if (type === "plumbingContractor") {
      setPlumberId(null);
      setPlumberObject({});
      setPlumbingContractorSearchString("");
    } else if (type === "hvacContractor") {
      setHvacId(null);
      setHeatObject({});
      setHvacContractorSearchString("");
    }
  };

  const handleCreateDwellContractorApiResponse = (data) => {
    // setShowDwellContractor(true);
    setContractorLoader(false);
    if (data.status) {
      setContractorId(data.data.id);
      const newCreatedDwellingContractor = {
        id: data.data.id,
        name: data.data.name,
        companyName: data.data.companyName,
        licenseNumber: data.data.licenseNumber,
        phoneNumber: data.data.phoneNumber,
      };
      let tempDwellingContractors = [...dwellContractors];
      tempDwellingContractors.push(newCreatedDwellingContractor);
      setDwellContractors(tempDwellingContractors);
      setShowDwellContractorForm(false);
      setCreateContractorObject(data?.data);
      setSelectedContractorData(data?.data);
      setSelectedContractorDataShow(true);
      // setContractorObject(data.data);
      setDwellingContractorToBeUpdated(true);
      fireToasterContext.fireToasterHandler(
        true,
        data?.messsage ? data?.messsage : "contractor created"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleCreateGeneralContractorApiResponse = (data) => {
    setGeneralContractorLoader(false);
    if (data.status) {
      setGeneralContractorId(data.data.id);
      const newCreatedGeneralContractor = {
        id: data.data.id,
        name: data.data.name,
        phoneNumber: data.data.phoneNumber,
        email: data.data.email,
      };
      let tempGeneralContractor = [...generalContractors];
      tempGeneralContractor.push(newCreatedGeneralContractor);
      setGeneralContractors(tempGeneralContractor);
      setShowGeneralTable(true);
      setShowGeneralForm(false);
      setGeneralObject(data.data);
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "contractor created"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleCreateElectricalContractorApiResponse = (data) => {
    setElectricalContractorLoader(false);
    if (data.status) {
      setElectricalId(data.data.id);
      const newCreatedElectricalContractor = {
        id: data.data.id,
        phoneNumber: data.data.phoneNumber,
        licenseNumber: data.data.licenseNumber,
        masterElectricianLicenseNumber:
          data.data.masterElectricianLicenseNumber,
        masterElectricianLicenseExpirationDate:
          data.data.masterElectricianLicenseExpirationDate,
      };
      let tempElectricalContractor = [...electricalContractors];
      tempElectricalContractor.push(newCreatedElectricalContractor);
      setElectricalContractors(tempElectricalContractor);
      // setShowElectricalTable(true);
      // setShowElectricalForm(false);
      setSelectedElectricalContractorData(data.data);
      setSelectedElectricalContractorDataShow(true);
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "contractor created"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleCreatePlumberContractorApiResponse = (data) => {
    setPlumberContractorLoader(false);
    if (data.status) {
      setPlumberId(data.data.id);
      const newCreatedPlumbingContractor = {
        id: data.data.id,
        name: data.data.name,
        companyName: data.data.companyName,
        phoneNumber: data.data.phoneNumber,
        licenseNumber: data.data.licenseNumber,
        licenseExpirationDate: data.data.licenseExpirationDate,
      };
      let tempPlumbingContractors = [...plumberContractors];
      tempPlumbingContractors.push(newCreatedPlumbingContractor);
      setPlumberContractors(tempPlumbingContractors);
      // setShowPlumbingTable(true);
      setShowPlumbingForm(false);
      setSelectedPlumberContractorData(data.data);
      setSelectedPlumberContractorDataShow(true);
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "contractor created"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleCreateHVACContractorApiResponse = (data) => {
    setHvacContractorLoader(false);
    if (data.status) {
      setHvacId(data.data.id);
      const newCreatedHvacContractor = {
        id: data.data.id,
        name: data.data.name,
        companyName: data.data.companyName,
        phoneNumber: data.data.phoneNumber,
        licenseNumber: data.data.licenseNumber,
        licenseExpirationDate: data.data.licenseExpirationDate,
      };
      let tempHvacContractors = [...hvacContractors];
      tempHvacContractors.push(newCreatedHvacContractor);
      setHVACContractors(tempHvacContractors);
      // setShowHVACTable(true);
      setShowHVACForm(false);
      // setHeatObject(data.data);
      setSelectedHvacContractorData(data?.data);
      setSelectedHvacContractorDataShow(true);
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "contractor created"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleCreateDwellContractorSubmit = () => {
    setSaveContractorPressed(true);
    if (createContractorObject?.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(createContractorObject?.email)) {
        setDwellEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setDwellEmailError(false);
      }
    }
    if (createContractorObject?.dwellingContractorQualifierEmail) {
      var patternQualifierEmail = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (
        !patternQualifierEmail.test(
          createContractorObject?.dwellingContractorQualifierEmail
        )
      ) {
        setDwellQualifierEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setDwellQualifierEmailError(false);
      }
    }
    if (
      createContractorObject?.name &&
      createContractorObject?.companyName &&
      createContractorObject?.email &&
      createContractorObject?.phoneNumber &&
      createContractorObject?.licenseNumber &&
      createContractorObject?.licenseExpirationDate &&
      createContractorObject?.address &&
      createContractorObject?.city &&
      createContractorObject?.zip &&
      createContractorObject?.dwellingContractorQualifierName &&
      createContractorObject?.dwellingContractorQualifierAddress &&
      createContractorObject?.dwellingContractorQualifierLicenseNumber &&
      createContractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
      createContractorObject?.dwellingContractorQualifierCity &&
      createContractorObject?.dwellingContractorQualifierZip &&
      createContractorObject?.dwellingContractorQualifierPhoneNumber &&
      createContractorObject?.dwellingContractorQualifierEmail
    ) {
      const finalDwellContractorObject = {
        type: "Construction",
        temporary: false,
        name: createContractorObject.name,
        email: createContractorObject.email,
        companyName: createContractorObject.companyName,
        phoneNumber: createContractorObject.phoneNumber,
        licenseNumber: createContractorObject.licenseNumber,
        licenseExpirationDate: createContractorObject.licenseExpirationDate,
        address: createContractorObject.address,
        city: createContractorObject.city,
        zip: createContractorObject.zip,
        dwellingContractorQualifierName:
          createContractorObject.dwellingContractorQualifierName,
        dwellingContractorQualifierAddress:
          createContractorObject?.dwellingContractorQualifierAddress,
        dwellingContractorQualifierLicenseNumber:
          createContractorObject.dwellingContractorQualifierLicenseNumber,
        dwellingContractorQualifierLicenseExpirationDate:
          createContractorObject.dwellingContractorQualifierLicenseExpirationDate,
        dwellingContractorQualifierCity:
          createContractorObject.dwellingContractorQualifierCity,
        dwellingContractorQualifierZip:
          createContractorObject.dwellingContractorQualifierZip,
        dwellingContractorQualifierEmail:
          createContractorObject.dwellingContractorQualifierEmail,
        dwellingContractorQualifierPhoneNumber:
          createContractorObject.dwellingContractorQualifierPhoneNumber,
      };
      setContractorLoader(true);
      dispatch(
        createContractor(
          finalDwellContractorObject,
          handleCreateDwellContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Dwelling Contractor"
      );
    }
  };

  const handleCreateGeneralContractorSubmit = () => {
    setSaveGeneralContractorPressed(true);
    if (generalObject?.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(generalObject?.email)) {
        setGeneralEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setGeneralEmailError(false);
      }
    }
    if (
      generalObject?.name &&
      generalObject?.phoneNumber &&
      generalObject?.email &&
      generalObject?.companyName
    ) {
      const finalGeneralContractorObject = {
        type: "General",
        id: generalContractorId,
        applicationId: selectedApplicationInfo?.id,
        name: generalObject.name,
        email: generalObject.email,
        companyName: generalObject.companyName,
        phoneNumber: generalObject.phoneNumber,
        temporary: false,
      };
      setGeneralContractorLoader(true);
      dispatch(
        createContractor(
          finalGeneralContractorObject,
          handleCreateGeneralContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For General Contractor"
      );
    }
  };

  const handleCreateElectricContractorSubmit = () => {
    setSaveElectricalContractorPressed(true);
    if (electricalObject?.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(electricalObject?.email)) {
        setElectricalEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setElectricalEmailError(false);
      }
    }
    if (electricalObject?.masterElectricianEmail) {
      var patternMasterEmail = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!patternMasterEmail.test(electricalObject?.masterElectricianEmail)) {
        setElectrcialMasterEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setElectrcialMasterEmailError(false);
      }
    }
    if (
      electricalObject?.email &&
      electricalObject?.phoneNumber &&
      electricalObject?.licenseNumber &&
      electricalObject?.licenseExpirationDate &&
      electricalObject?.address &&
      electricalObject?.city &&
      electricalObject?.zip &&
      electricalObject?.masterElectricianName &&
      electricalObject?.masterElectricianEmail &&
      electricalObject?.masterElectricianPhoneNumber &&
      electricalObject?.masterElectricianLicenseNumber &&
      electricalObject?.masterElectricianLicenseExpirationDate &&
      electricalObject?.masterElectricianAddress &&
      electricalObject?.masterElectricianCity &&
      electricalObject?.masterElectricianZip
    ) {
      const finalElectricalContractorObject = {
        type: "Electrical",
        id: electricalId,
        applicationId: selectedApplicationInfo?.id,
        name: null,
        email: electricalObject.email,
        companyName: electricalObject.companyName,
        phoneNumber: electricalObject.phoneNumber,
        licenseNumber: electricalObject.licenseNumber,
        licenseExpirationDate: electricalObject.licenseExpirationDate,
        address: electricalObject.address,
        city: electricalObject.city,
        zip: electricalObject.zip,
        masterElectricianName: electricalObject.masterElectricianName,
        masterElectricianEmail: electricalObject.masterElectricianEmail,
        masterElectricianPhoneNumber:
          electricalObject.masterElectricianPhoneNumber,
        masterElectricianLicenseNumber:
          electricalObject.masterElectricianLicenseNumber,
        masterElectricianLicenseExpirationDate:
          electricalObject.masterElectricianLicenseExpirationDate,
        masterElectricianAddress: electricalObject.masterElectricianAddress,
        masterElectricianCity: electricalObject.masterElectricianCity,
        masterElectricianZip: electricalObject.masterElectricianZip,
        temporary: false,
      };
      setElectricalContractorLoader(true);
      dispatch(
        createContractor(
          finalElectricalContractorObject,
          handleCreateElectricalContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Electrical Contractor"
      );
    }
  };

  const handleCreatePlumberContractorSubmit = () => {
    setSavePlumberContractorPressed(true);
    if (plumberObject?.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(plumberObject?.email)) {
        setPlumberEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setPlumberEmailError(false);
      }
    }
    if (
      plumberObject?.companyName &&
      plumberObject?.email &&
      plumberObject?.phoneNumber &&
      plumberObject?.licenseNumber &&
      plumberObject?.licenseExpirationDate &&
      plumberObject?.address &&
      plumberObject?.city &&
      plumberObject?.zip
    ) {
      const finalPlumberContractorObject = {
        type: "Plumbing",
        temporary: false,
        name: null,
        email: plumberObject?.email,
        companyName: plumberObject?.companyName,
        phoneNumber: plumberObject?.phoneNumber,
        licenseNumber: plumberObject?.licenseNumber,
        licenseExpirationDate: plumberObject?.licenseExpirationDate,
        address: plumberObject?.address,
        city: plumberObject?.city,
        zip: plumberObject?.zip,
      };
      setPlumberContractorLoader(true);
      dispatch(
        createContractor(
          finalPlumberContractorObject,
          handleCreatePlumberContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Plumbing Contractor"
      );
    }
  };

  const handleCreateHVACContractorSubmit = () => {
    setSaveHvacContractorPressed(true);
    if (heatObject?.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(heatObject?.email)) {
        setHvacEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setHvacEmailError(false);
      }
    }
    if (
      heatObject?.companyName &&
      heatObject?.phoneNumber &&
      heatObject?.licenseNumber &&
      heatObject?.licenseExpirationDate &&
      heatObject?.email
    ) {
      const finalHvacContractorObject = {
        type: "HVAC",
        temporary: false,
        name: null,
        email: heatObject?.email,
        companyName: heatObject?.companyName,
        phoneNumber: heatObject?.phoneNumber,
        licenseNumber: heatObject?.licenseNumber,
        licenseExpirationDate: heatObject?.licenseExpirationDate,
        address: heatObject?.address,
        city: heatObject?.city,
        zip: heatObject?.zip,
      };
      setHvacContractorLoader(true);
      dispatch(
        createContractor(
          finalHvacContractorObject,
          handleCreateHVACContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Hvac Contractor"
      );
    }
  };

  const handleSelectedPermitRequest = (value) => {
    for (var i = 0; i < permitRequested?.length; i++) {
      for (var j = 0; j < value?.length; j++) {
        if (permitRequested[i].name == value[j].name) {
          if (permitRequested[i].name === "Other") {
            setShowPermitOtherField(true);
          } else {
            setShowPermitOtherField(false);
          }
        }
      }
    }
    setPermitIds(value.map((item) => item.id));
  };

  const handleOpenEmailModal = () => {
    if (emailModalOpen || disablePdfButton) {
      fireToasterContext.fireToasterHandler(
        false,
        "Please save application details first"
      );
      return;
    }
    setOpenEmailDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenEmailDialog(false);
  };

  const handleDwellContractorApiResponse = (data) => {
    setContractorLoader(false);
    if (data.status) {
      setContractorId(data.data.id);
      setContractorObject(data?.data);
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "contractor created"
      );
      setContractorObject(data?.data);
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleGeneralContractorApiResponse = (data) => {
    setGeneralContractorLoader(false);
    if (data.status) {
      setContractorId(data.data.id);
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "contractor created"
      );
      setGeneralObject(data?.data);
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleElectricalContractorApiResponse = (data) => {
    setElectricalContractorLoader(false);
    if (data.status) {
      setElectricalId(data.data.id);
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "contractor created"
      );
      setElectricalObject(data?.data);
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handlePlumberContractorApiResponse = (data) => {
    setPlumberContractorLoader(false);
    if (data.status) {
      setPlumberId(data.data.id);
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "contractor created"
      );
      setPlumberObject(data?.data);
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleHVACContractorApiResponse = (data) => {
    setHvacContractorLoader(false);
    if (data.status) {
      setHvacId(data.data.id);
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "contractor created"
      );
      setHeatObject(data?.data);
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleDwellContractorSubmit = () => {
    setSaveContractorPressed(true);
    // contractorObject.email
    if (contractorObject?.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(contractorObject?.email)) {
        setDwellEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setDwellEmailError(false);
      }
    }
    if (contractorObject?.dwellingContractorQualifierEmail) {
      var patternQualifierEmail = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (
        !patternQualifierEmail.test(
          contractorObject.dwellingContractorQualifierEmail
        )
      ) {
        setDwellQualifierEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setDwellQualifierEmailError(false);
      }
    }
    if (
      contractorObject?.name &&
      contractorObject?.companyName &&
      contractorObject?.phoneNumber &&
      contractorObject?.email &&
      contractorObject?.licenseNumber &&
      contractorObject?.licenseExpirationDate &&
      contractorObject?.dwellingContractorQualifierEmail &&
      contractorObject?.dwellingContractorQualifierName &&
      contractorObject?.dwellingContractorQualifierLicenseNumber &&
      contractorObject?.dwellingContractorQualifierAddress &&
      contractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
      contractorObject?.dwellingContractorQualifierCity &&
      contractorObject?.dwellingContractorQualifierZip &&
      contractorObject?.dwellingContractorQualifierEmail &&
      contractorObject?.dwellingContractorQualifierPhoneNumber
    ) {
      const finalDwellContractorObject = {
        id: contractorId,
        applicationId: selectedApplicationInfo?.id,
        type: "Construction",
        name: contractorObject?.name,
        email: contractorObject?.email,
        companyName: contractorObject?.companyName,
        phoneNumber: contractorObject?.phoneNumber,
        licenseNumber: contractorObject?.licenseNumber,
        licenseExpirationDate: contractorObject?.licenseExpirationDate,
        address: contractorObject?.address,
        city: contractorObject?.city,
        zip: contractorObject?.zip,
        dwellingContractorQualifierName:
          contractorObject?.dwellingContractorQualifierName,
        dwellingContractorQualifierLicenseNumber:
          contractorObject?.dwellingContractorQualifierLicenseNumber,
        dwellingContractorQualifierAddress:
          contractorObject?.dwellingContractorQualifierAddress,
        dwellingContractorQualifierLicenseExpirationDate:
          contractorObject?.dwellingContractorQualifierLicenseExpirationDate,
        dwellingContractorQualifierCity:
          contractorObject?.dwellingContractorQualifierCity,
        dwellingContractorQualifierZip:
          contractorObject?.dwellingContractorQualifierZip,
        dwellingContractorQualifierEmail:
          contractorObject?.dwellingContractorQualifierEmail,
        dwellingContractorQualifierPhoneNumber:
          contractorObject?.dwellingContractorQualifierPhoneNumber,
        temporary: false,
      };
      setContractorLoader(true);
      dispatch(
        updateContractorApplicationSlice(
          finalDwellContractorObject,
          handleDwellContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Dwelling Contractor"
      );
    }
  };

  const handleGeneralContractorSubmit = () => {
    setSaveGeneralContractorPressed(true);
    if (generalObject?.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(generalObject?.email)) {
        setGeneralEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setGeneralEmailError(false);
      }
    }
    if (
      generalObject?.name &&
      generalObject?.phoneNumber &&
      generalObject?.email &&
      generalObject?.companyName
    ) {
      const finalGeneralContractorObject = {
        type: "General",
        id: generalContractorId,
        applicationId: selectedApplicationInfo?.id,
        name: generalObject.name,
        email: generalObject.email,
        companyName: generalObject.companyName,
        phoneNumber: generalObject.phoneNumber,
        temporary: false,
      };
      setGeneralContractorLoader(true);
      dispatch(
        updateContractorApplicationSlice(
          finalGeneralContractorObject,
          handleGeneralContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Electrical Contractor"
      );
    }
  };

  const handleElectricContractorSubmit = () => {
    setSaveElectricalContractorPressed(true);
    if (electricalObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(electricalObject.email)) {
        setElectricalEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setElectricalEmailError(false);
      }
    }
    if (electricalObject.masterElectricianEmail) {
      var patternMasterEmail = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!patternMasterEmail.test(electricalObject.masterElectricianEmail)) {
        setElectrcialMasterEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setElectrcialMasterEmailError(false);
      }
    }
    if (
      // electricalObject.name &&
      // electricalObject.phoneNumber &&
      // electricalObject.email &&
      // electricalObject.licenseNumber &&
      // electricalObject.licenseExpirationDate &&
      // electricalObject.masterElectricianEmail &&
      // electricalObject.masterElectricianLicenseNumber &&
      // electricalObject.masterElectricianLicenseExpirationDate
      // electricalObject.name &&
      electricalObject.companyName &&
      electricalObject.email &&
      electricalObject.phoneNumber &&
      electricalObject.licenseNumber &&
      electricalObject.licenseExpirationDate &&
      electricalObject.address &&
      electricalObject.city &&
      electricalObject.zip &&
      electricalObject.masterElectricianName &&
      electricalObject.masterElectricianEmail &&
      electricalObject.masterElectricianPhoneNumber &&
      electricalObject.masterElectricianLicenseNumber &&
      electricalObject.masterElectricianLicenseExpirationDate &&
      electricalObject.masterElectricianAddress &&
      electricalObject.masterElectricianCity &&
      electricalObject.masterElectricianZip
    ) {
      const finalElectricalContractorObject = {
        type: "Electrical",
        id: electricalId,
        applicationId: selectedApplicationInfo?.id,
        name: electricalObject.name,
        email: electricalObject.email,
        companyName: electricalObject.companyName,
        phoneNumber: electricalObject.phoneNumber,
        licenseNumber: electricalObject.licenseNumber,
        licenseExpirationDate: electricalObject.licenseExpirationDate,
        address: electricalObject.address,
        city: electricalObject.city,
        zip: electricalObject.zip,
        masterElectricianName: electricalObject.masterElectricianName,
        masterElectricianEmail: electricalObject.masterElectricianEmail,
        masterElectricianPhoneNumber:
          electricalObject.masterElectricianPhoneNumber,
        masterElectricianLicenseNumber:
          electricalObject.masterElectricianLicenseNumber,
        masterElectricianLicenseExpirationDate:
          electricalObject.masterElectricianLicenseExpirationDate,
        masterElectricianAddress: electricalObject.masterElectricianAddress,
        masterElectricianCity: electricalObject.masterElectricianCity,
        masterElectricianZip: electricalObject.masterElectricianZip,
        temporary: false,
      };
      setElectricalContractorLoader(true);
      dispatch(
        updateContractorApplicationSlice(
          finalElectricalContractorObject,
          handleElectricalContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Electrical Contractor"
      );
    }
  };

  const handlePlumberContractorSubmit = () => {
    setSavePlumberContractorPressed(true);
    if (plumberObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(plumberObject.email)) {
        setPlumberEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setPlumberEmailError(false);
      }
    }
    if (
      plumberObject.companyName &&
      plumberObject.email &&
      plumberObject.phoneNumber &&
      plumberObject.licenseNumber &&
      plumberObject.licenseExpirationDate &&
      plumberObject.address &&
      plumberObject.city &&
      plumberObject.zip
    ) {
      const finalPlumberContractorObject = {
        type: "Plumbing",
        id: plumberId,
        applicationId: selectedApplicationInfo?.id,
        email: plumberObject.email,
        companyName: plumberObject.companyName,
        phoneNumber: plumberObject.phoneNumber,
        licenseNumber: plumberObject.licenseNumber,
        licenseExpirationDate: plumberObject.licenseExpirationDate,
        address: plumberObject.address,
        city: plumberObject.city,
        zip: plumberObject.zip,
        temporary: false,
      };
      setPlumberContractorLoader(true);
      dispatch(
        updateContractorApplicationSlice(
          finalPlumberContractorObject,
          handlePlumberContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Plumbing Contractor"
      );
    }
  };

  const handleHVACContractorSubmit = () => {
    setSaveHvacContractorPressed(true);
    if (heatObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(heatObject.email)) {
        setHvacEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setHvacEmailError(false);
      }
    }
    if (
      heatObject.companyName &&
      heatObject.email &&
      heatObject.phoneNumber &&
      heatObject.licenseNumber &&
      heatObject.licenseExpirationDate
    ) {
      const finalHvacContractorObject = {
        type: "HVAC",
        id: hvacId,
        applicationId: selectedApplicationInfo?.id,
        email: heatObject.email,
        companyName: heatObject.companyName,
        phoneNumber: heatObject.phoneNumber,
        licenseNumber: heatObject.licenseNumber,
        licenseExpirationDate: heatObject.licenseExpirationDate,
        address: heatObject.address,
        city: heatObject.city,
        zip: heatObject.zip,
        temporary: false,
      };
      setHvacContractorLoader(true);
      dispatch(
        updateContractorApplicationSlice(
          finalHvacContractorObject,
          handleHVACContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Hvac Contractor"
      );
    }
  };

  const handlePdfData = async (pdfData) => {
    let pdfLink;
    let newPdfData = JSON.parse(JSON.stringify(pdfData));
    const pdfBlob = await pdf(
      <ApplicationDetailFormPdf data={pdfData} />
    ).toBlob();
    const pdfBuffer = await new Response(pdfBlob).arrayBuffer();
    const params = {
      Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
      Key: `${process.env.REACT_APP_ENVIRONMENT}/${
        selectedApplicationInfo?.id
      }/Application-detail-${selectedApplicationInfo?.id}-${Date.now()}.pdf`,
      Body: pdfBuffer,
      ACL: "public-read",
      ContentType: "application/pdf",
    };
    try {
      await s3Client.send(new PutObjectCommand(params));
      pdfLink = `${process.env.REACT_APP_SPACES_ENDPOINT}/${params.Bucket}/${params.Key}`;
      if (pdfLink) {
        setModalOpen(false);
      }

      newPdfData.applicationDetailPdfUrl = pdfLink;
      localStorage.setItem("selectedApplication", JSON.stringify(newPdfData));
      const uploadPdfData = {
        applicationId: selectedApplicationInfo?.id,
        applicationDetailPdfUrl: pdfLink,
        occupancyLetterPdfUrl: null,
        buildingPlacardPdfUrl: null,
        permitFeePdfUrl: null,
      };
      dispatch(uploadPdf(uploadPdfData));
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handleUpdateApplicationResponse = (data, submitState) => {
    if (data.status) {
      if (submitState) {
        fireToasterContext.fireToasterHandler(
          true,
          data.message ? data.message : "Application Updated successfully"
        );
      }
      setLoader(false);
      dispatch(getApplicationPdf(handleApiResponse));
      dispatch(getSingleApplicationData(data.data?.id));
      setDisablePdfButton(false);
      setModalOpen(false);
      setSelectedContractorDataShow(false);
      setSelectedGeneralContractorDataShow(false);
      setSelectedElectricalContractorDataShow(false);
      setSelectedPlumberContractorDataShow(false);
      setSelectedHvacContractorDataShow(false);
      // window.location.reload();
    } else {
      setLoader(false);
      fireToasterContext.fireToasterHandler(
        false,
        data?.message ? data?.message : "Something went wrong"
      );
    }
  };

  const handleSubmit = () => {
    let submitToState = true;
    setSubmitPressed(true);
    if (applicationFormObject?.ownerEmail) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(applicationFormObject?.ownerEmail)) {
        setOwnerEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setOwnerEmailError(false);
      }
    }
    if (applicationFormObject.applicantEmail) {
      var patternApplicantEmail = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!patternApplicantEmail.test(applicationFormObject.applicantEmail)) {
        setApplicantEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setApplicantEmailError(false);
      }
    }
    if (
      showPermitOtherField &&
      !applicationFormObject.permitRequestedOtherDescription
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields"
      );
      return;
    }
    if (projectType < 3 && !applicationFormObject.totalSquareFeet) {
      fireToasterContext.fireToasterHandler(
        false,
        "Total square feet is required"
      );
      return;
    }

    if (permitIds?.includes(1) && !applicationFormObject?.constructionCost) {
      fireToasterContext.fireToasterHandler(
        false,
        "Construction cost is required"
      );
      return;
    }

    if (permitIds?.includes(3) && !applicationFormObject?.electricalCost) {
      fireToasterContext.fireToasterHandler(
        false,
        "Electrical cost is required"
      );
      return;
    }

    if (permitIds?.includes(4) && !applicationFormObject?.plumbingCost) {
      fireToasterContext.fireToasterHandler(false, "Plumber cost is required");
      return;
    }

    if (permitIds?.includes(2) && !applicationFormObject?.hvacCost) {
      fireToasterContext.fireToasterHandler(false, "HVAC cost is required");
      return;
    }

    // if building type and project type is correct and general contractor is not selected or created
    if (!applicationFormObject?.dwellingContractorByOwner) {
      if (
        !(buildingType === 5 && projectType === 1) &&
        permitIds?.includes(1) &&
        ((buildingType > 2 && projectType) ||
          (buildingType < 3 && projectType > 4)) &&
        !generalContractorId
      ) {
        fireToasterContext.fireToasterHandler(
          false,
          "General contractor is required"
        );
        return;
      }
    }

    // this condition for building type ad project type is true but contractor not selected or added to applicationFormObject
    if (!applicationFormObject?.dwellingContractorByOwner) {
      if (
        permitIds?.includes(1) &&
        buildingType < 3 &&
        projectType < 5 &&
        !contractorId
      ) {
        fireToasterContext.fireToasterHandler(
          false,
          "Dwelling contractor is required"
        );
        return;
      }
    }

    if (!applicationFormObject?.electricalContractorByOwner) {
      if (permitIds?.includes(3) && !electricalId) {
        fireToasterContext.fireToasterHandler(
          false,
          "Electrical contractor is required"
        );
        return;
      }
    }

    if (!applicationFormObject?.plumbingContractorByOwner) {
      if (permitIds?.includes(4) && !plumberId) {
        fireToasterContext.fireToasterHandler(
          false,
          "Plumber contractor is required"
        );
        return;
      }
    }

    if (!applicationFormObject?.hvacContractorByOwner) {
      if (permitIds.includes(2) && !hvacId) {
        fireToasterContext.fireToasterHandler(
          false,
          "Hvac contractor is required"
        );
        return;
      }
    }

    const setDwellContractorId = () => {
      let dwellingContractorId = null;
      if (permitIds?.includes(1)) {
        if (
          applicationFormObject.dwellingContractorByOwner &&
          (contractorId || generalContractorId)
        ) {
          dwellingContractorId = contractorId
            ? contractorId
            : generalContractorId;
          return dwellingContractorId;
        } else if (
          applicationFormObject.dwellingContractorByOwner &&
          (!contractorId || !generalContractorId)
        ) {
          dwellingContractorId = null;

          return dwellingContractorId;
        }
        if (!applicationFormObject.dwellingContractorByOwner && contractorId) {
          dwellingContractorId = contractorId;
          return dwellingContractorId;
        } else if (
          !applicationFormObject.dwellingContractorByOwner &&
          generalContractorId
        ) {
          dwellingContractorId = generalContractorId;
          return dwellingContractorId;
        } else {
          dwellingContractorId = null;
          return dwellingContractorId;
        }
      } else {
        dwellingContractorId = null;
        return dwellingContractorId;
      }
    };

    if (
      muncipalityId &&
      applicationFormObject.projectAddress &&
      projectType &&
      buildingType &&
      applicationStatus &&
      applicationFormObject.jobDescription &&
      permitIds.length > 0 &&
      applicationFormObject.ownerName &&
      applicationFormObject.ownerEmail &&
      applicationFormObject.ownerCity &&
      applicationFormObject.ownerZip &&
      applicationFormObject.ownerPhoneNumber &&
      applicationFormObject.ownerAddress &&
      personSubmittingApplicationName &&
      applicationFormObject.applicantName &&
      applicationFormObject.applicantEmail
    ) {
      const finalObject = {
        id: applicationFormObject.id,
        muncipalityId: muncipalityId,
        projectAddress: applicationFormObject.projectAddress,
        projectType: projectType,
        buildingType: buildingType,
        jobDescription: applicationFormObject.jobDescription,
        totalSquareFeet:
          projectType < 3 ? applicationFormObject.totalSquareFeet : 0,
        constructionCost:
          permitIds?.includes(1) ||
          permitIds?.includes(6) ||
          permitIds?.includes(7) ||
          permitIds?.includes(8) ||
          permitIds?.includes(9) ||
          permitIds?.includes(10)
            ? applicationFormObject.constructionCost
            : 0,
        electricalCost: permitIds?.includes(3)
          ? applicationFormObject.electricalCost
          : 0,
        plumbingCost: permitIds?.includes(4)
          ? applicationFormObject.plumbingCost
          : 0,
        hvacCost: permitIds?.includes(2) ? applicationFormObject.hvacCost : 0,
        ownerName: applicationFormObject.ownerName,
        ownerEmail: applicationFormObject.ownerEmail,
        ownerPhoneNumber: applicationFormObject.ownerPhoneNumber,
        ownerAddress: applicationFormObject.ownerAddress,
        ownerCity: applicationFormObject.ownerCity,
        ownerState: applicationFormObject.ownerState,
        ownerZip: applicationFormObject.ownerZip,
        permitRequested: permitIds,
        permitRequestedOtherDescription: showPermitOtherField
          ? applicationFormObject.permitRequestedOtherDescription
          : null,
        // dwellingContractorId: permitIds?.includes(1)
        //   ? applicationFormObject.dwellingContractorByOwner &&
        //     (generalContractorId || contractorId)
        //     ? contractorId
        //       ? contractorId
        //       : generalContractorId
        //     : null
        //   : null,
        dwellingContractorId: setDwellContractorId(),
        dwellingContractorByOwner: permitIds?.includes(1)
          ? applicationFormObject.dwellingContractorByOwner
          : false,
        electricalContractorId: permitIds?.includes(3) ? electricalId : null,
        electricalContractorByOwner: permitIds?.includes(3)
          ? applicationFormObject.electricalContractorByOwner
          : false,
        plumbingContractorId: permitIds?.includes(4) ? plumberId : null,
        plumbingContractorByOwner: permitIds?.includes(4)
          ? applicationFormObject.plumbingContractorByOwner
          : false,
        hvacContractorId: permitIds?.includes(2) ? hvacId : null,
        hvacContractorByOwner: permitIds?.includes(2)
          ? applicationFormObject.hvacContractorByOwner
          : false,
        personSubmittingApplication: personSubmittingApplicationName,
        applicantName: applicationFormObject.applicantName,
        applicantEmail: applicationFormObject.applicantEmail,
        note: applicationFormObject.note,
        status: applicationStatus.name,
      };

      setLoader(true);
      dispatch(
        updateApplicationDetail(
          finalObject,
          handleUpdateApplicationResponse,
          submitToState
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields"
      );
    }
  };

  const handleResponseForSubmitDataToState = (status, message) => {
    setSubmitToStateLoader(false);
    fireToasterContext.fireToasterHandler(status, message);
    dispatch(
      getSingleApplicationData(selectedApplicationInfo?.id, handleApiResponse)
    );
  };

  const handleSubmitDataToState = () => {
    let submitToState = false;

    if (permitIds?.includes(1) && !applicationFormObject?.constructionCost) {
      fireToasterContext.fireToasterHandler(
        false,
        "Construction cost is required"
      );
      return;
    }

    if (permitIds?.includes(3) && !applicationFormObject?.electricalCost) {
      fireToasterContext.fireToasterHandler(
        false,
        "Electrical cost is required"
      );
      return;
    }

    if (permitIds?.includes(4) && !applicationFormObject?.plumbingCost) {
      fireToasterContext.fireToasterHandler(false, "Plumber cost is required");
      return;
    }

    if (permitIds?.includes(2) && !applicationFormObject?.hvacCost) {
      fireToasterContext.fireToasterHandler(false, "HVAC cost is required");
      return;
    }

    if (
      permitIds?.includes(1) &&
      ((buildingType > 2 && projectType) ||
        (buildingType < 3 && projectType > 4)) &&
      !generalContractorId
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "General contractor is required"
      );
      return;
    }

    if (
      permitIds?.includes(1) &&
      buildingType < 3 &&
      projectType < 5 &&
      !contractorId
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "Dwelling contractor is required"
      );
      return;
    }

    if (permitIds?.includes(3) && !electricalId) {
      fireToasterContext.fireToasterHandler(
        false,
        "Electrical contractor is required"
      );
      return;
    }

    if (permitIds?.includes(4) && !plumberId) {
      fireToasterContext.fireToasterHandler(
        false,
        "Plumber contractor is required"
      );
      return;
    }

    if (!applicationFormObject?.hvacContractorByOwner) {
      if (permitIds.includes(2) && !hvacId) {
        fireToasterContext.fireToasterHandler(
          false,
          "Hvac contractor is required"
        );
        return;
      }
    }

    const finalObjectForApplicationToState = {
      id: applicationFormObject.id,
      muncipalityId: muncipalityId,
      projectAddress: applicationFormObject.projectAddress,
      projectType: projectType,
      buildingType: buildingType,
      jobDescription: applicationFormObject.jobDescription,
      totalSquareFeet:
        projectType < 3 ? applicationFormObject.totalSquareFeet : 0,
      constructionCost:
        permitIds?.includes(1) ||
        permitIds?.includes(6) ||
        permitIds?.includes(7) ||
        permitIds?.includes(8) ||
        permitIds?.includes(9) ||
        permitIds?.includes(10)
          ? applicationFormObject.constructionCost
          : 0,
      electricalCost: permitIds?.includes(3)
        ? applicationFormObject.electricalCost
        : 0,
      plumbingCost: permitIds?.includes(4)
        ? applicationFormObject.plumbingCost
        : 0,
      hvacCost: permitIds?.includes(2) ? applicationFormObject.hvacCost : 0,
      ownerName: applicationFormObject.ownerName,
      ownerEmail: applicationFormObject.ownerEmail,
      ownerPhoneNumber: applicationFormObject.ownerPhoneNumber,
      ownerAddress: applicationFormObject.ownerAddress,
      ownerCity: applicationFormObject.ownerCity,
      ownerState: applicationFormObject.ownerState,
      ownerZip: applicationFormObject.ownerZip,
      permitRequested: permitIds,
      permitRequestedOtherDescription: showPermitOtherField
        ? applicationFormObject.permitRequestedOtherDescription
        : null,
      dwellingContractorId:
        permitIds?.includes(1) ||
        permitIds?.includes(6) ||
        permitIds?.includes(7) ||
        permitIds?.includes(8) ||
        permitIds?.includes(9) ||
        permitIds?.includes(10)
          ? contractorId
            ? contractorId
            : generalContractorId
          : null,
      dwellingContractorByOwner:
        applicationFormObject.dwellingContractorByOwner,
      electricalContractorId: permitIds?.includes(3) ? electricalId : null,
      electricalContractorByOwner:
        applicationFormObject.electricalContractorByOwner,
      plumbingContractorId: permitIds?.includes(4) ? plumberId : null,
      plumbingContractorByOwner:
        applicationFormObject.plumbingContractorByOwner,
      hvacContractorId: permitIds?.includes(2) ? hvacId : null,
      hvacContractorByOwner: applicationFormObject.hvacContractorByOwner,
      personSubmittingApplication: personSubmittingApplicationName,
      applicantName: applicationFormObject.applicantName,
      applicantEmail: applicationFormObject.applicantEmail,
      note: applicationFormObject.note,
      status: applicationStatus.name,
    };
    // setLoader(true);
    dispatch(
      updateApplicationDetail(
        finalObjectForApplicationToState,
        handleUpdateApplicationResponse,
        submitToState
      )
    );
    setSubmitToStateLoader(true);
    let finalObject = {
      applicationId: selectedApplicationInfo.id,
      serverBaseUrl: process.env.REACT_APP_HOST_API_KEY,
    };
    dispatch(
      submitDataToState(finalObject, handleResponseForSubmitDataToState)
    );
  };

  const onlyDate = (dateStr) => {
    const [yyyy, mm, dd] = dateStr.split(/[/:\-T]/);
    const newDT = `${dd}/${mm}/${yyyy}`;
    return newDT;
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const checkExpireDate = (date, checkDateKey) => {
    let currentDate = new Date();
    let checkDate = new Date(date);

    if (checkDateKey === "dwellingContractorLicenseExpirationDate") {
      if (checkDate > currentDate) {
        setExpireDwellingContractorLicenseDate(false);
      } else {
        setExpireDwellingContractorLicenseDate(true);
      }
    }
    if (checkDateKey === "dwellingContractorQualifierLicenseExpirationDate") {
      if (checkDate > currentDate) {
        setExpireDwellingContractorQualifierLicenseDate(false);
      } else {
        setExpireDwellingContractorQualifierLicenseDate(true);
      }
    }

    if (checkDateKey === "licenseExpirationDate") {
      if (checkDate > currentDate) {
        setELectricianLicenseExpirationDate(false);
      } else {
        setELectricianLicenseExpirationDate(true);
      }
    }
    if (checkDateKey === "masterElectricianLicenseExpirationDate") {
      if (checkDate > currentDate) {
        setMasterELectricianLicenseExpirationDate(false);
      } else {
        setMasterELectricianLicenseExpirationDate(true);
      }
    }

    if (checkDateKey === "plumberLicenseExpirationDate") {
      if (checkDate > currentDate) {
        setPlumbingLicenseExpirationDate(false);
      } else {
        setPlumbingLicenseExpirationDate(true);
      }
    }

    if (checkDateKey === "heatObjectExpirationDate") {
      if (checkDate > currentDate) {
        setHvacLicenseExpirationDate(false);
      } else {
        setHvacLicenseExpirationDate(true);
      }
    }
  };

  const handleCloseDeleteDialog = (boolean) => {
    if (boolean === true) {
      setShowDeleteModal(false);
      localStorage.removeItem("selectedApplication");
      navigate("/");
    } else {
      setShowDeleteModal(false);
    }
  };

  return (
    <Card sx={{ width: "100%", flexGrow: 1 }}>
      <CardHeader
        disableTypography
        sx={applicationCardStyle}
        // title="Application Details"
        // action="hello"
        title={
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Application Detail</Box>
            <Box>
              Status:{application?.status} | Permit Fee:
              {(application?.totalAmount * 1)?.toFixed(2)}
            </Box>
          </Box>
        }
      />
      <CardContent
        sx={{
          height: "600px",
          overflowY: "auto",
          [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
          ...miniScrollBarStyles,
        }}
      >
        {/* <PDFViewer style={{ height: "100vh", width: "100%" }}>
          <ApplicationDetailFormPdf data={applicationPdfData} />
        </PDFViewer> */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              name="muncipalityId"
              id="muncipalityId"
              readOnly={manipulationPermission}
              options={muncipalityList}
              getOptionLabel={(option) => option.name || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Municipality"
                  sx={textfield}
                  error={!muncipalityId && submitPressed}
                  helperText={
                    !muncipalityId && submitPressed
                      ? "Municipality is required "
                      : ""
                  }
                />
              )}
              value={
                muncipalityList.find(
                  (option) => option?.id === muncipalityId
                ) || null
              }
              onChange={(event, value) => {
                setMuncipalityId(value ? value.id : null);
              }}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="applicationStatus"
              readOnly={manipulationPermission}
              label="Status"
              variant="outlined"
              name="applicationStatus"
              getOptionLabel={(option) => option.name || ""}
              options={applicationStatusArr || []}
              freeSolo
              value={applicationStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={textfield}
                  label="Status"
                  error={!applicationStatus && submitPressed}
                  helperText={
                    !applicationStatus && submitPressed
                      ? "Application Status is required "
                      : ""
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography
                    sx={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      textTransform: "capitalize",
                    }}
                    variant="inputOutputList"
                  >
                    {option.name}
                  </Typography>
                </li>
              )}
              onChange={(event, newValue) => {
                // handleChangeStatus(newValue);
                setApplicationStatus(newValue);
                // setDisablePdfButton(false);
                setDisablePdfButton(true);
                // if (newValue?.name === "Delete") {
                //   setApplicationStatus(null);
                //   setDisablePdfButton(true);
                //   setShowDeleteModal(true);
                // }
              }}
            />
          </Grid>
        </Grid>
        {/* project information box */}
        <Box component="div" sx={{ marginTop: "20px" }}>
          <Typography sx={applicantTypos}>Project Information</Typography>
          <Grid container spacing={2}>
            {/* project address */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                error={!applicationFormObject.projectAddress && submitPressed}
                helperText={
                  !applicationFormObject.projectAddress && submitPressed
                    ? "Project address is required"
                    : ""
                }
                id="project-address"
                label="Project Address"
                name="projectAddress"
                value={applicationFormObject.projectAddress || ""}
                sx={textfield}
                onChange={handleChange}
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>

            {/* project type dropdown */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Autocomplete
                id="project-type"
                readOnly={manipulationPermission}
                options={projectTypeList}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project Type"
                    sx={textfield}
                    error={!projectType && submitPressed}
                    helperText={
                      !projectType && submitPressed
                        ? "Project type is required"
                        : ""
                    }
                  />
                )}
                value={
                  projectTypeList.find((option) => option.id === projectType) ||
                  null
                }
                onChange={(event, value) => {
                  if (buildingType < 3 && value.id < 5) {
                    setGeneralContractorId(null);
                    setGeneralContractorId(null);
                  } else if (
                    (buildingType > 2 && projectType) ||
                    (buildingType < 3 && projectType > 4)
                  ) {
                    setContractorId(null);
                    setContractorObject(null);
                  }
                  setProjectType(value ? value.id : null);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
              />
            </Grid>

            {/* building type dropdown */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Autocomplete
                id="building-type"
                readOnly={manipulationPermission}
                options={buildingTypeList}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Building Type"
                    sx={textfield}
                    error={!buildingType && submitPressed}
                    helperText={
                      !buildingType && submitPressed
                        ? "Building type is required"
                        : ""
                    }
                  />
                )}
                value={
                  buildingTypeList.find(
                    (option) => option?.id === buildingType
                  ) || null
                }
                onChange={(event, value) => {
                  if (value.id < 3 && projectType < 5) {
                    if (application.buildingType == value.id) {
                      setContractorId(application.dwellingContractorId);
                      setContractorObject(application.dwellingContractor);
                    } else {
                      setGeneralContractorId(null);
                      setGeneralContractorId(null);
                    }
                  } else if (
                    (value.id > 2 && projectType < 5) ||
                    (value.id < 3 && projectType > 4)
                  ) {
                    if (application.buildingType == value.id) {
                      setGeneralContractorId(application.dwellingContractorId);
                      setGeneralObject(application.dwellingContractor);
                    } else {
                      setContractorId(null);
                      setContractorObject(null);
                    }
                  }
                  setBuildingType(value ? value.id : null);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
              />
            </Grid>
          </Grid>
        </Box>

        {/* job description box */}
        <Box component="div" sx={{ marginTop: "20px" }}>
          <Typography sx={applicantTypos}>Job Description</Typography>
          <Grid container spacing={2}>
            {/* job description */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                rows={3}
                multiline
                error={!applicationFormObject.jobDescription && submitPressed}
                helperText={
                  !applicationFormObject.jobDescription && submitPressed
                    ? "Job description is required"
                    : ""
                }
                id="job-description"
                name="jobDescription"
                value={applicationFormObject?.jobDescription || ""}
                label="Job Description"
                sx={textfield}
                onChange={handleChange}
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>

            {/* Total square feet for new buildings and additions */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                error={
                  projectType < 3 &&
                  !applicationFormObject.totalSquareFeet &&
                  submitPressed
                }
                helperText={
                  projectType < 3 &&
                  !applicationFormObject.totalSquareFeet &&
                  submitPressed
                    ? "Total square feet is required"
                    : ""
                }
                InputProps={{
                  readOnly: manipulationPermission,
                }}
                id="square-feet-for-new-building"
                type="number"
                label="Total square feet for new buildings and additions"
                name="totalSquareFeet"
                value={applicationFormObject?.totalSquareFeet || ""}
                sx={textfield}
                onChange={handleChange}
                inputProps={{ min: 1 }}
              />
            </Grid>

            {/* Total square feet for new buildings and additions */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {/* permit request dropdown */}
              <Autocomplete
                multiple
                disableCloseOnSelect
                name="permitRequested"
                id="permit-requested"
                readOnly={manipulationPermission}
                options={permitRequested}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Permit Requested"
                    sx={textfield}
                    error={permitIds?.length < 1 && submitPressed}
                    helperText={
                      permitIds?.length < 1 && submitPressed
                        ? "Permit requested is required"
                        : ""
                    }
                  />
                )}
                value={
                  permitRequested.filter((option) =>
                    permitIds?.find((permitId) => option.id == permitId)
                  ) || null
                }
                onChange={(event, value) => {
                  handleSelectedPermitRequest(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
              />
            </Grid>

            {showPermitOtherField && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={
                    !applicationFormObject.permitRequestedOtherDescription &&
                    submitPressed
                  }
                  helperText={
                    !applicationFormObject.permitRequestedOtherDescription &&
                    submitPressed
                      ? "Permit other description is required"
                      : ""
                  }
                  id="square-feet-for-new-building"
                  label="Permit Requested Other Description"
                  value={
                    showPermitOtherField
                      ? applicationFormObject?.permitRequestedOtherDescription
                      : ""
                  }
                  name="permitRequestedOtherDescription"
                  sx={textfield}
                  onChange={handleChange}
                  // type="number"
                  InputProps={{
                    readOnly: manipulationPermission,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>

        {/* cost box */}
        <Box component="div" sx={{ marginTop: "20px" }}>
          <Typography sx={applicantTypos}>Cost</Typography>
          <Box sx={{ mb: 1 }}>
            {" "}
            <Typography variant="subText" sx={applicantTypos}>
              Cost = Material + Labor
            </Typography>
          </Box>

          <Grid container spacing={2}>
            {/* Cunstruction cost */}
            {(permitIds?.includes(1) ||
              permitIds?.includes(6) ||
              permitIds?.includes(7) ||
              permitIds?.includes(8) ||
              permitIds?.includes(9) ||
              permitIds?.includes(10)) && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="construction-cost"
                  label="Construction Cost"
                  name="constructionCost"
                  value={applicationFormObject?.constructionCost || ""}
                  type="number"
                  sx={textfield}
                  onChange={handleChange}
                  inputProps={{ min: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: manipulationPermission,
                  }}
                  error={
                    !applicationFormObject?.constructionCost && submitPressed
                  }
                  helperText={
                    !applicationFormObject?.constructionCost && submitPressed
                      ? "Construction Cost is required"
                      : ""
                  }
                />
              </Grid>
            )}

            {/* electrical cost */}
            {permitIds?.includes(3) && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="electricalCost"
                  label="Electrical Cost"
                  name="electricalCost"
                  value={applicationFormObject?.electricalCost || ""}
                  type="number"
                  sx={textfield}
                  onChange={handleChange}
                  inputProps={{ min: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: manipulationPermission,
                  }}
                  error={
                    !applicationFormObject?.electricalCost && submitPressed
                  }
                  helperText={
                    !applicationFormObject?.electricalCost && submitPressed
                      ? "Electrical Cost is required"
                      : ""
                  }
                />
              </Grid>
            )}

            {/* plumbing cost */}
            {permitIds?.includes(4) && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="electricalCost"
                  label="Plumbing Cost"
                  name="plumbingCost"
                  value={applicationFormObject?.plumbingCost || ""}
                  type="number"
                  sx={textfield}
                  onChange={handleChange}
                  inputProps={{ min: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: manipulationPermission,
                  }}
                  error={!applicationFormObject?.plumbingCost && submitPressed}
                  helperText={
                    !applicationFormObject?.plumbingCost && submitPressed
                      ? "Plumbing Cost is required"
                      : ""
                  }
                />
              </Grid>
            )}

            {/* hvac cost */}
            {permitIds?.includes(2) && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="hvacCost"
                  label="HVAC Cost"
                  name="hvacCost"
                  value={applicationFormObject?.hvacCost || ""}
                  type="number"
                  sx={textfield}
                  onChange={handleChange}
                  inputProps={{ min: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    readOnly: manipulationPermission,
                  }}
                  error={!applicationFormObject?.hvacCost && submitPressed}
                  helperText={
                    !applicationFormObject?.hvacCost && submitPressed
                      ? "HVAC Cost is required"
                      : ""
                  }
                />
              </Grid>
            )}
          </Grid>
        </Box>

        {/* owner information box */}
        <Box component="div" sx={{ marginTop: "20px" }}>
          <Typography sx={applicantTypos}>Owner Information</Typography>
          <Grid container spacing={2}>
            {/* owner name */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                error={!applicationFormObject.ownerName && submitPressed}
                helperText={
                  !applicationFormObject.ownerName && submitPressed
                    ? "Owner name is required"
                    : ""
                }
                id="owner-name"
                name="ownerName"
                label="Name"
                value={applicationFormObject?.ownerName || ""}
                sx={textfield}
                onChange={handleChange}
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>

            {/* Owner email */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                error={
                  !!ownerEmailError ||
                  (!applicationFormObject?.ownerEmail && submitPressed)
                }
                helperText={
                  ownerEmailError ||
                  (!applicationFormObject?.ownerEmail && submitPressed)
                    ? "Please enter a valid email address"
                    : ""
                }
                id="owner-email"
                label="Email"
                name="ownerEmail"
                value={applicationFormObject?.ownerEmail || ""}
                sx={textfield}
                onChange={handleChange}
                type="email"
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>

            {/* owner phonenumber */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                error={!applicationFormObject.ownerPhoneNumber && submitPressed}
                helperText={
                  !applicationFormObject.ownerPhoneNumber && submitPressed
                    ? "Owner phone number is required"
                    : ""
                }
                id="owner-phonenumber"
                label="Phone Number"
                name="ownerPhoneNumber"
                value={
                  applicationFormObject?.ownerPhoneNumber
                    ? applicationFormObject?.ownerPhoneNumber
                    : ""
                }
                sx={textfield}
                onChange={handleChange}
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>

            {/* owner address */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                error={!applicationFormObject.ownerAddress && submitPressed}
                helperText={
                  !applicationFormObject.ownerAddress && submitPressed
                    ? "Owner address is required"
                    : ""
                }
                id="owner-address"
                name="ownerAddress"
                label="Address"
                value={applicationFormObject?.ownerAddress || ""}
                sx={textfield}
                onChange={handleChange}
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>

            {/* owner city */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="owner-city"
                label="City"
                name="ownerCity"
                value={applicationFormObject?.ownerCity || ""}
                sx={textfield}
                onChange={handleChange}
                error={!applicationFormObject.ownerCity && submitPressed}
                helperText={
                  !applicationFormObject.ownerCity && submitPressed
                    ? "Owner city is required"
                    : ""
                }
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>

            {/* owner state */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="owner-state"
                label="State"
                name="ownerState"
                value={applicationFormObject?.ownerState || ""}
                sx={textfield}
                onChange={handleChange}
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>

            {/* owner zipcode */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                type="number"
                id="owner-zipcode"
                label="Zip code"
                name="ownerZip"
                value={applicationFormObject?.ownerZip || ""}
                sx={textfield}
                onChange={handleChange}
                error={!applicationFormObject.ownerZip && submitPressed}
                helperText={
                  !applicationFormObject.ownerZip && submitPressed
                    ? "Owner zip is required"
                    : ""
                }
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* General Contractor info box */}
        {permitIds?.includes(1) &&
          ((buildingType > 2 && projectType) ||
            (buildingType < 3 && projectType > 4)) &&
          (selectedGeneralContractorDataShow ? (
            <ApplicationFormContractorDetail
              type={
                (buildingType > 2 && projectType) ||
                (buildingType < 3 && projectType > 4)
                  ? "General"
                  : ""
              }
              data={selectedGeneralContractorData}
              handleClose={handleShowSelectedGeneralFormClose}
            />
          ) : generalContractorId ? (
            <Box component="div" sx={{ marginTop: "20px" }}>
              <Typography sx={applicantTypos}>
                General Contractor Information
              </Typography>
              <Grid container spacing={2} sx={{ my: "5px" }}>
                {/* General contractor name field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!generalObject?.name && saveGeneralContractorPressed}
                    helperText={
                      !generalObject?.name && saveGeneralContractorPressed
                        ? "Name is required"
                        : ""
                    }
                    id="general-contractor-name"
                    label="General Contractor Contact Name"
                    value={generalObject?.name || ""}
                    name="name"
                    sx={textfield}
                    onChange={handleGeneralChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* General Contractor Company  field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !generalObject?.companyName &&
                      saveGeneralContractorPressed
                    }
                    helperText={
                      !generalObject?.companyName &&
                      saveGeneralContractorPressed
                        ? "Company name is required"
                        : ""
                    }
                    id="general-contractor-company-name"
                    label="General Contractor Company"
                    name="companyName"
                    value={generalObject?.companyName || ""}
                    sx={textfield}
                    onChange={handleGeneralChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* General contractor email field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="general-contractor-email"
                    label="General Contractor Company Email"
                    name="email"
                    value={generalObject?.email || ""}
                    type="email"
                    sx={textfield}
                    onChange={handleGeneralChange}
                    error={
                      !!generalEmailError ||
                      (!generalObject?.email && saveGeneralContractorPressed)
                    }
                    helperText={
                      generalEmailError ||
                      (!generalObject?.email && saveGeneralContractorPressed)
                        ? "Please enter a valid email address"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* general contractor phone number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !generalObject?.phoneNumber &&
                      saveGeneralContractorPressed
                    }
                    helperText={
                      !generalObject?.phoneNumber &&
                      saveGeneralContractorPressed
                        ? "Phone number is required"
                        : ""
                    }
                    id="genral-contractor-phone-number"
                    label="General Contractor Company Phone No."
                    name="phoneNumber"
                    value={generalObject?.phoneNumber || ""}
                    sx={textfield}
                    onChange={handleGeneralChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  align="right"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  {!generalObject?.temporary && (
                    <Typography
                      sx={{
                        fontFamily: "Poppins-semibold",
                        fontSize: "16px",
                      }}
                    >
                      Already exist in DB
                    </Typography>
                  )}
                  <Button
                    sx={{
                      ...saveButton,
                      width: "230px",
                      backgroundColor: "#d52027",
                      "&:hover": {
                        backgroundColor: "red",
                        transition: ".7s ease-in",
                        boxShadow: 2,
                      },
                      "&:disabled": {
                        color: "white",
                      },
                    }}
                    disabled={manipulationPermission}
                    onClick={() => {
                      handleChangeForAddNewContractor("generalContractor");
                    }}
                  >
                    Change Contractor
                  </Button>
                  <Button
                    disabled={manipulationPermission}
                    sx={{
                      ...saveButton,
                      width:
                        generalObject?.temporary?.toString() === "true"
                          ? "230px"
                          : "230px",
                      backgroundColor: generalObject?.temporary
                        ? "#D52027"
                        : "#32363F",
                      "&:hover": {
                        backgroundColor: generalObject?.temporary
                          ? "red"
                          : "#000000",
                        transition: ".7s ease-in",
                        boxShadow: 2,
                      },
                      "&:disabled": {
                        color: "white",
                      },
                    }}
                    onClick={() => {
                      handleGeneralContractorSubmit();
                    }}
                  >
                    {generalObject?.temporary
                      ? "Add contractor to Database"
                      : "Update"}
                    {generalContractorLoader ? <CircularProgressLoader /> : ""}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              <Box component="div" sx={{ marginTop: "20px" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                >
                  Construction
                </Typography>

                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "unset",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      "&.MuiFormControlLabel-root": {
                        "&.MuiFormControlLabel-label": {
                          "&.Mui-disabled": {
                            color: "green !important",
                          },
                        },
                      },
                    }}
                    control={
                      <Checkbox
                        name="dwellingContractorByOwner"
                        checked={
                          applicationFormObject?.dwellingContractorByOwner
                            ? applicationFormObject?.dwellingContractorByOwner
                            : false
                        }
                        sx={{
                          fontSize: 60,
                          "&.Mui-disabled": { color: "#D52027 " },
                        }}
                        disabled={
                          !localStorage
                            .getItem("permissions")
                            .includes("manipulate application details")
                        }
                        onChange={handleChangeCheckBox}
                      />
                    }
                    label="By Owner"
                  />
                </FormGroup>

                <Typography
                  variant="body1"
                  sx={{
                    color: "#414042",
                    fontWeight: 300,
                    fontSize: "15px",
                    marginTop: "5px",
                  }}
                >
                  Search General Contractor if the contractor is not listed
                  click the add button to enter contractors information
                </Typography>
                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <TextField
                      id="search-dweeling-contractor"
                      placeholder="Enter name, company name or email "
                      name="searchGeneralContractor"
                      value={generalContractorSearchString}
                      sx={textfield}
                      onChange={(event) => {
                        handleContractorSearchString(
                          event.target.value,
                          "generalContractor"
                        );
                      }}
                      disabled={
                        !localStorage
                          .getItem("permissions")
                          .includes("manipulate application details")
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      disabled={
                        !generalContractorSearchString ||
                        !localStorage
                          .getItem("permissions")
                          .includes("manipulate application details")
                      }
                      sx={applicationDetailFormSearchButton}
                      onClick={() =>
                        handleFilterContractorType("generalContractor")
                      }
                    >
                      <AddBoxOutlined /> Search
                    </Button>
                  </Grid>
                </Grid>
                {showGeneralTable && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#414042",
                        fontWeight: 300,
                        fontSize: "15px",
                        marginTop: "5px",
                      }}
                    >
                      Click desired contractor from search results
                    </Typography>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={tableHeadCell}>Name</TableCell>
                            <TableCell sx={tableHeadCell}>
                              Company Name
                            </TableCell>
                            <TableCell sx={tableHeadCell}>Email</TableCell>
                            <TableCell sx={tableHeadCell}>
                              Phone Number
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {generalContractors
                            ?.slice(
                              generalContractorPage *
                                rowsPerPageForGeneralContractor,
                              generalContractorPage *
                                rowsPerPageForGeneralContractor +
                                rowsPerPageForGeneralContractor
                            )
                            ?.map((contractor, index) => (
                              <TableRow
                                // selected={contractorId === contractor.id}
                                key={index}
                                sx={[
                                  tableDataRow,
                                  {
                                    backgroundColor:
                                      contractor.id === contractorId
                                        ? "crimson"
                                        : "",
                                    color:
                                      contractor.id === contractorId
                                        ? "white"
                                        : "",
                                  },
                                ]}
                                onClick={() =>
                                  handleGeneralContractorTableRowClick(
                                    contractor
                                  )
                                }
                              >
                                <TableCell sx={tableDataCell}>
                                  {contractor?.name}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {contractor?.companyName}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {contractor?.email}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {contractor?.phoneNumber}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <CustomPagination
                      page={generalContractorPage}
                      setPage={setGeneralContractorPage}
                      rowsPerPage={rowsPerPageForGeneralContractor}
                      handleChangePage={handleGeneralContractorChangePage}
                      filteredList={generalContractors}
                      setRowsPerPage={setRowsPerPageForGeneralContractor}
                    />
                    <Button
                      sx={applicationFormCreateButton}
                      onClick={() => {
                        setShowGeneralTable(false);
                        setShowGeneralForm(true);
                      }}
                    >
                      <AddBoxOutlined /> Add General Contractor
                    </Button>
                  </>
                )}
              </Box>
              {showGeneralForm && (
                <Box component="div" sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#414042",
                      fontWeight: 600,
                      fontSize: "15px",
                    }}
                  >
                    General Contractor Information
                  </Typography>
                  <Grid container spacing={2} sx={{ my: "5px" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !generalObject?.name && saveGeneralContractorPressed
                        }
                        helperText={
                          !generalObject?.name && saveGeneralContractorPressed
                            ? "Name is required"
                            : ""
                        }
                        id="general-contractor-name"
                        label="General Contractor Contact Name"
                        value={generalObject?.name ? generalObject?.name : ""}
                        name="name"
                        sx={textfield}
                        onChange={handleGeneralChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !generalObject?.companyName &&
                          saveGeneralContractorPressed
                        }
                        helperText={
                          !generalObject?.companyName &&
                          saveGeneralContractorPressed
                            ? "Company name is required"
                            : ""
                        }
                        id="general-contractor-company-name"
                        label="General Contractor Company"
                        name="companyName"
                        value={
                          generalObject?.companyName
                            ? generalObject?.companyName
                            : ""
                        }
                        sx={textfield}
                        onChange={handleGeneralChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="general-contractor-email"
                        label="General Contractor Company Email"
                        name="email"
                        value={generalObject?.email}
                        type="email"
                        sx={textfield}
                        onChange={handleGeneralChange}
                        error={
                          generalEmailError ||
                          (!generalObject?.email &&
                            saveGeneralContractorPressed)
                        }
                        helperText={
                          generalEmailError ||
                          (!generalObject?.email &&
                            saveGeneralContractorPressed)
                            ? "Please enter a valid email address"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !generalObject?.phoneNumber &&
                          saveGeneralContractorPressed
                        }
                        helperText={
                          !generalObject?.phoneNumber &&
                          saveGeneralContractorPressed
                            ? "Phone no is required"
                            : ""
                        }
                        id="general-contractor-phone-number"
                        label="General Contractor Company Phone No."
                        name="phoneNumber"
                        value={generalObject?.phoneNumber}
                        sx={textfield}
                        onChange={handleGeneralChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        sx={{
                          ...saveContractorButton,
                          backgroundColor: generalContractorLoader
                            ? "grey"
                            : "#d52027",
                        }}
                        onClick={() => {
                          handleCreateGeneralContractorSubmit();
                        }}
                      >
                        Save Contractor
                        {generalContractorLoader ? (
                          <CircularProgressLoader />
                        ) : (
                          ""
                        )}
                      </Button>
                      <Button
                        sx={cancelContractorButton}
                        onClick={() => {
                          setGeneralObject({});
                          setShowGeneralForm(false);
                          setShowGeneralTable(true);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          ))}

        {/* Dwelling Contractor info box */}
        {permitIds?.includes(1) &&
          buildingType < 3 &&
          projectType < 5 &&
          (selectedContractorDataShow ? (
            <ApplicationFormContractorDetail
              type={buildingType < 3 && projectType < 5 ? "Dwelling" : ""}
              data={selectedContractorData}
              handleClose={handleShowSelectedFormClose}
            />
          ) : dwellingContractorToBeUpdated && contractorId ? (
            <Box component="div" sx={{ marginTop: "20px" }}>
              <Typography sx={applicantTypos}>
                Dwelling Contractor Information
              </Typography>
              <Grid container spacing={2} sx={{ my: "5px" }}>
                {/* General contractor name field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!contractorObject?.name && saveContractorButton}
                    helperText={
                      !contractorObject?.name && saveContractorButton
                        ? "Name is required"
                        : ""
                    }
                    id="dwelling-contractor-name"
                    label="Dwelling Contractor Name"
                    value={contractorObject?.name || ""}
                    name="name"
                    sx={textfield}
                    onChange={handleContractorChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* Dwelling contractor company name field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-company-name"
                    label="Dwelling Contractor Company"
                    name="companyName"
                    value={contractorObject?.companyName || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.companyName && saveContractorButton
                    }
                    helperText={
                      !contractorObject?.companyName && saveContractorButton
                        ? "Company name is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dweeling contractor email field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-email"
                    label="Dwelling Contractor Email"
                    name="email"
                    value={contractorObject?.email || ""}
                    type="email"
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !!dwellEmailError ||
                      (!contractorObject?.email && saveContractorPressed)
                    }
                    helperText={
                      dwellEmailError ||
                      (!contractorObject?.email && saveContractorPressed)
                        ? "Please enter a valid email address"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor phone number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !contractorObject?.phoneNumber && saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.phoneNumber && saveContractorPressed
                        ? "Phone number is required"
                        : ""
                    }
                    id="dwelling-contractor-phone-number"
                    label="Dwelling Contractor Phone No."
                    name="phoneNumber"
                    value={contractorObject?.phoneNumber || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor license number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !contractorObject?.licenseNumber && saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.licenseNumber && saveContractorPressed
                        ? "License number is required"
                        : ""
                    }
                    id="dwelling-contractor-license-number"
                    label="Dwelling Contractor License No."
                    name="licenseNumber"
                    value={contractorObject?.licenseNumber || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor license expire date */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      (!contractorObject?.licenseExpirationDate &&
                        saveContractorPressed,
                      expireDwellingContractorLicenseDate)
                    }
                    helperText={
                      (!contractorObject?.licenseExpirationDate &&
                      saveContractorPressed
                        ? "License expiration date is required"
                        : "",
                      expireDwellingContractorLicenseDate
                        ? "Your license expiration date was expired"
                        : "")
                    }
                    id="dwelling-contractor-license-expire-date"
                    // label="Dwelling Contractor License/Certification No. Exp Date"
                    name="licenseExpirationDate"
                    value={
                      contractorObject?.licenseExpirationDate?.split("T")[0]
                    }
                    disableUnderline
                    fullWidth
                    sx={textfieldDate}
                    label="License Expiration Date"
                    inputProps={{
                      max: "9999-12-31",
                    }}
                    type="date"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: manipulationPermission,
                    }}
                    onChange={handleContractorChange}
                  />
                </Grid>
                {/* dwelling contractor address */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="dwelling-contractor-address"
                    label="Dwelling Contractor Street Address"
                    name="address"
                    value={contractorObject?.address || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={!contractorObject?.address && saveContractorPressed}
                    helperText={
                      !contractorObject?.address && saveContractorPressed
                        ? "Address is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor city */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-city"
                    label="Dwelling Contractor City"
                    name="city"
                    value={contractorObject?.city || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={!contractorObject?.city && saveContractorPressed}
                    helperText={
                      !contractorObject?.city && saveContractorPressed
                        ? "City is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor zip code */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-zipd-code"
                    label="Dwelling Contractor Zip Code"
                    name="zip"
                    value={contractorObject?.zip || ""}
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={!contractorObject?.zip && saveContractorPressed}
                    helperText={
                      !contractorObject?.zip && saveContractorPressed
                        ? "Zip code is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* dweeling contractor qualifier name */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-name"
                    label="Dwelling Contractor Qualifier Name"
                    value={
                      contractorObject?.dwellingContractorQualifierName || ""
                    }
                    name="dwellingContractorQualifierName"
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierName &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierName &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier name is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor qualifier's license number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-license-number"
                    label="Dwelling Contractor Qualifier License No."
                    name="dwellingContractorQualifierLicenseNumber"
                    value={
                      contractorObject?.dwellingContractorQualifierLicenseNumber ||
                      ""
                    }
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierLicenseNumber &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierLicenseNumber &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier license number is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor qualifier's license number expire date */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-license-number-expire-date"
                    name="dwellingContractorQualifierLicenseExpirationDate"
                    value={
                      contractorObject?.dwellingContractorQualifierLicenseExpirationDate?.split(
                        "T"
                      )[0]
                    }
                    onChange={handleContractorChange}
                    disableUnderline
                    fullWidth
                    sx={textfieldDate}
                    label="License Expiration Date"
                    inputProps={{
                      max: "9999-12-31",
                    }}
                    type="date"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: manipulationPermission,
                    }}
                    error={
                      (!contractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
                        saveContractorPressed,
                      expireDwellingContractorQualifierLicenseDate)
                    }
                    helperText={
                      (!contractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier license expiration date is required"
                        : "",
                      expireDwellingContractorQualifierLicenseDate
                        ? "Your qualifier license expiration date was expired."
                        : "")
                    }
                  />
                </Grid>
                {/* Dwelling Contractor Qualifier Address */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-Qualifier-address"
                    label="Dwelling Contractor Qualifier Address"
                    name="dwellingContractorQualifierAddress"
                    value={
                      contractorObject?.dwellingContractorQualifierAddress || ""
                    }
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierAddress &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierAddress &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier address is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor qualifier city */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-city"
                    label="Dwelling Contractor Qualifier City"
                    name="dwellingContractorQualifierCity"
                    value={
                      contractorObject?.dwellingContractorQualifierCity || ""
                    }
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierCity &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierCity &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier city is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor qualifier zipCode */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-zip-code"
                    label="Dwelling Contractor Qualifier Zip Code"
                    name="dwellingContractorQualifierZip"
                    value={
                      contractorObject?.dwellingContractorQualifierZip || ""
                    }
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierZip &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierZip &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier zip code is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor qualifier email */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-email"
                    label="Dwelling Contractor Qualifier Email"
                    name="dwellingContractorQualifierEmail"
                    value={
                      contractorObject?.dwellingContractorQualifierEmail || ""
                    }
                    type="email"
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !!dwellQualifierEmailError ||
                      (!contractorObject?.dwellingContractorQualifierEmail &&
                        saveContractorPressed)
                    }
                    helperText={
                      dwellQualifierEmailError ||
                      (!contractorObject?.dwellingContractorQualifierEmail &&
                        saveContractorPressed)
                        ? "Please enter a valid email address"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                {/* dwelling contractor qualifier phone number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="dwelling-contractor-qualifier-phone-number"
                    label="Dwelling Contractor Qualifier Phone No."
                    name="dwellingContractorQualifierPhoneNumber"
                    value={
                      contractorObject?.dwellingContractorQualifierPhoneNumber ||
                      ""
                    }
                    sx={textfield}
                    onChange={handleContractorChange}
                    error={
                      !contractorObject?.dwellingContractorQualifierPhoneNumber &&
                      saveContractorPressed
                    }
                    helperText={
                      !contractorObject?.dwellingContractorQualifierPhoneNumber &&
                      saveContractorPressed
                        ? "Dwelling contractor qualifier phone number is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  align="right"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  {!contractorObject?.temporary && (
                    <Typography
                      sx={{
                        fontFamily: "Poppins-semibold",
                        fontSize: "16px",
                      }}
                    >
                      Already exist in DB
                    </Typography>
                  )}

                  <Button
                    sx={{
                      ...saveButton,
                      width: "230px",
                      backgroundColor: "#d52027",
                      "&:hover": {
                        backgroundColor: "red",
                        transition: ".7s ease-in",
                        boxShadow: 2,
                      },
                      "&:disabled": {
                        color: "white",
                      },
                    }}
                    disabled={manipulationPermission}
                    onClick={() => {
                      handleChangeForAddNewContractor("dwellingContractor");
                    }}
                  >
                    Change Contractor
                  </Button>
                  <Button
                    disabled={manipulationPermission}
                    sx={{
                      ...saveButton,
                      width:
                        contractorObject?.temporary?.toString() === "true"
                          ? "230px"
                          : "230px",
                      backgroundColor: contractorObject?.temporary
                        ? "#D52027"
                        : "#32363F",
                      "&:hover": {
                        backgroundColor: contractorObject?.temporary
                          ? "red"
                          : "#000000",
                      },
                      "&:disabled": {
                        color: "white",
                      },
                    }}
                    onClick={() => {
                      handleDwellContractorSubmit();
                    }}
                  >
                    {contractorObject?.temporary
                      ? "Add contractor to Database"
                      : "Update"}
                    {contractorLoader ? <CircularProgressLoader /> : ""}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              <Box component="div" sx={{ marginTop: "20px" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                >
                  Construction
                </Typography>

                <FormGroup sx={{ display: "flex", flexDirection: "unset" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={manipulationPermission}
                        name="dwellingContractorByOwner"
                        checked={
                          applicationFormObject?.dwellingContractorByOwner
                            ? applicationFormObject?.dwellingContractorByOwner
                            : false
                        }
                        sx={{
                          fontSize: 60,
                          "&.Mui-disabled": { color: "#D52027 " },
                        }}
                        onChange={handleChangeCheckBox}
                      />
                    }
                    label="By Owner"
                  />
                </FormGroup>

                <Typography
                  variant="body1"
                  sx={{
                    color: "#414042",
                    fontWeight: 300,
                    fontSize: "15px",
                    marginTop: "5px",
                  }}
                >
                  Search Dwelling Contractor if the contractor is not listed
                  click the add button to enter contractors information
                </Typography>
                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <TextField
                      id="search-dweeling-contractor"
                      placeholder="Enter name, company name, license number or phone number..."
                      name="searchDwellContractor"
                      value={
                        dwellingContractorSearchString
                          ? dwellingContractorSearchString
                          : ""
                      }
                      sx={textfield}
                      onChange={(event) => {
                        handleContractorSearchString(
                          event.target.value,
                          "dwellingContractor"
                        );
                      }}
                      InputProps={{
                        readOnly: !localStorage
                          .getItem("permissions")
                          .includes("manipulate application details"),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      disabled={
                        !dwellingContractorSearchString ||
                        manipulationPermission
                      }
                      sx={applicationDetailFormSearchButton}
                      onClick={() =>
                        handleFilterContractorType("dwellingContractor")
                      }
                    >
                      <AddBoxOutlined /> Search
                    </Button>
                  </Grid>
                </Grid>
                {showDwellContractor && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#414042",
                        fontWeight: 300,
                        fontSize: "15px",
                        marginTop: "5px",
                      }}
                    >
                      Click desired contractor from search results
                    </Typography>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={tableHeadCell}>Name</TableCell>
                            <TableCell sx={tableHeadCell}>
                              Company Name
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              License Number
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              Phone Number
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dwellContractors
                            ?.slice(
                              dwellContractorPage *
                                rowsPerPageForDwellContractorPage,
                              dwellContractorPage *
                                rowsPerPageForDwellContractorPage +
                                rowsPerPageForDwellContractorPage
                            )
                            ?.map((contractor, index) => (
                              <TableRow
                                selected={contractorId === contractor.id}
                                key={index}
                                sx={[
                                  tableDataRow,
                                  {
                                    backgroundColor:
                                      contractor.id === contractorId
                                        ? "crimson"
                                        : "",
                                    color:
                                      contractor.id === contractorId
                                        ? "white"
                                        : "",
                                  },
                                ]}
                                onClick={() =>
                                  handleContractorTableRowClick(contractor)
                                }
                              >
                                <TableCell sx={tableDataCell}>
                                  {contractor?.name}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {contractor?.companyName}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {contractor?.licenseNumber}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {contractor?.phoneNumber}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <CustomPagination
                      page={dwellContractorPage}
                      setPage={setDwellContractorPage}
                      rowsPerPage={rowsPerPageForDwellContractorPage}
                      handleChangePage={handleDwellContractorChangePage}
                      filteredList={dwellContractors}
                      setRowsPerPage={setRowsPerPageForDwellContractors}
                    />
                    <Button
                      sx={applicationFormCreateButton}
                      onClick={() => {
                        setShowDwellContractor(false);
                        setShowDwellContractorForm(true);
                        setDwellingContractorToBeUpdated(false);
                      }}
                    >
                      <AddBoxOutlined /> Add Dwelling Contractor
                    </Button>
                  </>
                )}
              </Box>
              {showDwellContractorForm && (
                <Box component="div" sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#414042",
                      fontWeight: 600,
                      fontSize: "15px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Dwelling Contractor Information
                  </Typography>
                  <Grid container spacing={2} sx={{ my: "5px" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !createContractorObject?.name && saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.name && saveContractorPressed
                            ? "Name is required"
                            : ""
                        }
                        id="dwelling-contractor-name"
                        label="Dwelling Contractor name"
                        value={
                          createContractorObject?.name
                            ? createContractorObject?.name
                            : ""
                        }
                        name="name"
                        sx={textfield}
                        onChange={handleContractorChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !createContractorObject?.companyName &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.companyName &&
                          saveContractorPressed
                            ? "Company name is required"
                            : ""
                        }
                        id="dwelling-contractor-company-name"
                        label="Dwelling Contractor Company"
                        name="companyName"
                        value={
                          createContractorObject?.companyName
                            ? createContractorObject?.companyName
                            : ""
                        }
                        sx={textfield}
                        onChange={handleContractorChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="dwelling-contractor-email"
                        label="Dwelling Contractor Email"
                        name="email"
                        value={
                          createContractorObject?.email
                            ? createContractorObject?.email
                            : ""
                        }
                        type="email"
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          dwellEmailError ||
                          (!createContractorObject?.email &&
                            saveContractorPressed)
                        }
                        helperText={
                          dwellEmailError ||
                          (!createContractorObject?.email &&
                            saveContractorPressed)
                            ? "Please enter a valid email address"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !createContractorObject?.phoneNumber &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.phoneNumber &&
                          saveContractorPressed
                            ? "Phone no is required"
                            : ""
                        }
                        id="dwelling-contractor-phone-number"
                        label="Dwelling Contractor Phone No."
                        name="phoneNumber"
                        value={
                          createContractorObject?.phoneNumber
                            ? createContractorObject?.phoneNumber
                            : ""
                        }
                        sx={textfield}
                        onChange={handleContractorChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !createContractorObject?.licenseNumber &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.licenseNumber &&
                          saveContractorPressed
                            ? "License no is required"
                            : ""
                        }
                        id="dwelling-contractor-license-number"
                        label="Dwelling Contractor License No."
                        name="licenseNumber"
                        value={
                          createContractorObject?.licenseNumber
                            ? createContractorObject?.licenseNumber
                            : ""
                        }
                        sx={textfield}
                        onChange={handleContractorChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !createContractorObject?.licenseExpirationDate &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.licenseExpirationDate &&
                          saveContractorPressed
                            ? "License No. Expiration date is Required"
                            : ""
                        }
                        id="dwelling-contractor-license-expire-date"
                        name="licenseExpirationDate"
                        value={
                          createContractorObject?.licenseExpirationDate
                            ? createContractorObject?.licenseExpirationDate
                            : ""
                        }
                        onChange={handleContractorChange}
                        disableUnderline
                        fullWidth
                        sx={textfieldDate}
                        label="License Expiration Date"
                        inputProps={{
                          max: "9999-12-31",
                        }}
                        type="date"
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        // error
                        id="dwelling-contractor-address"
                        label="Dwelling Contractor Street Address"
                        name="address"
                        value={
                          createContractorObject?.address
                            ? createContractorObject?.address
                            : ""
                        }
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          !createContractorObject?.address &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.address &&
                          saveContractorPressed
                            ? "Address is required"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        // error
                        id="dwelling-contractor-city"
                        label="Dwelling Contractor City"
                        name="city"
                        value={
                          createContractorObject?.city
                            ? createContractorObject?.city
                            : ""
                        }
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          !createContractorObject?.city && saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.city && saveContractorPressed
                            ? "City is required"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        // error
                        id="dwelling-contractor-zipd-code"
                        label="Dwelling Contractor Zip Code"
                        name="zip"
                        value={
                          createContractorObject?.zip
                            ? createContractorObject?.zip
                            : ""
                        }
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          !createContractorObject?.zip && saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.zip && saveContractorPressed
                            ? "Zip is required"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        // error
                        id="dwelling-contractor-qualifier-name"
                        label="Dwelling Contractor Qualifier Name"
                        value={
                          createContractorObject?.dwellingContractorQualifierName
                            ? createContractorObject?.dwellingContractorQualifierName
                            : ""
                        }
                        name="dwellingContractorQualifierName"
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          !createContractorObject?.dwellingContractorQualifierName &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.dwellingContractorQualifierName &&
                          saveContractorPressed
                            ? "Dwelling contractor qualifier name is required"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        // error
                        id="dwelling-contractor-qualifier-name"
                        label="Dwelling Contractor Qualifier Address"
                        value={
                          createContractorObject?.dwellingContractorQualifierAddress
                            ? createContractorObject?.dwellingContractorQualifierAddress
                            : ""
                        }
                        name="dwellingContractorQualifierAddress"
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          !createContractorObject?.dwellingContractorQualifierAddress &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.dwellingContractorQualifierAddress &&
                          saveContractorPressed
                            ? "Dwelling contractor qualifier name is required"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        // error
                        id="dwelling-contractor-qualifier-license-number"
                        label="Dwelling Contractor Qualifier License No."
                        name="dwellingContractorQualifierLicenseNumber"
                        value={
                          createContractorObject?.dwellingContractorQualifierLicenseNumber
                            ? createContractorObject?.dwellingContractorQualifierLicenseNumber
                            : ""
                        }
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          !createContractorObject?.dwellingContractorQualifierLicenseNumber &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.dwellingContractorQualifierLicenseNumber &&
                          saveContractorPressed
                            ? "Dwelling contractor qualifier license number is required"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="dwelling-contractor-qualifier-license-number-expire-date"
                        name="dwellingContractorQualifierLicenseExpirationDate"
                        value={
                          createContractorObject?.dwellingContractorQualifierLicenseExpirationDate
                            ? createContractorObject?.dwellingContractorQualifierLicenseExpirationDate
                            : ""
                        }
                        onChange={handleContractorChange}
                        disableUnderline
                        fullWidth
                        sx={textfieldDate}
                        label="License Expiration Date"
                        inputProps={{
                          max: "9999-12-31",
                        }}
                        type="date"
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        error={
                          !createContractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.dwellingContractorQualifierLicenseExpirationDate &&
                          saveContractorPressed
                            ? "Dwelling contractor qualifier license expiration date is required"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="dwelling-contractor-qualifier-city"
                        label="Dwelling Contractor Qualifier City"
                        name="dwellingContractorQualifierCity"
                        value={
                          createContractorObject?.dwellingContractorQualifierCity
                            ? createContractorObject?.dwellingContractorQualifierCity
                            : ""
                        }
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          !createContractorObject?.dwellingContractorQualifierCity &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.dwellingContractorQualifierCity &&
                          saveContractorPressed
                            ? "Dwelling contractor qualifier city is required"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="dwelling-contractor-qualifier-zip-code"
                        label="Dwelling Contractor Qualifier Zip Code"
                        name="dwellingContractorQualifierZip"
                        value={
                          createContractorObject?.dwellingContractorQualifierZip
                            ? createContractorObject?.dwellingContractorQualifierZip
                            : ""
                        }
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          !createContractorObject?.dwellingContractorQualifierZip &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.dwellingContractorQualifierZip &&
                          saveContractorPressed
                            ? "Dwelling contractor qualifier zip is required"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="dwelling-contractor-qualifier-email"
                        label="Dwelling Contractor Qualifier Email"
                        name="dwellingContractorQualifierEmail"
                        value={
                          createContractorObject?.dwellingContractorQualifierEmail
                            ? createContractorObject?.dwellingContractorQualifierEmail
                            : ""
                        }
                        type="email"
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          !!dwellQualifierEmailError ||
                          (!createContractorObject?.dwellingContractorQualifierEmail &&
                            saveContractorPressed)
                        }
                        helperText={
                          (dwellQualifierEmailError &&
                            dwellQualifierEmailError) ||
                          (!createContractorObject?.dwellingContractorQualifierEmail &&
                            saveContractorPressed)
                            ? "Please enter a valid email address"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="dwelling-contractor-qualifier-phone-number"
                        label="Dweeling Contractor Qualifier Phone No."
                        name="dwellingContractorQualifierPhoneNumber"
                        value={
                          createContractorObject?.dwellingContractorQualifierPhoneNumber
                            ? createContractorObject?.dwellingContractorQualifierPhoneNumber
                            : ""
                        }
                        sx={textfield}
                        onChange={handleContractorChange}
                        error={
                          !createContractorObject?.dwellingContractorQualifierPhoneNumber &&
                          saveContractorPressed
                        }
                        helperText={
                          !createContractorObject?.dwellingContractorQualifierPhoneNumber &&
                          saveContractorPressed
                            ? "Dwelling contractor qualifier phone number is required"
                            : ""
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        sx={{
                          ...saveContractorButton,
                          backgroundColor: contractorLoader
                            ? "grey"
                            : "#d52027",
                        }}
                        onClick={() => {
                          handleCreateDwellContractorSubmit();
                        }}
                      >
                        Save Contractor
                        {contractorLoader ? <CircularProgressLoader /> : ""}
                      </Button>
                      <Button
                        sx={cancelContractorButton}
                        onClick={() => {
                          setCreateContractorObject({});
                          setShowDwellContractorForm(false);
                          setShowDwellContractor(true);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          ))}

        {/* Electrical information box */}
        {permitIds?.includes(3) &&
          (selectedElectricalContractorDataShow ? (
            <ApplicationFormContractorDetail
              type="Electrical"
              data={selectedElectricalContractorData}
              handleClose={handleShowSelectedElectricalFormClose}
            />
          ) : electricalId ? (
            <>
              <Box component="div" sx={{ marginTop: "30px" }}>
                <Typography sx={applicantTypos}>
                  Electrical Contractor Information
                </Typography>
                <Grid container spacing={2} sx={{ my: "5px" }}>
                  {/* Electrical name */}
                  {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !electricalObject?.name && saveElectricalContractorPressed
                    }
                    helperText={
                      !electricalObject?.name && saveElectricalContractorPressed
                        ? "Name is required"
                        : ""
                    }
                    id="electrical-contractor-name"
                    label="Electrical Contractor Name"
                    value={electricalObject?.name || ""}
                    name="name"
                    sx={textfield}
                    onChange={handleElectricalChange}
                  />
                </Grid> */}

                  {/* Electrical company name */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-contract-company"
                      label="Electrical Contractor Company"
                      value={electricalObject?.companyName || ""}
                      name="companyName"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.companyName && saveContractorButton
                      }
                      helperText={
                        !electricalObject?.companyName && saveContractorButton
                          ? "Company name is required"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* Electrical company email */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-company-email"
                      label="Electrical Contractor Email"
                      type="email"
                      value={electricalObject?.email || ""}
                      name="email"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !!electricalEmailError ||
                        (!electricalObject?.email &&
                          saveElectricalContractorPressed)
                      }
                      helperText={
                        electricalEmailError ||
                        (!electricalObject?.email &&
                          saveElectricalContractorPressed)
                          ? "Please enter a valid email address"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* electrical phone number */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !electricalObject?.phoneNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.phoneNumber &&
                        saveElectricalContractorPressed
                          ? "Phone number is required"
                          : ""
                      }
                      id="electrical-phone-number"
                      label="Electrical Contractor Phone No."
                      value={electricalObject?.phoneNumber || ""}
                      name="phoneNumber"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* master electrical License number */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !electricalObject?.licenseNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.licenseNumber &&
                        saveElectricalContractorPressed
                          ? "License number is required"
                          : ""
                      }
                      id="electrician-license-number"
                      label="Electrical Contractor License No."
                      value={electricalObject?.licenseNumber || ""}
                      name="licenseNumber"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* electrician license expire date */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        (!electricalObject?.licenseExpirationDate &&
                          saveElectricalContractorPressed,
                        electricianLicenseExpirationDate)
                      }
                      helperText={
                        (!electricalObject?.licenseExpirationDate &&
                        saveElectricalContractorPressed
                          ? "License expiration date is required"
                          : "",
                        electricianLicenseExpirationDate
                          ? "Your license date is expired"
                          : "")
                      }
                      id="electrician-license-expire-date"
                      value={
                        electricalObject?.licenseExpirationDate?.split("T")[0]
                      }
                      name="licenseExpirationDate"
                      onChange={handleElectricalChange}
                      disableUnderline
                      fullWidth
                      sx={textfieldDate}
                      label="License expiration date"
                      inputProps={{
                        max: "9999-12-31",
                      }}
                      type="date"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: manipulationPermission,
                      }}
                    />
                    {/* {expireDate ? "Your license date is expired" : ""} */}
                  </Grid>

                  {/* electrical street address */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-street-address"
                      label="Electrical Contractor Street Address"
                      value={electricalObject?.address || ""}
                      name="address"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.address &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.address &&
                        saveElectricalContractorPressed
                          ? "Street address is required"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* electrical city field */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-city"
                      label="Electrical Contractor City"
                      value={electricalObject?.city || ""}
                      name="city"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.city &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.city &&
                        saveElectricalContractorPressed
                          ? "City is required"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* electrical zip code */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="electrical-zip-code"
                      label="Electrical Contractor Zip Code"
                      value={electricalObject?.zip || ""}
                      name="zip"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.zip &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.zip &&
                        saveElectricalContractorPressed
                          ? "Zip is required"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* Master Electrician Contractor info box */}
              <Box component="div" sx={{ marginTop: "20px" }}>
                <Typography sx={applicantTypos}>
                  Master Electrician Contractor Information
                </Typography>
                <Grid container spacing={2} sx={{ my: "5px" }}>
                  {/* master electrician name */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="master-electrician-name"
                      label="Master Electrician Name"
                      value={electricalObject?.masterElectricianName || ""}
                      name="masterElectricianName"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.masterElectricianName &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianName &&
                        saveElectricalContractorPressed
                          ? "Master electrician Name is required"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* master electrician email */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="master-electrical-email"
                      label="Master Electrician Email"
                      value={electricalObject?.masterElectricianEmail || ""}
                      name="masterElectricianEmail"
                      type="email"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !!electricalMasterEmailError ||
                        (!electricalObject?.masterElectricianEmail &&
                          saveElectricalContractorPressed)
                      }
                      helperText={
                        electricalMasterEmailError ||
                        (!electricalObject?.masterElectricianEmail &&
                          saveElectricalContractorPressed)
                          ? "Please enter a valid email address"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* master electrician phonenumber */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="master-electrician-phoneNumber"
                      label="Master Electrician Phone Number"
                      value={
                        electricalObject?.masterElectricianPhoneNumber || ""
                      }
                      name="masterElectricianPhoneNumber"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.masterElectricianPhoneNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianPhoneNumber &&
                        saveElectricalContractorPressed
                          ? "Master electrician phone is required"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* master electrician certificate no */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !electricalObject?.masterElectricianLicenseNumber &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianLicenseNumber &&
                        saveElectricalContractorPressed
                          ? "Master electrican license number is required"
                          : ""
                      }
                      id="master-electrician-certificate"
                      label="Master Electrician License No."
                      value={
                        electricalObject?.masterElectricianLicenseNumber || ""
                      }
                      name="masterElectricianLicenseNumber"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* master electrician license expiration date */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        (!electricalObject?.masterElectricianLicenseExpirationDate &&
                          saveElectricalContractorPressed,
                        masterElectricianLicenseExpirationDate)
                      }
                      helperText={
                        (!electricalObject?.masterElectricianLicenseExpirationDate &&
                        saveElectricalContractorPressed
                          ? "Master license expiration date is required"
                          : "",
                        masterElectricianLicenseExpirationDate
                          ? "Your master electrician license expiration date was expired"
                          : "")
                      }
                      id="master-electrical-license-expire-date"
                      // label="Master Electrician License Expire Date"
                      value={
                        electricalObject?.masterElectricianLicenseExpirationDate?.split(
                          "T"
                        )[0]
                      }
                      name="masterElectricianLicenseExpirationDate"
                      onChange={handleElectricalChange}
                      disableUnderline
                      fullWidth
                      sx={textfieldDate}
                      label="License Expiration Date"
                      inputProps={{
                        max: "9999-12-31",
                      }}
                      type="date"
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* master electrical address */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="master-electrical-address"
                      label="Master Electrician Street Address"
                      value={electricalObject?.masterElectricianAddress || ""}
                      name="masterElectricianAddress"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.masterElectricianAddress &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianAddress &&
                        saveElectricalContractorPressed
                          ? "Master electrician street address is required"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* master Electrical city */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="master-electrical-city"
                      label="Master Electrician City"
                      value={electricalObject?.masterElectricianCity || ""}
                      name="masterElectricianCity"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.masterElectricianCity &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianCity &&
                        saveElectricalContractorPressed
                          ? "Master electrician city is required"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* master electrical zipcode */}
                  <Grid item xs={12} sm={12} md={12} lg={12} align="right">
                    <TextField
                      id="master-electrical-zipcode"
                      label="Master Electrician Zip Code"
                      value={electricalObject?.masterElectricianZip || ""}
                      name="masterElectricianZip"
                      sx={textfield}
                      onChange={handleElectricalChange}
                      error={
                        !electricalObject?.masterElectricianZip &&
                        saveElectricalContractorPressed
                      }
                      helperText={
                        !electricalObject?.masterElectricianZip &&
                        saveElectricalContractorPressed
                          ? "Master electrician zip code is required"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    align="right"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "flex-end",
                    }}
                  >
                    {!electricalObject?.temporary && (
                      <Typography
                        sx={{
                          fontFamily: "Poppins-semibold",
                          fontSize: "16px",
                        }}
                      >
                        Already exist in DB
                      </Typography>
                    )}

                    <Button
                      disabled={manipulationPermission}
                      sx={{
                        ...saveButton,
                        width: "230px",
                        backgroundColor: "#D52027",
                        "&:hover": {
                          backgroundColor: "red",
                          transition: ".7s ease-in",
                          boxShadow: 2,
                        },
                        "&:disabled": { color: "white" },
                      }}
                      onClick={() => {
                        handleChangeForAddNewContractor("electricalContractor");
                      }}
                    >
                      Change Contractor
                    </Button>
                    <Button
                      sx={{
                        ...saveButton,
                        width:
                          electricalObject?.temporary?.toString() === "true"
                            ? "230px"
                            : "230px",
                        backgroundColor: electricalObject?.temporary
                          ? "#D52027"
                          : "#32363F",
                        "&:hover": {
                          backgroundColor: electricalObject?.temporary
                            ? "red"
                            : "#000000",
                          transition: ".7s ease-in",
                          boxShadow: 2,
                        },
                        "&:disabled": {
                          color: "white",
                        },
                      }}
                      disabled={manipulationPermission}
                      onClick={() => {
                        handleElectricContractorSubmit();
                      }}
                    >
                      {electricalObject?.temporary
                        ? "Add contractor to Database"
                        : "Update"}
                      {electricalContractorLoader ? (
                        <CircularProgressLoader />
                      ) : (
                        ""
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <>
              {/* Electrical information box */}
              <Box component="div" sx={{ marginTop: "30px" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                >
                  Electrical
                </Typography>

                {/* checkbox electrical contractor  */}

                <FormGroup sx={{ display: "flex", flexDirection: "unset" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="electricalContractorByOwner"
                        checked={
                          applicationFormObject?.electricalContractorByOwner
                            ? applicationFormObject?.electricalContractorByOwner
                            : false
                        }
                        sx={{
                          fontSize: 60,
                          "&.Mui-disabled": { color: "#D52027 " },
                        }}
                        onChange={handleChangeCheckBox}
                      />
                    }
                    disabled={manipulationPermission}
                    label="By Owner"
                  />
                </FormGroup>

                <Typography
                  variant="body1"
                  sx={{
                    color: "#414042",
                    fontWeight: 300,
                    fontSize: "15px",
                    marginTop: "5px",
                  }}
                >
                  Search Electrical Contractor if the contractor is not listed
                  click the add button to enter contractors information
                </Typography>
                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <TextField
                      id="outlined-error-helper-text"
                      placeholder="Enter in company name, phone number or license number"
                      name="searchElectricalContractor"
                      value={
                        electricalContractorSearchString
                          ? electricalContractorSearchString
                          : ""
                      }
                      sx={textfield}
                      onChange={(event) => {
                        handleContractorSearchString(
                          event.target.value,
                          "electricContractor"
                        );
                      }}
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      sx={applicationDetailFormSearchButton}
                      disabled={
                        !electricalContractorSearchString ||
                        manipulationPermission
                      }
                      onClick={() =>
                        handleFilterContractorType("electricContractor")
                      }
                    >
                      <AddBoxOutlined /> Search
                    </Button>
                  </Grid>
                </Grid>

                {showElectricalTable && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#414042",
                        fontWeight: 300,
                        fontSize: "15px",
                        marginTop: "5px",
                      }}
                    >
                      Click desired Electrician from search results
                    </Typography>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={tableHeadCell}>
                              Company Name
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              Phone Number
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              License Number
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              Master Electrician License Number/Expiration Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {electricalContractors
                            ?.slice(
                              electricalContractorPage *
                                rowsPerPageForElectricalContractor,
                              electricalContractorPage *
                                rowsPerPageForElectricalContractor +
                                rowsPerPageForElectricalContractor
                            )
                            ?.map((electricalContractor, index) => (
                              <TableRow
                                key={index}
                                selected={
                                  electricalContractor.id === electricalId
                                }
                                sx={[
                                  tableDataRow,
                                  {
                                    backgroundColor:
                                      electricalContractor.id === electricalId
                                        ? "crimson"
                                        : "",
                                    color:
                                      electricalContractor.id === electricalId
                                        ? "white"
                                        : "",
                                  },
                                ]}
                                onClick={() =>
                                  handleElectricalTableRowClick(
                                    electricalContractor
                                  )
                                }
                              >
                                <TableCell sx={tableDataCell}>
                                  {electricalContractor?.companyName}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {electricalContractor?.phoneNumber}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {electricalContractor?.licenseNumber}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {electricalContractor?.masterElectricianLicenseNumber +
                                    " | " +
                                    onlyDate(
                                      electricalContractor?.masterElectricianLicenseExpirationDate
                                    )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <CustomPagination
                      page={electricalContractorPage}
                      setPage={setElectricalContractorPage}
                      rowsPerPage={rowsPerPageForElectricalContractor}
                      handleChangePage={handleElectricalContractorChangePage}
                      filteredList={electricalContractors}
                      setRowsPerPage={setRowsPerPageForElectricalContractor}
                    />

                    <Button
                      sx={applicationFormCreateButton}
                      onClick={() => {
                        setShowElectricalTable(false),
                          setShowElectricalForm(!showElecrticalForm);
                      }}
                    >
                      <AddBoxOutlined /> Add Electrical Contractor
                    </Button>
                  </>
                )}

                {/* Electrical Contractor info box */}
                {showElecrticalForm && (
                  <>
                    <Box component="div" sx={{ marginTop: "20px" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#414042",
                          fontWeight: 600,
                          fontSize: "15px",
                        }}
                      >
                        Electrical Contractor Information
                      </Typography>
                      <Grid container spacing={2} sx={{ my: "5px" }}>
                        {/* Electrical name */}
                        {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          error={
                            !electricalObject?.name &&
                            saveElectricalContractorPressed
                          }
                          helperText={
                            !electricalObject?.name &&
                            saveElectricalContractorPressed
                              ? "Name is required"
                              : ""
                          }
                          id="electrical-contractor-name"
                          placeholder="Electrical Contractor Name"
                          value={electricalObject?.electricalName}
                          name="name"
                          sx={textfield}
                          onChange={handleElectricalChange}
                        />
                      </Grid> */}

                        {/* Electrical company name */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="electrical-contract-company"
                            label="Electrical Contractor Company"
                            value={electricalObject?.electricalCompanyName}
                            name="companyName"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !electricalObject?.companyName &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.companyName &&
                              saveElectricalContractorPressed
                                ? "Company name is required"
                                : ""
                            }
                          />
                        </Grid>

                        {/* Electrical company email */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="electrical-company-email"
                            label="Electrical Contractor Email"
                            type="email"
                            value={electricalObject?.electricalEmail}
                            name="email"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !!electricalEmailError ||
                              (!electricalObject?.email &&
                                saveElectricalContractorPressed)
                            }
                            helperText={
                              (electricalEmailError && electricalEmailError) ||
                              (!electricalObject?.email &&
                                saveElectricalContractorPressed)
                                ? "Please enter a valid email address"
                                : ""
                            }
                          />
                        </Grid>

                        {/* electrical phone number */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            error={
                              !electricalObject?.phoneNumber &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.phoneNumber &&
                              saveElectricalContractorPressed
                                ? "Phone number is required"
                                : ""
                            }
                            id="electrical-phone-number"
                            label="Electrical Contractor Phone No."
                            value={electricalObject?.electricalPhoneNumber}
                            name="phoneNumber"
                            sx={textfield}
                            onChange={handleElectricalChange}
                          />
                        </Grid>

                        {/* master electrical License number */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            error={
                              !electricalObject?.licenseNumber &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.licenseNumber &&
                              saveElectricalContractorPressed
                                ? "License no is required"
                                : ""
                            }
                            id="electrician-license-number"
                            label="Electrician Contractor License No."
                            value={electricalObject?.electricalLicenseNumber}
                            name="licenseNumber"
                            sx={textfield}
                            onChange={handleElectricalChange}
                          />
                        </Grid>

                        {/* master electrician license expire date */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            error={
                              !electricalObject?.licenseExpirationDate &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.licenseExpirationDate &&
                              saveElectricalContractorPressed
                                ? "License expiration date is required"
                                : ""
                            }
                            id="electrician-license-expire-date"
                            value={
                              electricalObject?.electricalLicenseExpirationDate
                            }
                            name="licenseExpirationDate"
                            onChange={handleElectricalChange}
                            disableUnderline
                            fullWidth
                            sx={textfieldDate}
                            label="Date issued"
                            inputProps={{
                              max: "9999-12-31",
                            }}
                            type="date"
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true }}
                          />
                        </Grid>

                        {/* electrical street address */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="electrical-street-address"
                            label="Electrical Contractor Street Address"
                            value={electricalObject?.electricalAddress}
                            name="address"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !electricalObject?.address &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.address &&
                              saveElectricalContractorPressed
                                ? "Address is required"
                                : ""
                            }
                          />
                        </Grid>

                        {/* electrical city field */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="electrical-city"
                            label="Electrical Contractor City"
                            value={electricalObject?.electricalCity}
                            name="city"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !electricalObject?.city &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.city &&
                              saveElectricalContractorPressed
                                ? "City is required"
                                : ""
                            }
                          />
                        </Grid>

                        {/* electrical zip code */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="electrical-zip-code"
                            label="Electrical Contractor Zip Code"
                            value={electricalObject?.electricalZip}
                            name="zip"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !electricalObject?.zip &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.zip &&
                              saveElectricalContractorPressed
                                ? "Zip is required"
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    {/* Master Electrian Contractor info box */}
                    <Box component="div" sx={{ marginTop: "20px" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#414042",
                          fontWeight: 600,
                          fontSize: "15px",
                        }}
                      >
                        Master Electrian Contractor Information
                      </Typography>
                      <Grid container spacing={2} sx={{ my: "5px" }}>
                        {/* maaster electrician name */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            id="master-electrician-name"
                            label="Master Electrician Contractor Name "
                            value={
                              electricalObject?.masterElectricianName
                                ? electricalObject?.masterElectricianName
                                : ""
                            }
                            name="masterElectricianName"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !electricalObject?.masterElectricianName &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.masterElectricianName &&
                              saveElectricalContractorPressed
                                ? "Master electrician name is required"
                                : ""
                            }
                          />
                        </Grid>

                        {/* master electrician email */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="master-electrical-email"
                            label="Master Electrician Contractor Email"
                            value={
                              electricalObject?.masterElectricianEmail
                                ? electricalObject?.masterElectricianEmail
                                : ""
                            }
                            name="masterElectricianEmail"
                            type="email"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !!electricalMasterEmailError ||
                              (!electricalObject?.masterElectricianEmail &&
                                saveElectricalContractorPressed)
                            }
                            helperText={
                              (electricalMasterEmailError &&
                                electricalMasterEmailError) ||
                              (!electricalObject?.masterElectricianEmail &&
                                saveElectricalContractorPressed)
                                ? "Please enter a valid email address"
                                : ""
                            }
                          />
                        </Grid>

                        {/* master electrician phonenumber */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="master-electrician-phoneNumber"
                            label="Master Electrician Contractor Phone Number"
                            value={
                              electricalObject?.masterElectricianPhoneNumber
                                ? electricalObject?.masterElectricianPhoneNumber
                                : ""
                            }
                            name="masterElectricianPhoneNumber"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !electricalObject?.masterElectricianEmail &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.masterElectricianEmail &&
                              saveElectricalContractorPressed
                                ? "master electrician email is required"
                                : ""
                            }
                          />
                        </Grid>

                        {/* master electrician certificate no */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            error={
                              !electricalObject?.masterElectricianLicenseNumber &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.masterElectricianLicenseNumber &&
                              saveElectricalContractorPressed
                                ? "Master electrician license no is required"
                                : ""
                            }
                            id="master-electrician-certificate"
                            label="Master Electrician Contractor License No."
                            value={
                              electricalObject?.masterElectricianLicenseNumber
                                ? electricalObject?.masterElectricianLicenseNumber
                                : ""
                            }
                            name="masterElectricianLicenseNumber"
                            sx={textfield}
                            onChange={handleElectricalChange}
                          />
                        </Grid>

                        {/* master electrician license expiration date */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            error={
                              !electricalObject?.masterElectricianLicenseExpirationDate &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.masterElectricianLicenseExpirationDate &&
                              saveElectricalContractorPressed
                                ? "Master electrician license expiration date is required"
                                : ""
                            }
                            id="master-electrical-license-expire-date"
                            value={
                              electricalObject?.masterElectricianLicenseExpirationDate
                            }
                            name="masterElectricianLicenseExpirationDate"
                            onChange={handleElectricalChange}
                            disableUnderline
                            fullWidth
                            sx={textfieldDate}
                            label="License Expiration Date"
                            inputProps={{
                              max: "9999-12-31",
                            }}
                            type="date"
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true }}
                          />
                        </Grid>

                        {/* master electrical address */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            id="master-electrical-address"
                            label="Master Electrician Contractor Street Address"
                            value={
                              electricalObject?.masterElectricianAddress
                                ? electricalObject?.masterElectricianAddress
                                : ""
                            }
                            name="masterElectricianAddress"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !electricalObject?.masterElectricianAddress &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.masterElectricianAddress &&
                              saveElectricalContractorPressed
                                ? "Master electrician address is required"
                                : ""
                            }
                          />
                        </Grid>

                        {/* master Electrical city */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="master-electrical-city"
                            label="Master Electrician Contractor City"
                            value={
                              electricalObject?.masterElectricianCity
                                ? electricalObject?.masterElectricianCity
                                : ""
                            }
                            name="masterElectricianCity"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !electricalObject?.masterElectricianCity &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.masterElectricianCity &&
                              saveElectricalContractorPressed
                                ? "Master electrician city is required"
                                : ""
                            }
                          />
                        </Grid>

                        {/* master electrical zipcode */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="master-electrical-zipcode"
                            label="Master Electrician Contractor Zip Code"
                            value={
                              electricalObject?.masterElectricianZip
                                ? electricalObject?.masterElectricianZip
                                : ""
                            }
                            name="masterElectricianZip"
                            sx={textfield}
                            onChange={handleElectricalChange}
                            error={
                              !electricalObject?.masterElectricianZip &&
                              saveElectricalContractorPressed
                            }
                            helperText={
                              !electricalObject?.masterElectricianZip &&
                              saveElectricalContractorPressed
                                ? "Master electrician zip is required"
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      sx={{ mt: 3, display: "flex", alignItems: "center" }}
                    >
                      <Button
                        sx={{
                          ...saveContractorButton,
                          backgroundColor: contractorLoader
                            ? "grey"
                            : "#d52027",
                        }}
                        onClick={() => {
                          handleCreateElectricContractorSubmit();
                        }}
                      >
                        Save Contractor
                        {contractorLoader ? <CircularProgressLoader /> : ""}
                      </Button>
                      <Button
                        sx={cancelContractorButton}
                        onClick={() => {
                          setElectricalObject({});
                          setShowElectricalForm(false);
                          setShowElectricalTable(true);
                          setSaveElectricalContractorPressed(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                )}
              </Box>
            </>
          ))}

        {/* ########################### Plumbing Contractor info box##################################### */}
        {permitIds?.includes(4) &&
          (selectedPlumberContractorDataShow ? (
            <ApplicationFormContractorDetail
              type="Plumbing"
              data={selectedPlumberContractorData}
              handleClose={handleShowSelectedPlumberFormClose}
            />
          ) : plumberId ? (
            <Box component="div" sx={{ marginTop: "20px" }}>
              <Typography sx={applicantTypos}>
                Plumber Contractor Information
              </Typography>
              <Grid container spacing={2} sx={{ my: "5px" }}>
                {/* Plumber name */}
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={!plumberObject?.name && savePlumberContractorPressed}
                  helperText={
                    !plumberObject?.name && savePlumberContractorPressed
                      ? "Name is required"
                      : ""
                  }
                  id="plumber-contractor-name"
                  label="Plumber Contractor Name"
                  value={plumberObject?.name || ""}
                  name="name"
                  sx={textfield}
                  onChange={handlePlumberChange}
                />
              </Grid> */}

                {/* Plumber company name */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !plumberObject?.companyName &&
                      savePlumberContractorPressed
                    }
                    helperText={
                      !plumberObject?.companyName &&
                      savePlumberContractorPressed
                        ? "Company name is required"
                        : ""
                    }
                    id="plumber-contract-company"
                    label="Plumber Contractor Company"
                    value={
                      plumberObject?.companyName
                        ? plumberObject?.companyName
                        : ""
                    }
                    name="companyName"
                    sx={textfield}
                    onChange={handlePlumberChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* Plumber company email */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="plumber-company-email"
                    label="Plumber Contractor Email"
                    type="email"
                    value={plumberObject?.email || ""}
                    name="email"
                    sx={textfield}
                    onChange={handlePlumberChange}
                    error={
                      !!plumberEmailError ||
                      (!plumberObject?.email && savePlumberContractorPressed)
                    }
                    helperText={
                      plumberEmailError ||
                      (!plumberObject?.email && savePlumberContractorPressed)
                        ? "Please enter a valid email address"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* Pluimber phone number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !plumberObject?.phoneNumber &&
                      savePlumberContractorPressed
                    }
                    helperText={
                      !plumberObject?.phoneNumber &&
                      savePlumberContractorPressed
                        ? "Phone number is required"
                        : ""
                    }
                    id="plumber-phone-number"
                    label="Plumber Contractor Phone No."
                    value={plumberObject?.phoneNumber || ""}
                    name="phoneNumber"
                    sx={textfield}
                    onChange={handlePlumberChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* plumber License number */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      !plumberObject?.licenseNumber &&
                      savePlumberContractorPressed
                    }
                    helperText={
                      !plumberObject?.licenseNumber &&
                      savePlumberContractorPressed
                        ? "License number is required"
                        : ""
                    }
                    id="plumber-license-number"
                    label="Plumber Contractor License No."
                    value={plumberObject?.licenseNumber || ""}
                    name="licenseNumber"
                    sx={textfield}
                    onChange={handlePlumberChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* plumber license expire date */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={
                      (!plumberObject?.licenseExpirationDate &&
                        savePlumberContractorPressed,
                      plumbingLicenseExpirationDate)
                    }
                    helperText={
                      (!plumberObject?.licenseExpirationDate &&
                      savePlumberContractorPressed
                        ? "License expiration date is required"
                        : "",
                      plumbingLicenseExpirationDate
                        ? "Your license date is expired"
                        : "")
                    }
                    id="plumber-license-expire-date"
                    // label="Plumber Contractor License Expiration Date"
                    value={plumberObject?.licenseExpirationDate?.split("T")[0]}
                    name="licenseExpirationDate"
                    onChange={handlePlumberChange}
                    disableUnderline
                    fullWidth
                    sx={textfieldDate}
                    label="License Expiration Date"
                    inputProps={{
                      max: "9999-12-31",
                    }}
                    type="date"
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* plumber street address */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="plumber-street-address"
                    label="Plumber Contractor Street Address"
                    value={plumberObject?.address || ""}
                    name="address"
                    sx={textfield}
                    onChange={handlePlumberChange}
                    error={
                      !plumberObject?.address && savePlumberContractorPressed
                    }
                    helperText={
                      !plumberObject?.address && savePlumberContractorPressed
                        ? "Street address is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* plumber city field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="plumber-city"
                    label="Plumber Contractor City"
                    value={plumberObject?.city || ""}
                    name="city"
                    sx={textfield}
                    onChange={handlePlumberChange}
                    error={!plumberObject?.city && savePlumberContractorPressed}
                    helperText={
                      !plumberObject?.city && savePlumberContractorPressed
                        ? "City is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* plumber zip code */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="plumber-zip-code"
                    label="Plumber Contractor Zip Code"
                    value={plumberObject?.zip || ""}
                    name="zip"
                    sx={textfield}
                    onChange={handlePlumberChange}
                    error={!plumberObject?.zip && savePlumberContractorPressed}
                    helperText={
                      !plumberObject?.zip && savePlumberContractorPressed
                        ? "Zip code is required"
                        : ""
                    }
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  align="right"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  {!plumberObject?.temporary && (
                    <Typography
                      sx={{ fontFamily: "Poppins-semibold", fontSize: "16px" }}
                    >
                      Already exist in DB
                    </Typography>
                  )}
                  <Button
                    sx={{
                      ...saveButton,
                      width: "230px",
                      backgroundColor: "#d52027",
                      "&:hover": {
                        backgroundColor: "red",
                        transition: ".7s ease-in",
                        boxShadow: 2,
                      },
                      "&:disabled": {
                        color: "white",
                      },
                    }}
                    disabled={manipulationPermission}
                    onClick={() => {
                      handleChangeForAddNewContractor("plumbingContractor");
                    }}
                  >
                    Change Contractor
                  </Button>
                  <Button
                    sx={{
                      ...saveButton,
                      width:
                        plumberObject?.temporary?.toString() === "true"
                          ? "230px"
                          : "230px",
                      backgroundColor: plumberObject?.temporary
                        ? "#D52027"
                        : "#32363F",
                      "&:hover": {
                        backgroundColor: plumberObject?.temporary
                          ? "red"
                          : "#000000",
                        transition: ".7s ease-in",
                        boxShadow: 2,
                      },
                      "&:disabled": {
                        color: "white",
                      },
                    }}
                    disabled={manipulationPermission}
                    onClick={() => {
                      handlePlumberContractorSubmit();
                    }}
                  >
                    {plumberObject?.temporary
                      ? "Add contractor to Database"
                      : "Update"}
                    {plumberContractorLoader ? <CircularProgressLoader /> : ""}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              {/* Plumbing information box */}
              <Box component="div" sx={{ marginTop: "30px" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                >
                  Plumbing
                </Typography>

                {/* plumbing checkbox by owner */}

                <FormGroup sx={{ display: "flex", flexDirection: "unset" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="plumbingContractorByOwner"
                        checked={
                          applicationFormObject?.plumbingContractorByOwner
                            ? applicationFormObject?.plumbingContractorByOwner
                            : false
                        }
                        sx={{
                          fontSize: 60,
                          "&.Mui-disabled": { color: "#D52027 " },
                        }}
                        disabled={manipulationPermission}
                        onChange={handleChangeCheckBox}
                      />
                    }
                    label="By Owner"
                  />
                </FormGroup>

                <Typography
                  variant="body1"
                  sx={{
                    color: "#414042",
                    fontWeight: 300,
                    fontSize: "15px",
                    marginTop: "5px",
                  }}
                >
                  Search Plumbing Contractor if the contractor is not listed
                  click the add button to enter contractors information
                </Typography>
                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <TextField
                      id="outlined-error-helper-text"
                      placeholder="Enter compamy name,license number or phone number..."
                      value={
                        plumbingContractorSearchString
                          ? plumbingContractorSearchString
                          : ""
                      }
                      name="searchPlumbingContractor"
                      sx={textfield}
                      onChange={(event) => {
                        handleContractorSearchString(
                          event.target.value,
                          "plumberContractor"
                        );
                      }}
                      InputProps={{ readOnly: manipulationPermission }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      sx={applicationDetailFormSearchButton}
                      disabled={
                        !plumbingContractorSearchString ||
                        manipulationPermission
                      }
                      onClick={() =>
                        handleFilterContractorType("plumberContractor")
                      }
                    >
                      <AddBoxOutlined /> Search
                    </Button>
                  </Grid>
                </Grid>

                {showPlumbingTable && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#414042",
                        fontWeight: 300,
                        fontSize: "15px",
                        marginTop: "5px",
                      }}
                    >
                      Click desired plumber from search results
                    </Typography>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={tableHeadCell}>
                              Company Name
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              Phone Number
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              License Number
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              Expiration Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {plumberContractors
                            ?.slice(
                              plumberContractorPage *
                                rowsPerPageForPlumberContractor,
                              plumberContractorPage *
                                rowsPerPageForPlumberContractor +
                                rowsPerPageForPlumberContractor
                            )
                            ?.map((plumberContractor, index) => (
                              <TableRow
                                selected={plumberContractor.id === plumberId}
                                key={index}
                                sx={[
                                  tableDataRow,
                                  {
                                    backgroundColor:
                                      plumberContractor.id === plumberId
                                        ? "crimson"
                                        : "",
                                    color:
                                      plumberContractor.id === plumberId
                                        ? "white"
                                        : "",
                                  },
                                ]}
                                onClick={() =>
                                  handlePlumberTableRowClick(plumberContractor)
                                }
                              >
                                <TableCell sx={tableDataCell}>
                                  {plumberContractor.companyName}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {plumberContractor.phoneNumber}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {plumberContractor.licenseNumber}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {plumberContractor.licenseNumber +
                                    " | " +
                                    onlyDate(
                                      plumberContractor.licenseExpirationDate
                                    )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <CustomPagination
                      page={plumberContractorPage}
                      setPage={setPlumberContractorPage}
                      rowsPerPage={rowsPerPageForPlumberContractor}
                      handleChangePage={handlePlumberContractorChangePage}
                      filteredList={plumberContractors}
                      setRowsPerPage={setRowsPerPageForPlumberContractor}
                    />
                    <Button
                      sx={applicationFormCreateButton}
                      onClick={() => {
                        setShowPlumbingTable(false),
                          setShowPlumbingForm(!showPlumbingForm);
                      }}
                    >
                      <AddBoxOutlined /> Add Plumbing Contractor
                    </Button>
                  </>
                )}
              </Box>

              {/* Plumbing Contractor info box */}
              {showPlumbingForm && (
                <Box component="div" sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                  >
                    Plumber Contractor Information
                  </Typography>
                  <Grid container spacing={2} sx={{ my: "5px" }}>
                    {/* Plumber name */}
                    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !plumberObject?.name && savePlumberContractorPressed
                      }
                      helperText={
                        !plumberObject?.name && savePlumberContractorPressed
                          ? "Name is required"
                          : ""
                      }
                      id="plumber-contractor-name"
                      label="Plumber Contractor Name"
                      value={plumberObject?.name}
                      name="name"
                      sx={textfield}
                      onChange={handlePlumberChange}
                    />
                  </Grid> */}

                    {/* Plumber company name */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !plumberObject?.companyName &&
                          savePlumberContractorPressed
                        }
                        helperText={
                          !plumberObject?.companyName &&
                          savePlumberContractorPressed
                            ? "Company name is required"
                            : ""
                        }
                        id="plumber-contract-company"
                        label="Plumber Contractor Company"
                        value={plumberObject?.companyName}
                        name="companyName"
                        sx={textfield}
                        onChange={handlePlumberChange}
                      />
                    </Grid>

                    {/* Plumber company email */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="plumber-company-email"
                        label="Plumber Contractor Email"
                        type="email"
                        value={plumberObject?.email}
                        name="email"
                        sx={textfield}
                        onChange={handlePlumberChange}
                        error={
                          !!plumberEmailError ||
                          (!plumberObject?.email &&
                            savePlumberContractorPressed)
                        }
                        helperText={
                          (plumberEmailError && plumberEmailError) ||
                          (!plumberObject?.email &&
                            savePlumberContractorPressed)
                            ? "Please enter a valid email address"
                            : ""
                        }
                      />
                    </Grid>

                    {/* Pluimber phone number */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !plumberObject?.phoneNumber &&
                          savePlumberContractorPressed
                        }
                        helperText={
                          !plumberObject?.phoneNumber &&
                          savePlumberContractorPressed
                            ? "Phone No Required"
                            : ""
                        }
                        id="plumber-phone-number"
                        label="Plumber Contractor Phone No."
                        value={plumberObject?.phoneNumber}
                        name="phoneNumber"
                        sx={textfield}
                        onChange={handlePlumberChange}
                      />
                    </Grid>

                    {/* plumber License number */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !plumberObject?.licenseNumber &&
                          savePlumberContractorPressed
                        }
                        helperText={
                          !plumberObject?.licenseNumber &&
                          savePlumberContractorPressed
                            ? "License no is required"
                            : ""
                        }
                        id="plumber-license-number"
                        label="Plumber Contractor License No."
                        value={plumberObject?.licenseNumber}
                        name="licenseNumber"
                        sx={textfield}
                        onChange={handlePlumberChange}
                      />
                    </Grid>

                    {/* plumber license expire date */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !plumberObject?.licenseExpirationDate &&
                          savePlumberContractorPressed
                        }
                        helperText={
                          !plumberObject?.licenseExpirationDate &&
                          savePlumberContractorPressed
                            ? "License expiration date is required"
                            : ""
                        }
                        id="plumber-license-expire-date"
                        value={plumberObject?.licenseExpirationDate}
                        name="licenseExpirationDate"
                        onChange={handlePlumberChange}
                        disableUnderline
                        fullWidth
                        sx={textfieldDate}
                        label="License Expiration Date"
                        inputProps={{
                          max: "9999-12-31",
                        }}
                        type="date"
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>

                    {/* plumber street address */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="plumber-street-address"
                        label="Plumber Contractor Street Address"
                        value={plumberObject?.address}
                        name="address"
                        sx={textfield}
                        onChange={handlePlumberChange}
                        error={
                          !plumberObject?.address &&
                          savePlumberContractorPressed
                        }
                        helperText={
                          !plumberObject?.address &&
                          savePlumberContractorPressed
                            ? "Address is required"
                            : ""
                        }
                      />
                    </Grid>

                    {/* plumber city field */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="plumber-city"
                        label="Plumber Contractor City"
                        value={plumberObject?.city}
                        name="city"
                        sx={textfield}
                        onChange={handlePlumberChange}
                        error={
                          !plumberObject?.city && savePlumberContractorPressed
                        }
                        helperText={
                          !plumberObject?.city && savePlumberContractorPressed
                            ? "City is required"
                            : ""
                        }
                      />
                    </Grid>

                    {/* plumber zip code */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        // error
                        id="plumber-zip-code"
                        label="Plumber Contractor Zip Code"
                        value={plumberObject?.zip}
                        name="zip"
                        sx={textfield}
                        onChange={handlePlumberChange}
                        error={
                          !plumberObject?.zip && savePlumberContractorPressed
                        }
                        helperText={
                          !plumberObject?.zip && savePlumberContractorPressed
                            ? "Zip is required"
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    sx={{ mt: 3, display: "flex", alignItems: "center" }}
                  >
                    <Button
                      sx={{
                        ...saveContractorButton,
                        backgroundColor: contractorLoader ? "grey" : "#d52027",
                      }}
                      onClick={() => {
                        handleCreatePlumberContractorSubmit();
                      }}
                    >
                      Save Contractor
                      {contractorLoader ? <CircularProgressLoader /> : ""}
                    </Button>
                    <Button
                      sx={cancelContractorButton}
                      onClick={() => {
                        setPlumberObject({});
                        setShowPlumbingForm(false);
                        setShowPlumbingTable(true);
                        setSavePlumberContractorPressed(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Box>
              )}
            </>
          ))}

        {/* Heating information box */}
        {permitIds?.includes(2) &&
          (selectedHvacContractorDataShow ? (
            <ApplicationFormContractorDetail
              type="HVAC"
              data={selectedHvacContractorData}
              handleClose={handleShowSelectedHvacFormClose}
            />
          ) : hvacId ? (
            <Box component="div" sx={{ marginTop: "20px" }}>
              <Typography sx={applicantTypos}>
                Heating Contractor Information
              </Typography>

              {/* HVAC Contractor info box */}
              <Box component="div" sx={{ marginTop: "20px" }}>
                <Grid container spacing={2} sx={{ my: "5px" }}>
                  {/* heating name */}
                  {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!heatObject?.name && saveHvacContractorPressed}
                    helperText={
                      !heatObject?.name && saveHvacContractorPressed
                        ? "Name is required"
                        : ""
                    }
                    id="heating-contractor-name"
                    label="HVAC Contractor Name"
                    value={heatObject?.name || ""}
                    name="name"
                    sx={textfield}
                    onChange={handleHeatObjectChange}
                  />
                </Grid> */}

                  {/* heating company name */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !heatObject?.companyName && saveHvacContractorPressed
                      }
                      helperText={
                        !heatObject?.companyName && saveHvacContractorPressed
                          ? "Company name is required"
                          : ""
                      }
                      id="heating-contract-company"
                      label="HVAC Contractor Company Name"
                      value={heatObject?.companyName || ""}
                      name="companyName"
                      sx={textfield}
                      onChange={handleHeatObjectChange}
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* heating company email */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="heating-company-email"
                      label="HVAC Contractor Email"
                      type="email"
                      value={heatObject?.email || ""}
                      name="email"
                      sx={textfield}
                      onChange={handleHeatObjectChange}
                      error={
                        !!hvacEmailError ||
                        (!heatObject?.email && saveHvacContractorPressed)
                      }
                      helperText={
                        hvacEmailError ||
                        (!heatObject?.email && saveHvacContractorPressed)
                          ? "Please enter a valid email address"
                          : ""
                      }
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* heat phone number */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !heatObject?.phoneNumber && saveHvacContractorPressed
                      }
                      helperText={
                        !heatObject?.phoneNumber && saveHvacContractorPressed
                          ? "Phone number is required"
                          : ""
                      }
                      id="heat-phone-number"
                      label="HVAC Contractor Phone No."
                      value={heatObject?.phoneNumber || ""}
                      name="phoneNumber"
                      sx={textfield}
                      onChange={handleHeatObjectChange}
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* heat License number */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        !heatObject?.licenseNumber && saveHvacContractorPressed
                      }
                      helperText={
                        !heatObject?.licenseNumber && saveHvacContractorPressed
                          ? "License number is required"
                          : ""
                      }
                      id="heat-license-number"
                      label="HVAC Contractor License No."
                      value={heatObject?.licenseNumber || ""}
                      name="licenseNumber"
                      sx={textfield}
                      onChange={handleHeatObjectChange}
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* heat license expire date */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={
                        (!heatObject?.licenseExpirationDate &&
                          saveHvacContractorPressed,
                        hvacLicenseExpirationDate)
                      }
                      variant="filled"
                      helperText={
                        (!heatObject?.licenseExpirationDate &&
                        saveHvacContractorPressed
                          ? "License expiration date is required"
                          : "",
                        hvacLicenseExpirationDate
                          ? "Your license date is expired"
                          : "")
                      }
                      id="heat-license-expire-date"
                      // label="HVAC Contractor License Expiration Date"
                      value={heatObject?.licenseExpirationDate?.split("T")[0]}
                      name="licenseExpirationDate"
                      onChange={handleHeatObjectChange}
                      disableUnderline
                      fullWidth
                      sx={textfieldDate}
                      label="License Expiration Date"
                      inputProps={{
                        max: "9999-12-31",
                      }}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* heat street address */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="heat-street-address"
                      label="HVAC Contractor Street Address"
                      value={heatObject?.address || ""}
                      name="address"
                      sx={textfield}
                      onChange={handleHeatObjectChange}
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* heat city field */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="heat-city"
                      label="HVAC Contractor City"
                      value={heatObject?.city || ""}
                      name="city"
                      sx={textfield}
                      onChange={handleHeatObjectChange}
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>

                  {/* heat zip code */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="heat-zip-code"
                      label="HVAC Contractor Zip Code"
                      value={heatObject?.zip || ""}
                      name="zip"
                      sx={textfield}
                      onChange={handleHeatObjectChange}
                      InputProps={{
                        readOnly: manipulationPermission,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    align="right"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "flex-end",
                    }}
                  >
                    {!heatObject?.temporary && (
                      <Typography
                        sx={{
                          fontFamily: "Poppins-semibold",
                          fontSize: "16px",
                        }}
                      >
                        Already exist in DB
                      </Typography>
                    )}

                    <Button
                      sx={{
                        ...saveButton,
                        width: "230px",
                        backgroundColor: "#D52027",
                        "&:hover": {
                          backgroundColor: "red",
                          transition: ".7s ease-in",
                          boxShadow: 2,
                        },
                        "&:disabled": { color: "white" },
                      }}
                      disabled={manipulationPermission}
                      onClick={() => {
                        handleChangeForAddNewContractor("hvacContractor");
                      }}
                    >
                      Change Contractor
                    </Button>
                    <Button
                      sx={{
                        ...saveButton,
                        width:
                          heatObject?.temporary?.toString() === "true"
                            ? "230px"
                            : "230px",
                        backgroundColor: heatObject?.temporary
                          ? "#D52027"
                          : "#32363F",
                        "&:hover": {
                          backgroundColor: heatObject?.temporary
                            ? "red"
                            : "#000000",
                          transition: ".7s ease-in",
                          boxShadow: 2,
                        },
                        "&:disabled": {
                          color: "white",
                        },
                      }}
                      disabled={manipulationPermission}
                      onClick={() => {
                        handleHVACContractorSubmit();
                      }}
                    >
                      {heatObject?.temporary
                        ? "Add contractor to Database"
                        : "Update"}
                      {hvacContractorLoader ? <CircularProgressLoader /> : ""}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : (
            <>
              {/* Heating information box */}
              <Box component="div" sx={{ marginTop: "20px" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                >
                  Heating
                </Typography>

                {/* plumbing checkbox by owner */}

                <FormGroup sx={{ display: "flex", flexDirection: "unset" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="hvacContractorByOwner"
                        checked={
                          applicationFormObject?.hvacContractorByOwner
                            ? applicationFormObject?.hvacContractorByOwner
                            : false
                        }
                        sx={{
                          fontSize: 60,
                          "&.Mui-disabled": { color: "#D52027 " },
                        }}
                        disabled={manipulationPermission}
                        onChange={handleChangeCheckBox}
                      />
                    }
                    label="By Owner"
                  />
                </FormGroup>

                <Typography
                  variant="body1"
                  sx={{
                    color: "#414042",
                    fontWeight: 300,
                    fontSize: "15px",
                    marginTop: "5px",
                  }}
                >
                  Search HVAC Contractor if the contractor is not listed click
                  the add button to enter contractors information
                </Typography>
                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <TextField
                      id="search-hvac-contractor"
                      placeholder="Enter company name, phone number or license number..."
                      value={
                        hvacContractorSearchString
                          ? hvacContractorSearchString
                          : ""
                      }
                      name="searchHVACContractor"
                      sx={textfield}
                      onChange={(event) => {
                        handleContractorSearchString(
                          event.target.value,
                          "HVACContractor"
                        );
                      }}
                      InputProps={{ readOnly: manipulationPermission }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      sx={{
                        ...applicationDetailFormSearchButton,
                        "&:hover": {
                          backgroundColor: "black",
                          boxShadow: 2,
                          transition: ".7s ease-in",
                        },
                      }}
                      disabled={
                        !hvacContractorSearchString || manipulationPermission
                      }
                      onClick={() =>
                        handleFilterContractorType("HVACContractor")
                      }
                    >
                      <AddBoxOutlined /> Search
                    </Button>
                  </Grid>
                </Grid>
                {showHVACTable && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#414042",
                        fontWeight: 300,
                        fontSize: "15px",
                        marginTop: "5px",
                      }}
                    >
                      Click desired HVAC from search results
                    </Typography>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={tableHeadCell}>
                              HVAC Contractor Company Name
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              Phone Number
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              License Number
                            </TableCell>
                            <TableCell sx={tableHeadCell}>
                              Expiration Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {hvacContractors
                            ?.slice(
                              hvacContractorPage * rowsPerPageForHvacContractor,
                              hvacContractorPage *
                                rowsPerPageForHvacContractor +
                                rowsPerPageForHvacContractor
                            )
                            ?.map((hvacContractor, index) => (
                              <TableRow
                                selected={hvacContractor.id === hvacId}
                                key={index}
                                sx={[
                                  tableDataRow,
                                  {
                                    backgroundColor:
                                      hvacContractor.id === hvacId
                                        ? "crimson"
                                        : "",
                                    color:
                                      hvacContractor.id === hvacId
                                        ? "white"
                                        : "",
                                  },
                                ]}
                                onClick={() =>
                                  handleHvacTableRowClick(hvacContractor)
                                }
                              >
                                <TableCell sx={tableDataCell}>
                                  {hvacContractor.companyName}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {hvacContractor.phoneNumber}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {hvacContractor.licenseNumber}
                                </TableCell>
                                <TableCell sx={tableDataCell}>
                                  {onlyDate(
                                    hvacContractor.licenseExpirationDate
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <CustomPagination
                      page={hvacContractorPage}
                      setPage={setHvacContractorPage}
                      rowsPerPage={rowsPerPageForHvacContractor}
                      handleChangePage={handleHvacContractorChangePage}
                      filteredList={hvacContractors}
                      setRowsPerPage={setRowsPerPageForHvacContractor}
                    />
                    <Button
                      sx={applicationFormCreateButton}
                      onClick={() => {
                        setShowHVACTable(false), setShowHVACForm(!showHVACForm);
                      }}
                    >
                      <AddBoxOutlined /> Add Heating Contractor
                    </Button>
                  </>
                )}
              </Box>

              {/* Plumbing Contractor info box */}
              {showHVACForm && (
                <Box component="div" sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#414042", fontWeight: 600, fontSize: "15px" }}
                  >
                    Heating Contractor Information
                  </Typography>
                  <Grid container spacing={2} sx={{ my: "5px" }}>
                    {/* heating name */}
                    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      error={!heatObject?.name && saveHvacContractorPressed}
                      helperText={
                        !heatObject?.name && saveHvacContractorPressed
                          ? "Name is required"
                          : ""
                      }
                      id="heating-contractor-name"
                      label="Heating Contractor Name"
                      value={heatObject?.name}
                      name="name"
                      sx={textfield}
                      onChange={handleHeatObjectChange}
                    />
                  </Grid> */}

                    {/* heating company name */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !heatObject?.companyName && saveHvacContractorPressed
                        }
                        helperText={
                          !heatObject?.companyName && saveHvacContractorPressed
                            ? "Heating company is required"
                            : ""
                        }
                        id="heating-contract-company"
                        label="Heating Contractor Company"
                        value={heatObject?.companyName}
                        name="companyName"
                        sx={textfield}
                        onChange={handleHeatObjectChange}
                      />
                    </Grid>

                    {/* heating company email */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="heating-company-email"
                        label="Heating Contractor Email"
                        type="email"
                        value={heatObject?.email}
                        name="email"
                        sx={textfield}
                        onChange={handleHeatObjectChange}
                        error={
                          !!hvacEmailError ||
                          (!heatObject?.email && saveHvacContractorPressed)
                        }
                        helperText={
                          (hvacEmailError && hvacEmailError) ||
                          (!heatObject?.email && saveHvacContractorPressed)
                            ? "Please enter a valid email address"
                            : ""
                        }
                      />
                    </Grid>

                    {/* heat phone number */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !heatObject?.phoneNumber && saveHvacContractorPressed
                        }
                        helperText={
                          !heatObject?.phoneNumber && saveHvacContractorPressed
                            ? "Phone no is required"
                            : ""
                        }
                        id="heat-phone-number"
                        label="Heating Contractor Phone No."
                        value={heatObject?.phoneNumber}
                        name="phoneNumber"
                        sx={textfield}
                        onChange={handleHeatObjectChange}
                      />
                    </Grid>

                    {/* heat License number */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !heatObject?.licenseNumber &&
                          saveHvacContractorPressed
                        }
                        helperText={
                          !heatObject?.licenseNumber &&
                          saveHvacContractorPressed
                            ? "License no is required"
                            : ""
                        }
                        id="heat-license-number"
                        label="Heating Contractor License No."
                        value={heatObject?.licenseNumber}
                        name="licenseNumber"
                        sx={textfield}
                        onChange={handleHeatObjectChange}
                      />
                    </Grid>

                    {/* heat license expire date */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        error={
                          !heatObject?.licenseExpirationDate &&
                          saveHvacContractorPressed
                        }
                        helperText={
                          !heatObject?.licenseExpirationDate &&
                          saveHvacContractorPressed
                            ? "License expiration date is required"
                            : ""
                        }
                        id="heat-license-expire-date"
                        value={heatObject?.licenseExpirationDate}
                        name="licenseExpirationDate"
                        onChange={handleHeatObjectChange}
                        disableUnderline
                        fullWidth
                        sx={textfieldDate}
                        label="License Expiration Date"
                        inputProps={{
                          max: "9999-12-31",
                        }}
                        type="date"
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>

                    {/* heat street address */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="heat-street-address"
                        label="Heating Contractor Street Address"
                        value={heatObject?.address}
                        name="address"
                        sx={textfield}
                        onChange={handleHeatObjectChange}
                      />
                    </Grid>

                    {/* heat city field */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="heat-city"
                        label="Heating Contractor City"
                        value={heatObject?.city}
                        name="city"
                        sx={textfield}
                        onChange={handleHeatObjectChange}
                      />
                    </Grid>

                    {/* heat zip code */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="heat-zip-code"
                        label="Heating Contractor Zip Code"
                        value={heatObject?.zip}
                        name="zip"
                        sx={textfield}
                        onChange={handleHeatObjectChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    sx={{ mt: 3, display: "flex", alignItems: "center" }}
                  >
                    <Button
                      sx={{
                        ...saveContractorButton,
                        backgroundColor: contractorLoader ? "grey" : "#d52027",
                      }}
                      onClick={() => {
                        handleCreateHVACContractorSubmit();
                      }}
                    >
                      Save Contractor
                      {contractorLoader ? <CircularProgressLoader /> : ""}
                    </Button>
                    <Button
                      sx={cancelContractorButton}
                      onClick={() => {
                        setHeatObject({});
                        setShowHVACForm(false);
                        setShowHVACTable(true);
                        setSaveHvacContractorPressed(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Box>
              )}
            </>
          ))}

        {/* Applicant inormation info box */}
        <Box component="div" sx={{ marginTop: "20px" }}>
          <Typography sx={applicantTypos}>Applicant Information</Typography>
          <Grid container spacing={2} sx={{ my: "5px" }}>
            {/* person submit application */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Autocomplete
                id="grouped-demo"
                readOnly={manipulationPermission}
                options={personSubmittingApplication}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Person Submitting Application"
                    sx={textfield}
                    error={!personSubmittingApplicationName && submitPressed}
                    helperText={
                      !personSubmittingApplicationName && submitPressed
                        ? "Person submit applicant name is required"
                        : ""
                    }
                  />
                )}
                value={
                  personSubmittingApplication.find(
                    (option) => option.id === personSubmittingApplicationName
                  ) || null
                }
                onChange={(event, value) => {
                  setPersonSubmittingApplicationName(value ? value.id : null);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
              />
            </Grid>

            {/* applicant name  */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                error={!applicationFormObject.applicantName && submitPressed}
                helperText={
                  !applicationFormObject.applicantName && submitPressed
                    ? "Applicant name is required"
                    : ""
                }
                id="applicant-name"
                label="Applicant Name"
                name="applicantName"
                value={applicationFormObject?.applicantName || ""}
                sx={textfield}
                onChange={handleChange}
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>

            {/* applicant email */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                error={
                  !!applicantEmailError ||
                  (!applicationFormObject?.applicantEmail && submitPressed)
                }
                helperText={
                  applicantEmailError ||
                  (!applicationFormObject?.applicantEmail && submitPressed)
                    ? "Please enter a valid email address"
                    : ""
                }
                id="applicant-email"
                label="Email of Applicant"
                name="applicantEmail"
                value={applicationFormObject?.applicantEmail || ""}
                sx={textfield}
                onChange={handleChange}
                type="email"
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>
            {/* applicant email */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                // error={
                //   !!applicantEmailError ||
                //   (!applicationFormObject?.applicantEmail && submitPressed)
                // }
                // helperText={
                //   applicantEmailError ||
                //   (!applicationFormObject?.applicantEmail && submitPressed)
                //     ? "Please enter a valid email address"
                //     : ""
                // }
                multiline
                rows={3}
                id="applicant-email"
                label="Note"
                name="note"
                value={applicationFormObject?.note || ""}
                sx={textfield}
                onChange={handleChange}
                InputProps={{
                  readOnly: manipulationPermission,
                }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Submit Button box */}
      </CardContent>
      <CardActions>
        <Grid container spacing={1} sx={{ my: 1, px: 1 }}>
          <Grid item xs={12} sm={12} md={2} lg={1.5}>
            {/* Delete button */}
            <Button
              disabled={manipulationPermission}
              sx={{
                ...applicationFormDeleteButton,
                backgroundColor: manipulationPermission ? "#FA8128" : "#FA8128",
              }}
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Button
              // disabled={disablePdfButton}
              sx={{
                ...applicationFormStateButton,
                backgroundColor:
                  application?.submittedToStateAt === null
                    ? "#32363F"
                    : "#15c39b",
                "&:disabled": {
                  color: "white",
                  // color: "#707070",
                },
                "&:hover": {
                  backgroundColor:
                    application?.submittedToStateAt === null
                      ? "black"
                      : "#11a180",
                },
              }}
              disabled={manipulationPermission}
              onClick={() => {
                handleSubmitDataToState();
              }}
            >
              {application?.submittedToStateAt === null
                ? "Submit to State"
                : `Submitted At ${formatDate(
                    application?.submittedToStateAt?.split("T")[0]
                  )}`}
              {submitToStateLoader ? <CircularProgressLoader /> : ""}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={2.5}>
            {/* email button */}
            <Button
              sx={{
                ...applicationFormDownloadButton,
                backgroundColor: "#32363F",
                "&:disabled": {
                  color: "white",
                },
              }}
              disabled={manipulationPermission}
              onClick={handleOpenEmailModal}
            >
              Email to Applicant
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={3}>
            {/* pdf download button */}
            {disablePdfButton && (
              <Button
                disabled={manipulationPermission}
                sx={{
                  ...applicationFormDownloadButton,
                  backgroundColor: "#32363F",
                  "&:disabled": {
                    color: "white",
                  },
                }}
                onClick={() => {
                  fireToasterContext.fireToasterHandler(
                    false,
                    "Please save the application first"
                  );
                }}
              >
                Download Application
              </Button>
            )}

            {!disablePdfButton && (
              <PDFDownloadLink
                document={
                  <ApplicationDetailFormPdf data={applicationPdfData} />
                }
                fileName={`Application-detail-${
                  selectedApplicationInfo?.id
                }-${Date.now()}`}
                style={{ textDecoration: "none" }}
              >
                {({ loading }) => (
                  <Button
                    sx={{
                      ...applicationFormDownloadButton,
                      backgroundColor: loading ? "grey" : "#32363F",
                    }}
                  >
                    {loading
                      ? "Loading Application..."
                      : "Download Application"}
                  </Button>
                )}
              </PDFDownloadLink>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            {/* Save button */}
            <Button
              disabled={loader || manipulationPermission}
              sx={{
                ...applicationFormSubmitButton,
                backgroundColor: loader
                  ? "grey"
                  : manipulationPermission
                  ? "#d52027"
                  : "#d52027",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
      {openEmailModal && (
        <EmailToApplicantModal
          openEmailModal={openEmailModal}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {showDeleteModal && (
        <DeleteApplicationModal
          selectedApplication={application}
          openModal={showDeleteModal}
          handleCloseModal={handleCloseDeleteDialog}
        />
      )}
    </Card>
  );
};

export default ApplicationDetailForm;
