export const dashboardTypographyStyles = {
  "&.MuiTypography-roomAlertsCell": {
    color: "#6D7B88",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-logsCellSecondary": {
    color: "#6D7B88",
    fontFamily: "Poppins",
    fontSize: "11px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-roomAlertsCellDates": {
    color: "background.bgLayoutB",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-dashboardHeadingBold": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    marginLeft: "10px",
    fontSize: "25px",
    lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-dashboardInnerHeading": {
    color: "#040F25",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-dashboardTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-dashboardTableLogsHeadingMedium": {
    color: "#6D7B88",
    fontFamily: "Poppins-Medium",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-noData": {
    color: "#6D7B88",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const tableContainerLayout = {
  "&.MuiTableContainer-root": {
    height: 400,
    backgroundColor: "background.dashboardRoomLogCardBg",
    maxHeight: 400,
    borderRadius: "10px",
    [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
    [`@media screen and (min-width: 1536px)`]: {
      height: 500,
      maxHeight: 500,
    },
    [`@media screen and (min-width: 2000px)`]: {
      height: 800,
      maxHeight: 800,
    },
  },
};

export const tableHeadColor = { backgroundColor: "background.bgLayoutA" };
export const tableRowStyle = {
  display: "flex",
  justifyContent: "center",
  border: 0,
};
export const tableZeroResponseDivStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
  fontFamily: "Poppins",
};
export const tableBodyBoxStyle = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};
export const tableLogBoxStyle = {
  display: "flex",
  flexDirection: "column",
};
export const tableStatusBoxStyle = { display: "flex", justifyContent: "right" };
export const tableStatusStyle = { height: "0px", width: "0px" };
export const tableAvatarStyle = {
  color: "white",
  backgroundColor: "background.bgLayoutA",
  mr: "10px",
};

//Cards Style

export const cardlistMainBox = { display: "flex", gap: 2 };
export const cardlistMainGrid = { display: "flex" };
export const cardlistCard = { borderRadius: 5 };
export const cardlistCardContent = {
  backgroundColor: "background.dashboardAlarmCardBg",
  paddingTop: "10px",
  pb: 0,
  "&.MuiCardContent-root:last-child": {
    paddingBottom: "10px",
  },
};
export const cardlistCardContentBox = {
  display: "flex",
  alignItems: "center",
  gap: 1,
};
export const cardlistCardContentAvatar = {
  backgroundColor: "background.bgLayoutB",
  height: "30px",
  width: "30px",
  p: "8px",
};
export const cardlistCardContentAlarmIcon = {
  height: "40px",
  width: "40px",
  color: "white",
};
