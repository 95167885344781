// @mui
import { enUS, nbNO, nlNL } from "@mui/material/locale";

export const allLangs = [
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "/assets/icons/flags/ic_flag_en.svg",
  },
  {
    label: "Norwegian",
    value: "nb",
    systemValue: nbNO,
    icon: "/assets/icons/flags/ic_flag_nb.svg",
  },
  {
    label: "Dutch",
    value: "nl",
    systemValue: nlNL,
    icon: "/assets/icons/flags/ic_flag_nl.svg",
  },
];

export const defaultLang = allLangs[0]; // English
