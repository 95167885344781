import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Box,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  dialogCancelButton,
  textfield,
  textfieldAccessKey,
  userHeading,
} from "../../utils/muiComponentStyles";
import toasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { useDispatch } from "../../redux/store";
import { createMuncipality } from "../../redux/slices/muncipalitySlice";

const Index = ({ openAddDialog, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [municipalitieName, setMunicipalitieName] = useState({
    name: null,
    muniAccessKey: null,
    muniId: null,
  });
  const [buttonSubmit, setButtonSubmit] = useState(false);
  const fireToasterContext = useContext(toasterContext);

  const handleChangeMunicipalitie = (event) => {
    setMunicipalitieName({
      ...municipalitieName,
      [event.target.name]: event.target.value,
    });
  };
  const handleCreateNewMunicipality = () => {
    setButtonSubmit(true);
    if (
      municipalitieName.name &&
      municipalitieName.muniAccessKey &&
      municipalitieName.muniId
    ) {
      setLoader(true);
      let finalData = {
        name: municipalitieName.name,
        muniAccessKey: municipalitieName.muniAccessKey.toUpperCase(),
        muniId: municipalitieName.muniId,
      };

      dispatch(createMuncipality(finalData, handleApiRes));
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please fill all the required fields"
      );
    }
  };

  const handleApiRes = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(true, data?.message);
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(false, data?.message);
      handleCloseDialog();
    }
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openAddDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={userHeading} variant="userTableHeadingBold">
            Add New Municipality
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box paddingTop={4}>
            <TextField
              sx={{ ...textfield, mb: 2 }}
              fullWidth
              label="Name"
              name="name"
              variant="outlined"
              type="text"
              onChange={(event) => handleChangeMunicipalitie(event)}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
              error={!municipalitieName?.name && buttonSubmit}
              helperText={
                !municipalitieName?.name && buttonSubmit
                  ? "Municipality name is required"
                  : ""
              }
            />
            <TextField
              sx={{ ...textfieldAccessKey, mb: 2 }}
              fullWidth
              label="Municipality Access Key"
              name="muniAccessKey"
              variant="outlined"
              type="text"
              onChange={(event) => handleChangeMunicipalitie(event)}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
              error={!municipalitieName?.muniAccessKey && buttonSubmit}
              helperText={
                !municipalitieName?.muniAccessKey && buttonSubmit
                  ? "Municipality access key is required"
                  : ""
              }
            />
            <TextField
              sx={{ ...textfield, mb: 2 }}
              fullWidth
              label="Municipality Id"
              name="muniId"
              variant="outlined"
              type="text"
              onChange={(event) => handleChangeMunicipalitie(event)}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
              error={!municipalitieName?.muniId && buttonSubmit}
              helperText={
                !municipalitieName?.muniId && buttonSubmit
                  ? "Municipality Id is required"
                  : ""
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={{ ...dialogCancelButton, px: 3 }}
            autoFocus
            onClick={handleCloseDialog}
          >
            cancel
          </Button>

          <Button
            type="submit"
            onClick={handleCreateNewMunicipality}
            disabled={loader}
            sx={
              loader
                ? {
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    background: "#979797",
                    borderRadius: "10px",
                    px: 3,
                    "&:hover": { backgroundColor: "#979797" },
                    "&:disabled": {
                      backgroundColor: "#979797",
                      color: "white",
                    },
                  }
                : {
                    backgroundColor: "background.bgLayoutB",
                    color: "white",
                    fontFamily: "Poppins-semibold",
                    fontSize: "15px",
                    borderRadius: "10px",
                    px: 3,
                    "&:hover": {
                      backgroundColor: "background.bgLayoutB",
                      boxShadow: 2,
                    },
                    "&:disabled": {
                      backgroundColor: "#979797",
                      color: "white",
                    },
                  }
            }
          >
            Create Municipality
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default Index;
