import React, { useEffect, useState } from "react";
import { dashboardHeading } from "../../utils/muiComponentStyles";
import { useDispatch, useSelector } from "../../redux/store";
import { getDashboardStatsData } from "../../redux/slices/dashboard/dashboardSlice";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar,
} from "@mui/material";
import {
  cardlistCard,
  cardlistCardContent,
  cardlistCardContentAvatar,
  cardlistCardContentBox,
  cardlistMainBox,
  cardlistMainGrid,
} from "./muiDashboardComponentStyles";
// import NewFile from "../../assets/icons/NewFile.svg";
import WalltetPng from "../../assets/icons/wallet 1.png";
import BackCamera from "../../assets/icons/BackCamera.svg";
// import JusticeHammer from "../../assets/icons/JusticeHammer.svg";
import UserCircleSingle from "../../assets/icons/UserCircleSingle.svg";
// import CheckSquare from "../../assets/icons/CheckSquare.svg";
import Loader from "../Loader/Loader";
const CardList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getDashboardStatsData(handleApiResponse));
  }, []);

  const handleApiResponse = (response) => {
    if (response.status) {
      setLoading(false);
    }
  };

  const { dashboardStatsData } = useSelector((state) => state.dashboardReducer);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "auto",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box sx={cardlistMainBox}>
          <Grid container spacing={2} sx={cardlistMainGrid}>
            {/* <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card sx={cardlistCard} elevation={0}>
                <CardContent sx={cardlistCardContent}>
                  <Box sx={cardlistCardContentBox}>
                    <Avatar
                      sx={cardlistCardContentAvatar}
                      src={NewFile}
                      alt="NewFile"
                    />
                    <Box>
                      <Typography
                        sx={dashboardHeading}
                        variant="dashboardInnerHeading"
                        gutterBottom
                      >
                        Contractors
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 17,
                          color: "#D52027",
                          fontWeight: 400,
                          fontFamily: "Poppins-Medium",
                        }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {dashboardStatsData.contractorsCount}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid> */}
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card sx={cardlistCard} elevation={0}>
                <CardContent sx={cardlistCardContent}>
                  <Box sx={cardlistCardContentBox}>
                    <Avatar
                      sx={cardlistCardContentAvatar}
                      src={BackCamera}
                      alt="BackCamera"
                    />

                    <Box>
                      <Typography
                        sx={dashboardHeading}
                        variant="dashboardInnerHeading"
                        gutterBottom
                      >
                        New Applications
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#D52027",
                          fontWeight: 400,
                          fontFamily: "Poppins",
                        }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {dashboardStatsData.applicationCount}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card sx={cardlistCard} elevation={0}>
                <CardContent sx={cardlistCardContent}>
                  <Box sx={cardlistCardContentBox}>
                    <Avatar
                      sx={cardlistCardContentAvatar}
                      src={WalltetPng}
                      alt="WalltetPng"
                    />
                    <Box>
                      <Typography
                        sx={dashboardHeading}
                        variant="dashboardInnerHeading"
                        gutterBottom
                      >
                        Waiting for Payment
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#D52027",
                          fontWeight: 400,
                          fontFamily: "Poppins",
                        }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {dashboardStatsData.waitingforPaymentCount}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card sx={cardlistCard} elevation={0}>
                <CardContent sx={cardlistCardContent}>
                  <Box sx={cardlistCardContentBox}>
                    <Avatar
                      sx={cardlistCardContentAvatar}
                      src={UserCircleSingle}
                      alt="UserCircleSingle"
                    />

                    <Box>
                      <Typography
                        sx={dashboardHeading}
                        variant="dashboardInnerHeading"
                        gutterBottom
                      >
                        Pending Permits
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: "#D52027",
                          fontWeight: 400,
                          fontFamily: "Poppins",
                        }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {dashboardStatsData.pendingPermitsCount}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card sx={cardlistCard} elevation={0}>
                <CardContent sx={cardlistCardContent}>
                  <Box sx={cardlistCardContentBox}>
                    <Avatar
                      sx={cardlistCardContentAvatar}
                      src={JusticeHammer}
                      alt="justiceHammer"
                    />
                    <Box>
                      <Typography
                        sx={dashboardHeading}
                        variant="dashboardInnerHeading"
                        gutterBottom
                      >
                        Municipalities
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 17,
                          color: "#D52027",
                          fontWeight: 400,
                          fontFamily: "Poppins",
                        }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {dashboardStatsData.muncipalitiesCount}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid> */}
            {/* <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card sx={cardlistCard} elevation={0}>
                <CardContent sx={cardlistCardContent}>
                  <Box sx={cardlistCardContentBox}>
                    <Avatar
                      sx={cardlistCardContentAvatar}
                      src={CheckSquare}
                      alt="CheckSquare"
                    />

                    <Box>
                      <Typography
                        sx={dashboardHeading}
                        variant="dashboardInnerHeading"
                        gutterBottom
                      >
                        Approved Permits
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 17,
                          color: "#D52027",
                          fontWeight: 400,
                          fontFamily: "Poppins",
                        }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {dashboardStatsData.approvedPermitsCount}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default CardList;
