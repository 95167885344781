import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dispatch } from "../store";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const initialState = {
  inspectionsList: [],
};

const slice = createSlice({
  name: "inspections",
  initialState,
  reducers: {
    getInspectionsList(state, action) {
      state.inspectionsList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAllInspections(handleApiResponse) {
  let data = JSON.parse(localStorage.getItem("selectedApplication"));

  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/recorded-inspections?applicationId=${data.id}`,
        { headers }
      );

      if (handleApiResponse) {
        handleApiResponse(response.data);
      }
      dispatch(slice.actions.getInspectionsList(response.data));
    } catch (error) {
      console.log(error);
      if (handleApiResponse) {
        handleApiResponse(error);
      }
    }
  };
}

export function createNewInspection(finalObj, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/recorded-inspections`,
        finalObj,
        { headers }
      );
      dispatch(getAllInspections());
      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error?.response?.data);
      }
    }
  };
}

export function updateRecordInspection(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/recorded-inspections?id=${data.id}`,
        data,
        { headers }
      );
      dispatch(getAllInspections());
      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}

export function deleteInspection(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/recorded-inspections?id=${id}`,
        { headers }
      );
      dispatch(getAllInspections());
      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      if (handleApiRes) {
        handleApiRes(error.response.data);
      }
    }
  };
}
