import React from "react";
import Index from "../components/Reports/Index";
const Reports = () => {
  return (
    <div>
      <Index />
    </div>
  );
};
export default Reports;
