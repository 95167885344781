import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Box,
  Card,
  CardHeader,
  // Container,
  Typography,
  Grid,
  Divider,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  CardActions,
  CardContent,
  // FormHelperText,
} from "@mui/material";
import {
  textfield,
  buildingPlacardTypograpghy,
  tableHeadCellStyle,
  tableCellDataStyle,
  buildingPlacardContractorField,
  // tableCell1stColumnDataStyle,
  contractorCheckboxStyle,
  buildingPlacardFormSubmitButton,
  buildingPlacardDownloadButton,
  buildingPlacardTextfieldDate,
  villageCitySelector,
  buildingPlacardSiteInfoButton,
} from "./BuildingPlacardStyle";
import {
  createBuilidingPlacardData,
  // emptyObject,
  getAllBuilidingPlacardData,
} from "../../../redux/slices/BuildingPlacardSlice";
import { useSelector, useDispatch } from "../../../redux/store";
import Loader from "../../Loader/Loader";
import ToasterContext from "../../../utils/context/toasterContext";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import BuildingPlacardPdf from "./BuildingPlacardPdf";
import { uploadPdf } from "../../../redux/slices/PdfDataSlice";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  getApplicantStatus,
  // getSingleApplicationData,
  sendEmailToApplicant,
} from "../../../redux/slices/dashboard/applicantsSlice";
import {
  applicationCardStyle,
  miniScrollBarStyles,
  recordInspectionTypo,
} from "../../../utils/muiComponentStyles";
import { getAllMuncipalities } from "../../../redux/slices/muncipalitySlice";

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
  forcePathStyle: true,
  region: process.env.REACT_APP_SPACES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
  },
});

const projectTypeList = [
  { id: 1, name: "New Building" },
  { id: 2, name: "Addition" },
  { id: 3, name: "Alteration/Repair" },
  { id: 4, name: "Deck" },
  { id: 5, name: "Demolition" },
  { id: 6, name: "Driveway" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Pool" },
];

const cityVillageTownArrayType = [
  { id: "1", name: "Town" },
  { id: "2", name: "Village" },
  { id: "3", name: "City" },
  { id: "4", name: "Country" },
  { id: "5", name: "State" },
];

let generalContractorInfo = null;
let hvacContractorInfo = null;
let plumbingContractorInfo = null;
let electricalContractorInfo = null;
let masterElectricalContractorInfo = null;

const BusinessPlacard = () => {
  let selectedApplicationInfo = JSON.parse(
    localStorage.getItem("selectedApplication")
  );
  const permissions = localStorage.getItem("permissions");
  const permissionArray = permissions ? permissions.split(",") : [];
  const [manipulationPermission, setManipulationPermission] = useState(false);
  let { buildingPlacardData } = useSelector(
    (state) => state.buildingPlacardReducer
  );

  let { muncipalityList } = useSelector((state) => state.muncipalityReducer);
  const fireToasterContext = useContext(ToasterContext);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [buildingPlacardObject, setBuildingPlacardObject] = useState({});
  const [placardContractors, setPlacardContractors] = useState([]);
  const [issuedToObject, setIssuedToObject] = useState({
    ownerName: "",
    ownerPhoneNumber: "",
    buildingSiteAddress: "",
    cityVillageTownType: 1,
    cityVillageTown: "",
  });
  const [issuedByObject, setIssuedByObject] = useState();

  const [buttonSubmit, setButtonSubmit] = useState(false);
  const [loaderButton, setLoaderButton] = useState(false);
  const [emailButtonLoader, setEmailButtonLoader] = useState(false);
  const [showSiteInfoForm, setShowSiteInfoForm] = useState(false);
  const [
    buildingPlacardContractorCheckbox,
    setBuildingPlacardContractorCheckbox,
  ] = useState({
    construction: false,
    hvac: false,
    electrical: false,
    plumbing: false,
    erosion: false,
  });

  console.log("Building", buildingPlacardData?.application);

  useEffect(() => {
    if (!permissionArray.includes("manipulate application details")) {
      setManipulationPermission(true);
      setLoaderButton(true);
    } else {
      setManipulationPermission(false);
    }
    dispatch(getAllMuncipalities());
    dispatch(getAllBuilidingPlacardData(handleApiResponse));
    setLoader(true);
    setBuildingPlacardContractorCheckbox({
      construction: buildingPlacardData?.application?.permitRequested?.includes(
        1
      )
        ? true
        : false,
      hvac: buildingPlacardData?.application?.permitRequested?.includes(2)
        ? true
        : false,
      electrical: buildingPlacardData?.application?.permitRequested?.includes(3)
        ? true
        : false,
      plumbing: buildingPlacardData?.application?.permitRequested?.includes(4)
        ? true
        : false,
      erosion: buildingPlacardData?.application?.permitRequested?.includes(5)
        ? true
        : false,
    });
  }, []);

  useEffect(() => {
    if (buildingPlacardData) {
      setBuildingPlacardObject(buildingPlacardData?.siteInfo);
      // setIssuedByObject(buildingPlacardData?.issuedBy);
      setIssuedToObject({
        ownerName: buildingPlacardData?.issuedTo?.ownerName,
        ownerPhoneNumber: buildingPlacardData?.issuedTo?.ownerPhoneNumber,
        buildingSiteAddress: buildingPlacardData?.issuedTo?.buildingSiteAddress,
        cityVillageTownType:
          buildingPlacardData?.issuedTo?.cityVillageTownType || 1,
        cityVillageTown:
          buildingPlacardData?.issuedTo?.cityVillageTown ||
          muncipalityList.find(
            (option) => option?.id === selectedApplicationInfo?.muncipalityId
          )?.name,
      });
      setIssuedByObject({
        certificateNumber: buildingPlacardData?.issuedBy?.certificateNumber,
        dateIssued:
          buildingPlacardData?.issuedBy?.dateIssued ||
          new Date().toISOString().split("T")[0],
        personIssuingName: buildingPlacardData?.issuedBy?.personIssuingName,
        phoneNumber: buildingPlacardData?.issuedBy?.phoneNumber,
      });
      setPlacardContractors(buildingPlacardData?.placardContractors);
      if (
        !generalContractorInfo &&
        buildingPlacardData?.placardContractors?.length > 0
      ) {
        generalContractorInfo = buildingPlacardData?.placardContractors[0];
        hvacContractorInfo = buildingPlacardData?.placardContractors[1];
        plumbingContractorInfo = buildingPlacardData?.placardContractors[2];
        masterElectricalContractorInfo =
          buildingPlacardData?.placardContractors[3];
        electricalContractorInfo = buildingPlacardData?.placardContractors[4];
      }

      setBuildingPlacardContractorCheckbox({
        construction:
          buildingPlacardData?.application?.permitRequested?.includes(1)
            ? true
            : false,
        hvac: buildingPlacardData?.application?.permitRequested?.includes(2)
          ? true
          : false,
        electrical: buildingPlacardData?.application?.permitRequested?.includes(
          3
        )
          ? true
          : false,
        plumbing: buildingPlacardData?.application?.permitRequested?.includes(4)
          ? true
          : false,
        erosion: buildingPlacardData?.application?.permitRequested?.includes(5)
          ? true
          : false,
      });
    }
  }, [buildingPlacardData]);

  useEffect(() => {
    if (typeof buildingPlacardData?.siteInfo !== "undefined") {
      if (buildingPlacardData?.siteInfo?.id !== null) {
        if (buildingPlacardData.siteInfo.subDivision === null) {
          setShowSiteInfoForm(false);
          setButtonSubmit(false);
        } else {
          setShowSiteInfoForm(true);
        }
      }
    }

    //   if (buildingPlacardData?.issuedTo?.cityVillageTown === null) {
    //     setLoaderButton(false);
    //   } else {
    //     handlePdfData();
    //     setLoaderButton(true);
    //   }
  }, [buildingPlacardData]);

  const handleApiResponse = (data) => {
    if (data.status) {
      setLoader(false);
      if (data?.data?.issuedBy?.dateIssued === null) {
        if (!permissionArray.includes("manipulate application details")) {
          setLoaderButton(true);
        } else {
          setLoaderButton(false);
        }
      } else {
        handlePdfData(data?.data);
        setLoaderButton(true);
      }
      if (!data.data.application.dwellingContractorByOwner) {
        generalContractorInfo = null;
      }
      if (!data.data.application.electricalContractorByOwner) {
        electricalContractorInfo = null;
        masterElectricalContractorInfo = null;
      }
      if (!data.data.application.hvacContractorByOwner) {
        hvacContractorInfo = null;
      }
      if (!data.data.application.plumbingContractorByOwner) {
        plumbingContractorInfo = null;
      }
      if (data.data.siteInfo.subDivision === null) {
        setShowSiteInfoForm(false);
        setButtonSubmit(false);
      }
    } else {
      setLoader(false);
    }
  };

  const handleChange = (event) => {
    setLoaderButton(false);
    event.preventDefault();
    setBuildingPlacardObject({
      ...buildingPlacardObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeContractorChecbox = (event) => {
    let tempPlacardContractors = [...placardContractors];
    tempPlacardContractors = JSON.parse(JSON.stringify(tempPlacardContractors));
    if (event.target.name == "construction") {
      if (event.target.checked) {
        if (generalContractorInfo?.contractorId) {
          tempPlacardContractors[0].name = generalContractorInfo.name;
          tempPlacardContractors[0].credential =
            generalContractorInfo.credential;
          tempPlacardContractors[0].phoneNumber =
            generalContractorInfo.phoneNumber;
          tempPlacardContractors[0].contractorId =
            generalContractorInfo.contractorId;
        } else {
          tempPlacardContractors[0].name = "Owner";
          tempPlacardContractors[0].credential = null;
          tempPlacardContractors[0].phoneNumber = null;
          tempPlacardContractors[0].contractorId = null;
        }
      } else {
        tempPlacardContractors[0].name = "G.G";
        tempPlacardContractors[0].credential = null;
        tempPlacardContractors[0].phoneNumber = null;
        tempPlacardContractors[0].contractorId = null;
      }
    }
    if (event.target.name == "hvac") {
      if (event.target.checked) {
        if (hvacContractorInfo?.contractorId) {
          tempPlacardContractors[1].name = hvacContractorInfo.name;
          tempPlacardContractors[1].credential = hvacContractorInfo.credential;
          tempPlacardContractors[1].phoneNumber =
            hvacContractorInfo.phoneNumber;
          tempPlacardContractors[1].contractorId =
            hvacContractorInfo.contractorId;
        } else {
          tempPlacardContractors[1].name = "Owner";
          tempPlacardContractors[1].credential = null;
          tempPlacardContractors[1].phoneNumber = null;
          tempPlacardContractors[1].contractorId = null;
        }
      } else {
        tempPlacardContractors[1].name = "HVAC";
        tempPlacardContractors[1].credential = null;
        tempPlacardContractors[1].phoneNumber = null;
        tempPlacardContractors[1].contractorId = null;
      }
    }
    if (event.target.name == "plumbing") {
      if (event.target.checked) {
        if (plumbingContractorInfo?.contractorId) {
          tempPlacardContractors[2].name = plumbingContractorInfo.name;
          tempPlacardContractors[2].credential =
            plumbingContractorInfo.credential;
          tempPlacardContractors[2].phoneNumber =
            plumbingContractorInfo.phoneNumber;
          tempPlacardContractors[2].contractorId =
            plumbingContractorInfo.contractorId;
        } else {
          tempPlacardContractors[2].name = "Owner";
          tempPlacardContractors[2].credential = null;
          tempPlacardContractors[2].phoneNumber = null;
          tempPlacardContractors[2].contractorId = null;
        }
      } else {
        tempPlacardContractors[2].name = "PLBG";
        tempPlacardContractors[2].credential = null;
        tempPlacardContractors[2].phoneNumber = null;
        tempPlacardContractors[2].contractorId = null;
      }
    }
    if (event.target.name == "electrical") {
      if (event.target.checked) {
        if (masterElectricalContractorInfo?.contractorId) {
          // For Master Electrical
          tempPlacardContractors[3].name = masterElectricalContractorInfo.name;
          tempPlacardContractors[3].credential =
            masterElectricalContractorInfo.credential;
          tempPlacardContractors[3].phoneNumber =
            masterElectricalContractorInfo.phoneNumber;
          tempPlacardContractors[3].contractorId =
            electricalContractorInfo.contractorId;

          // For Electrical
          tempPlacardContractors[4].name = electricalContractorInfo.name;
          tempPlacardContractors[4].credential =
            electricalContractorInfo.credential;
          tempPlacardContractors[4].phoneNumber =
            electricalContractorInfo.phoneNumber;
          tempPlacardContractors[4].contractorId =
            electricalContractorInfo.contractorId;
        } else {
          // For Master Electrical
          tempPlacardContractors[3].name = "Owner";
          tempPlacardContractors[3].credential = null;
          tempPlacardContractors[3].phoneNumber = null;
          tempPlacardContractors[3].contractorId = null;

          // For electrical
          tempPlacardContractors[4].name = "Owner";
          tempPlacardContractors[4].credential = null;
          tempPlacardContractors[4].phoneNumber = null;
          tempPlacardContractors[4].contractorId = null;
        }
      } else {
        // For Master Electrical
        tempPlacardContractors[3].name = "MSTR ELECT";
        tempPlacardContractors[3].credential = null;
        tempPlacardContractors[3].phoneNumber = null;
        tempPlacardContractors[3].contractorId = null;

        // For Electrical
        tempPlacardContractors[4].name = "ELECT";
        tempPlacardContractors[4].credential = null;
        tempPlacardContractors[4].phoneNumber = null;
        tempPlacardContractors[4].contractorId = null;
      }
    }
    setPlacardContractors(tempPlacardContractors);
    setLoaderButton(false);
    setBuildingPlacardContractorCheckbox({
      ...buildingPlacardContractorCheckbox,
      [event.target.name]: event.target.checked,
    });
  };

  const handleContractorChange = (event, index, specificContractorId) => {
    if (event.target.name == "name") {
      if (specificContractorId) {
        setLoaderButton(false);
        event.preventDefault();
        let tempPlacardContractors = [...placardContractors];
        tempPlacardContractors = JSON.parse(
          JSON.stringify(tempPlacardContractors)
        );
        tempPlacardContractors[index][event.target.name] = event.target.value;
        setPlacardContractors(tempPlacardContractors);
      }
    } else {
      setLoaderButton(false);
      event.preventDefault();
      let tempPlacardContractors = [...placardContractors];
      tempPlacardContractors = JSON.parse(
        JSON.stringify(tempPlacardContractors)
      );
      tempPlacardContractors[index][event.target.name] = event.target.value;
      setPlacardContractors(tempPlacardContractors);
    }
  };

  const handleIssuedToObjectChange = (event) => {
    setLoaderButton(false);
    event.preventDefault();
    setIssuedToObject({
      ...issuedToObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleIssuedByObjectChange = (event) => {
    setLoaderButton(false);
    event.preventDefault();
    setIssuedByObject({
      ...issuedByObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleCreateApiResponse = (data) => {
    if (data.status) {
      setTimeout(() => {
        setBtnLoading(false);
        fireToasterContext.fireToasterHandler(
          true,
          data.message ? data.message : "contractor created"
        );
      }, 2000);
      dispatch(getAllBuilidingPlacardData(handleApiResponse));
      dispatch(getApplicantStatus(selectedApplicationInfo.id));
    } else {
      setBtnLoading(false);
      fireToasterContext.fireToasterHandler(
        false,
        data?.message ? data?.message : "Something went wrong"
      );
    }
  };

  // const handlePdfResponse = (data) => {
  //   if (data.status) {
  //     setLoaderButton(true);
  //   }
  // };

  const handlePdfData = async (latestData) => {
    // if (loaderButton !== true) {
    //   return;
    // }

    try {
      let pdfLink;
      const pdfBlob = await pdf(
        <BuildingPlacardPdf data={latestData} />
      ).toBlob();
      const pdfBuffer = await new Response(pdfBlob).arrayBuffer();
      const params = {
        Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
        Key: `${process.env.REACT_APP_ENVIRONMENT}/${
          selectedApplicationInfo?.id
        }/Building-Placard-Detail-${Date.now()}.pdf`,
        Body: pdfBuffer,
        ACL: "public-read",
        ContentType: "application/pdf",
      };

      // {
      //   buildingPlacardData?.siteInfo?.id;
      // }

      await s3Client.send(new PutObjectCommand(params));
      pdfLink = `${process.env.REACT_APP_SPACES_ENDPOINT}/${params.Bucket}/${params.Key}`;
      selectedApplicationInfo.buildingPlacardPdfUrl = pdfLink;
      localStorage.setItem(
        "selectedApplication",
        JSON.stringify(selectedApplicationInfo)
      );
      const pdfData = {
        applicationId: selectedApplicationInfo?.id,
        applicationDetailPdfUrl: null,
        occupancyLetterPdfUrl: null,
        buildingPlacardPdfUrl: pdfLink,
        permitFeePdfUrl: null,
      };
      dispatch(uploadPdf(pdfData));
      // dispatch(emptyObject());
      setLoader(false);
      // setLoaderButton(true);
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handleEmailApiResponse = (data) => {
    if (data.status) {
      setEmailButtonLoader(false);
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Email sent successfully"
      );
      // dispatch(getSingleApplicationData(selectedApplicationInfo?.id));
    } else {
      setEmailButtonLoader(false);
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleSendEmailToApplicant = () => {
    if (!loaderButton) {
      fireToasterContext.fireToasterHandler(
        false,
        "Please save information first"
      );
      return;
    }

    setEmailButtonLoader(true);
    let finalData = {
      applicationId: selectedApplicationInfo?.id,
      applicationDetail: false,
      permitFee: false,
      buildingPlacard: true,
      occupancyLetter: false,
      message: "",
    };
    dispatch(sendEmailToApplicant(finalData, handleEmailApiResponse));
  };

  const handleSubmit = () => {
    setButtonSubmit(true);
    if (
      showSiteInfoForm &&
      (!buildingPlacardObject.subDivision ||
        !buildingPlacardObject.plotNumber ||
        !buildingPlacardObject.blockNumber ||
        !buildingPlacardObject.zoningDistrict ||
        !buildingPlacardObject.parcelNumber ||
        !buildingPlacardObject.setBacks ||
        !buildingPlacardObject.front ||
        !buildingPlacardObject.left ||
        !buildingPlacardObject.rear ||
        !buildingPlacardObject.right)
    ) {
      fireToasterContext.fireToasterHandler(
        false,
        "Please fill all site info detail required fields"
      );
      return;
    }

    // let generalType = generalContractorInfo.type;

    // if (generalType) {
    //   if (
    //     buildingPlacardContractorCheckbox.construction &&
    //     generalContractorInfo?.contractorId &&
    //     (!placardContractors[0].name || !placardContractors[0].phoneNumber)
    //   ) {
    //     fireToasterContext.fireToasterHandler(
    //       false,
    //       "Please fill required data of contractors"
    //     );
    //     return;
    //   }
    // } else if (
    //   buildingPlacardContractorCheckbox.construction &&
    //   generalContractorInfo?.contractorId &&
    //   (!placardContractors[0].name ||
    //     !placardContractors[0].credential ||
    //     !placardContractors[0].phoneNumber)
    // ) {
    //   fireToasterContext.fireToasterHandler(
    //     false,
    //     "Please fill required data of contractors"
    //   );
    //   return;
    // }

    // if (
    //   buildingPlacardContractorCheckbox.hvac &&
    //   hvacContractorInfo?.contractorId &&
    //   (!placardContractors[1].name ||
    //     !placardContractors[1].credential ||
    //     !placardContractors[1].phoneNumber)
    // ) {
    //   fireToasterContext.fireToasterHandler(
    //     false,
    //     "Please fill all required data of contractors"
    //   );
    //   return;
    // }

    // if (
    //   buildingPlacardContractorCheckbox.plumbing &&
    //   plumbingContractorInfo?.contractorId &&
    //   (!placardContractors[2].name ||
    //     !placardContractors[2].credential ||
    //     !placardContractors[2].phoneNumber)
    // ) {
    //   fireToasterContext.fireToasterHandler(
    //     false,
    //     "Please fill all required data of contractors"
    //   );
    //   return;
    // }

    // if (
    //   buildingPlacardContractorCheckbox.electrical &&
    //   (electricalContractorInfo?.contractorId ||
    //     masterElectricalContractorInfo?.contractorId) &&
    //   (!placardContractors[3].name ||
    //     !placardContractors[3].credential ||
    //     !placardContractors[3].phoneNumber ||
    //     !placardContractors[4].name ||
    //     !placardContractors[4].credential ||
    //     !placardContractors[4].phoneNumber)
    // ) {
    //   fireToasterContext.fireToasterHandler(
    //     false,
    //     "Please fill required data of contractors"
    //   );
    //   return;
    // }

    if (
      issuedToObject.ownerName &&
      issuedToObject.ownerPhoneNumber &&
      issuedToObject.buildingSiteAddress &&
      issuedToObject.cityVillageTownType &&
      issuedByObject.personIssuingName &&
      issuedByObject.certificateNumber &&
      issuedByObject.dateIssued &&
      issuedByObject.phoneNumber
    ) {
      let finalObject = {
        data: {
          siteInfo: {
            applicationId: selectedApplicationInfo?.id,
            subDivision: showSiteInfoForm
              ? buildingPlacardObject.subDivision
              : null,
            plotNumber: showSiteInfoForm
              ? buildingPlacardObject.plotNumber
              : null,
            blockNumber: showSiteInfoForm
              ? buildingPlacardObject.blockNumber
              : null,
            zoningDistrict: showSiteInfoForm
              ? buildingPlacardObject.zoningDistrict
              : null,
            parcelNumber: showSiteInfoForm
              ? buildingPlacardObject.parcelNumber
              : null,
            setBacks: showSiteInfoForm ? buildingPlacardObject.setBacks : null,
            front: showSiteInfoForm ? buildingPlacardObject.front : null,
            rear: showSiteInfoForm ? buildingPlacardObject.rear : null,
            left: showSiteInfoForm ? buildingPlacardObject.left : null,
            right: showSiteInfoForm ? buildingPlacardObject.right : null,

            sealNumber: showSiteInfoForm
              ? buildingPlacardObject.sealNumber
              : buildingPlacardObject.sealNumber,
            // construction: buildingPlacardContractorCheckbox.construction,
            // electrical: buildingPlacardContractorCheckbox.electrical,
            // plumbing: buildingPlacardContractorCheckbox.plumbing,
            // hvac: buildingPlacardContractorCheckbox.hvac,
            // erosion: buildingPlacardContractorCheckbox.erosion,
            personIssuingName: issuedByObject.personIssuingName,
            certificateNumber: issuedByObject.certificateNumber,
            cityVillageTownType: issuedToObject.cityVillageTownType,
            cityVillageTown: issuedToObject.cityVillageTown,
            dateIssued: issuedByObject.dateIssued,
            phoneNumber: issuedByObject.phoneNumber,
          },
          issuedTo: {
            ownerName: issuedToObject.ownerName,
            ownerPhoneNumber: issuedToObject.ownerPhoneNumber,
            buildingSiteAddress: issuedToObject.buildingSiteAddress,
            // cityVillageTownType:
          },
          application: {
            dwellingContractorByOwner:
              buildingPlacardContractorCheckbox.construction,
            electricalContractorByOwner:
              buildingPlacardContractorCheckbox.electrical,
            plumbingContractorByOwner:
              buildingPlacardContractorCheckbox.plumbing,
            hvacContractorByOwner: buildingPlacardContractorCheckbox.hvac,
            erosionControl: buildingPlacardContractorCheckbox.erosion,
            dwellingContractorId: buildingPlacardContractorCheckbox.construction
              ? buildingPlacardData?.application?.dwellingContractorId
              : null,
            electricalContractorId: buildingPlacardContractorCheckbox.electrical
              ? buildingPlacardData?.application?.electricalContractorId
              : null,
            plumbingContractorId: buildingPlacardContractorCheckbox.plumbing
              ? buildingPlacardData?.application?.plumbingContractorId
              : null,
            hvacContractorId: buildingPlacardContractorCheckbox.hvac
              ? buildingPlacardData?.application?.hvacContractorId
              : null,
            permitRequested: buildingPlacardData?.application?.permitRequested,
          },
          placardContractors: placardContractors,
        },
      };
      setBtnLoading(true);
      dispatch(
        createBuilidingPlacardData(finalObject, handleCreateApiResponse)
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields"
      );
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          disableTypography
          sx={applicationCardStyle}
          title={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>Building Placard Detail</Box>
              <Box>
                Status: {selectedApplicationInfo?.status} | Permit Fee:{" "}
                {(selectedApplicationInfo?.totalAmount * 1)?.toFixed(2)}
              </Box>
            </Box>
          }
        />
        {loader ? (
          <Box
            sx={{
              height: 180,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              mr: "88px",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box component="div" sx={{ my: 3, mx: 3 }}>
            {/* <PDFViewer style={{ height: "100vh", width: "100%" }}>
              <BuildingPlacardPdf data={buildingPlacardData} />
            </PDFViewer> */}
            <Typography
              sx={{ ...buildingPlacardTypograpghy, textAlign: "center" }}
            >
              Permit No: {selectedApplicationInfo?.permitNumber}
            </Typography>

            <Grid container spacing={2} sx={{ mt: 2, wordWrap: "break-word" }}>
              <Grid item xs={12} md={3}>
                <Typography sx={buildingPlacardTypograpghy}>
                  Project Type
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {
                    projectTypeList.find(
                      (project) =>
                        project.id === selectedApplicationInfo?.projectType
                    )?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography sx={buildingPlacardTypograpghy}>
                  Project Address
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {selectedApplicationInfo?.projectAddress}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography sx={buildingPlacardTypograpghy}>
                  Applicant Name
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {selectedApplicationInfo?.applicantName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography sx={buildingPlacardTypograpghy}>
                  Applicant Email
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {selectedApplicationInfo?.applicantEmail}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        <Divider />
        <CardContent
          sx={{
            height: "380px",
            overflowY: "auto",
            mt: 2,
            mx: 1,
            [`::-webkit-scrollbar`]: {
              width: "0px",
              background: "transparent",
            },
            ...miniScrollBarStyles,
          }}
        >
          {/* site info object div */}

          <Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  ...buildingPlacardTypograpghy,
                  color: "#212121",
                  mr: 0.3,
                }}
              >
                Site Info
              </Typography>
              <Button
                onClick={() => setShowSiteInfoForm(!showSiteInfoForm)}
                sx={buildingPlacardSiteInfoButton}
              >
                {showSiteInfoForm
                  ? "Hide Site Info Form"
                  : "Show Site Info Form"}
              </Button>
            </Box>
            {showSiteInfoForm && (
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {/* subdivision field */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    error={!buildingPlacardObject?.subDivision && buttonSubmit}
                    helperText={
                      !buildingPlacardObject?.subDivision && buttonSubmit
                        ? "Subdivision is required"
                        : ""
                    }
                    id="sub-division"
                    label="Sub Division"
                    name="subDivision"
                    value={buildingPlacardObject?.subDivision || ""}
                    sx={textfield}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* plot number field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!buildingPlacardObject?.plotNumber && buttonSubmit}
                    helperText={
                      !buildingPlacardObject?.plotNumber && buttonSubmit
                        ? "Lot number is required"
                        : ""
                    }
                    id="plot-number"
                    label="Lot Number"
                    name="plotNumber"
                    value={buildingPlacardObject?.plotNumber || ""}
                    sx={textfield}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* block number field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!buildingPlacardObject?.blockNumber && buttonSubmit}
                    helperText={
                      !buildingPlacardObject?.blockNumber && buttonSubmit
                        ? "Block number is required"
                        : ""
                    }
                    id="block-number"
                    label="Block Number"
                    name="blockNumber"
                    value={buildingPlacardObject?.blockNumber || ""}
                    sx={textfield}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* zoning District field */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    error={
                      !buildingPlacardObject?.zoningDistrict && buttonSubmit
                    }
                    helperText={
                      !buildingPlacardObject?.zoningDistrict && buttonSubmit
                        ? "Zoning disrtrict is required"
                        : ""
                    }
                    id="zoning-district"
                    label="Zoning District"
                    name="zoningDistrict"
                    value={buildingPlacardObject?.zoningDistrict || ""}
                    sx={textfield}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* parcel Number field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!buildingPlacardObject?.parcelNumber && buttonSubmit}
                    helperText={
                      !buildingPlacardObject?.parcelNumber && buttonSubmit
                        ? "Parcel number is required"
                        : ""
                    }
                    id="parcel-number"
                    label="Parcel Number"
                    name="parcelNumber"
                    value={buildingPlacardObject?.parcelNumber || ""}
                    sx={textfield}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* set backs field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!buildingPlacardObject?.setBacks && buttonSubmit}
                    helperText={
                      !buildingPlacardObject?.setBacks && buttonSubmit
                        ? "Set backs is required"
                        : ""
                    }
                    id="set-backs"
                    label="Set Backs"
                    name="setBacks"
                    value={buildingPlacardObject?.setBacks || ""}
                    sx={textfield}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                  />
                </Grid>

                {/* front field*/}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!buildingPlacardObject?.front && buttonSubmit}
                    helperText={
                      !buildingPlacardObject?.front && buttonSubmit
                        ? "Front is required"
                        : ""
                    }
                    id="front"
                    label="Front"
                    name="front"
                    value={buildingPlacardObject?.front || ""}
                    sx={textfield}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                    type="number"
                    inputProps={{ min: 1 }}
                  />
                </Grid>

                {/* rear field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!buildingPlacardObject?.rear && buttonSubmit}
                    helperText={
                      !buildingPlacardObject?.rear && buttonSubmit
                        ? "Rear is required"
                        : ""
                    }
                    id="rear"
                    label="Rear"
                    name="rear"
                    value={buildingPlacardObject?.rear || ""}
                    sx={textfield}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                    type="number"
                    inputProps={{ min: 1 }}
                  />
                </Grid>

                {/* left field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!buildingPlacardObject?.left && buttonSubmit}
                    helperText={
                      !buildingPlacardObject?.left && buttonSubmit
                        ? "Left is required"
                        : ""
                    }
                    id="left"
                    label="Left"
                    name="left"
                    value={buildingPlacardObject?.left || ""}
                    sx={textfield}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                    type="number"
                    inputProps={{ min: 1 }}
                  />
                </Grid>

                {/* right field */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    error={!buildingPlacardObject?.right && buttonSubmit}
                    helperText={
                      !buildingPlacardObject?.right && buttonSubmit
                        ? "Right is required"
                        : ""
                    }
                    id="right"
                    label="Right"
                    name="right"
                    value={buildingPlacardObject?.right || ""}
                    sx={textfield}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: manipulationPermission,
                    }}
                    type="number"
                    inputProps={{ min: 1 }}
                  />
                </Grid>
              </Grid>
            )}
          </Box>

          <Box>
            <Typography
              sx={{ ...buildingPlacardTypograpghy, color: "#212121", mt: 3 }}
            >
              Contractors
            </Typography>

            <TableContainer
              sx={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                mt: 2,
              }}
            >
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#212121",
                    }}
                  >
                    <TableCell sx={tableHeadCellStyle} align="center">
                      Name
                    </TableCell>
                    <TableCell sx={tableHeadCellStyle} align="center">
                      Credential No
                    </TableCell>
                    <TableCell sx={tableHeadCellStyle} align="center">
                      Phone
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {placardContractors?.map((contractor, index) => (
                    <TableRow key={index}>
                      <TableCell sx={tableCellDataStyle}>
                        <TextField
                          // error={!contractor?.name && buttonSubmit}
                          // helperText={
                          //   !contractor.name && buttonSubmit
                          //     ? "Name is is required"
                          //     : ""
                          // }
                          id="seat-number"
                          name="name"
                          // disabled={contractor?.contractorId === null}
                          value={contractor.name || ""}
                          sx={buildingPlacardContractorField}
                          onChange={(event) =>
                            handleContractorChange(
                              event,
                              index,
                              contractor?.contractorId
                            )
                          }
                          InputProps={{
                            readOnly: manipulationPermission,
                          }}
                        />
                      </TableCell>
                      <TableCell sx={tableCellDataStyle}>
                        <TextField
                          // error={
                          //   contractor.type !== "General" &&
                          //   contractor?.contractorId &&
                          //   !contractor?.credential &&
                          //   buttonSubmit
                          // }
                          // helperText={
                          //   contractor.type !== "General" &&
                          //   contractor?.contractorId &&
                          //   !contractor.credential &&
                          //   buttonSubmit
                          //     ? "Credential is is required"
                          //     : ""
                          // }
                          id="seat-number"
                          name="credential"
                          disabled={
                            contractor?.contractorId === null ||
                            contractor.type === "General"
                          }
                          value={contractor.credential || ""}
                          sx={buildingPlacardContractorField}
                          onChange={(event) =>
                            handleContractorChange(event, index)
                          }
                          InputProps={{
                            readOnly: manipulationPermission,
                          }}
                        />
                      </TableCell>
                      <TableCell sx={tableCellDataStyle}>
                        <TextField
                          // error={
                          //   contractor?.contractorId &&
                          //   !contractor?.phoneNumber &&
                          //   buttonSubmit
                          // }
                          // helperText={
                          //   contractor?.contractorId &&
                          //   !contractor.phoneNumber &&
                          //   buttonSubmit
                          //     ? "Phone number is is required"
                          //     : ""
                          // }
                          id="seat-number"
                          name="phoneNumber"
                          disabled={contractor?.contractorId === null}
                          value={contractor.phoneNumber || ""}
                          sx={buildingPlacardContractorField}
                          onChange={(event) =>
                            handleContractorChange(event, index)
                          }
                          InputProps={{
                            readOnly: manipulationPermission,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ mt: 3, mb: 2 }}>
            <TextField
              // error={!buildingPlacardObject?.sealNumber && buttonSubmit}
              // helperText={
              //   !buildingPlacardObject?.sealNumber && buttonSubmit
              //     ? "Seal number is required"
              //     : ""
              // }
              id="seal-number"
              label="Seal Number"
              name="sealNumber"
              type="number"
              value={buildingPlacardObject?.sealNumber || ""}
              sx={textfield}
              onChange={handleChange}
              InputProps={{
                readOnly: manipulationPermission,
              }}
              inputProps={{ min: 1 }}
            />
          </Box>

          {/* Contractor checkbox box */}
          <Box>
            <FormControl component="fieldset" variant="standard">
              <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={buildingPlacardContractorCheckbox?.construction}
                      onChange={handleChangeContractorChecbox}
                      name="construction"
                      id="construction-checkbox"
                      disabled={manipulationPermission}
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label=<Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      letterSpacing: "0.68px",
                      lineHeight: "24px",
                    }}
                    htmlFor="construction-checkbox"
                  >
                    Construction
                  </Typography>
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={buildingPlacardContractorCheckbox?.hvac || false}
                      onChange={handleChangeContractorChecbox}
                      name="hvac"
                      disabled={manipulationPermission}
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label=<Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      letterSpacing: "0.68px",
                      lineHeight: "24px",
                    }}
                  >
                    HVAC
                  </Typography>
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={
                        buildingPlacardContractorCheckbox?.electrical || false
                      }
                      onChange={handleChangeContractorChecbox}
                      name="electrical"
                      disabled={manipulationPermission}
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label=<Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      letterSpacing: "0.68px",
                      lineHeight: "24px",
                    }}
                  >
                    Electrical
                  </Typography>
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={
                        buildingPlacardContractorCheckbox?.plumbing || false
                      }
                      onChange={handleChangeContractorChecbox}
                      name="plumbing"
                      disabled={manipulationPermission}
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label=<Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      letterSpacing: "0.68px",
                      lineHeight: "24px",
                    }}
                  >
                    Plumbing
                  </Typography>
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={contractorCheckboxStyle}
                      checked={
                        buildingPlacardContractorCheckbox?.erosion || false
                      }
                      onChange={handleChangeContractorChecbox}
                      name="erosion"
                      disabled={manipulationPermission}
                      icon={
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="9.5"
                            fill="white"
                            stroke="#979797"
                          />
                        </svg>
                      }
                      checkedIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9L0 27C0 29.3869 0.948211 31.6761 2.63604 33.364C4.32387 35.0518 6.61305 36 9 36H27C29.3869 36 31.6761 35.0518 33.364 33.364C35.0518 31.6761 36 29.3869 36 27V9C36 6.61305 35.0518 4.32387 33.364 2.63604C31.6761 0.948211 29.3869 0 27 0H9ZM27.0077 13.4203C27.3279 13.0203 27.4761 12.5095 27.4197 12.0003C27.3632 11.491 27.1068 11.025 26.7069 10.7049C26.3069 10.3847 25.7961 10.2365 25.2868 10.2929C24.7776 10.3493 24.3116 10.6057 23.9914 11.0057L14.8783 22.4074L11.2294 19.6714C11.0268 19.5195 10.7963 19.4089 10.5509 19.3461C10.3056 19.2832 10.0503 19.2693 9.79954 19.3051C9.54883 19.3409 9.30762 19.4258 9.08969 19.5548C8.87176 19.6838 8.68139 19.8545 8.52943 20.0571C8.37747 20.2598 8.26691 20.4903 8.20405 20.7357C8.1412 20.981 8.12728 21.2363 8.1631 21.487C8.19891 21.7377 8.28376 21.979 8.4128 22.1969C8.54183 22.4148 8.71253 22.6052 8.91514 22.7571L14.058 26.6143C14.4588 26.9153 14.961 27.0485 15.4583 26.9856C15.9555 26.9228 16.4088 26.6688 16.722 26.2774L27.0077 13.4203Z"
                            fill="#040F25"
                          />
                        </svg>
                      }
                    />
                  }
                  label=<Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      letterSpacing: "0.68px",
                      lineHeight: "24px",
                    }}
                  >
                    Erosion Control
                  </Typography>
                />
              </FormGroup>
            </FormControl>
          </Box>

          {/* Project issued by box */}
          <Box>
            <Typography
              sx={{ ...buildingPlacardTypograpghy, color: "#212121" }}
            >
              Project
            </Typography>

            <TextField
              disabled
              // error={!buildingPlacardObject?.jobDescription && buttonSubmit}
              // helperText={
              //   !buildingPlacardObject?.jobDescription && buttonSubmit
              //     ? "Job description is required"
              //     : ""
              // }
              id="job-description"
              name="jobDescription"
              value={buildingPlacardData?.application?.jobDescription || ""}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                margin: 0, // Remove margin
                padding: 0, // Remove padding
                width: "100%", // Ensure full width
              }}
              onChange={handleChange}
              InputProps={{
                readOnly: manipulationPermission,
              }}
            />

            <TableContainer
              sx={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                // my: 2,
              }}
            >
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#212121",
                    }}
                  >
                    <TableCell sx={tableHeadCellStyle}>Issued To</TableCell>
                    <TableCell sx={tableHeadCellStyle} align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {/* owner name cell */}
                    <TableCell sx={tableCellDataStyle}>
                      <TextField
                        error={!issuedToObject?.ownerName && buttonSubmit}
                        helperText={
                          !issuedToObject?.ownerName && buttonSubmit
                            ? "Owner name is required"
                            : ""
                        }
                        id="owner-name"
                        name="ownerName"
                        placeholder="Owner (Agent)"
                        value={issuedToObject?.ownerName || ""}
                        sx={buildingPlacardContractorField}
                        onChange={(event) => handleIssuedToObjectChange(event)}
                        InputProps={{
                          readOnly: manipulationPermission,
                        }}
                      />
                    </TableCell>

                    {/* owner phone number cell */}
                    <TableCell sx={tableCellDataStyle}>
                      <TextField
                        error={
                          !issuedToObject?.ownerPhoneNumber && buttonSubmit
                        }
                        helperText={
                          !issuedToObject?.ownerPhoneNumber && buttonSubmit
                            ? "Owner phone number is required"
                            : ""
                        }
                        id="owner-phonenumber"
                        name="ownerPhoneNumber"
                        placeholder="Phone Number"
                        value={issuedToObject?.ownerPhoneNumber || ""}
                        sx={buildingPlacardContractorField}
                        onChange={(event) => handleIssuedToObjectChange(event)}
                        InputProps={{
                          readOnly: manipulationPermission,
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  {/* building site address row */}
                  <TableRow>
                    <TableCell colSpan={12} sx={tableCellDataStyle}>
                      <TextField
                        error={
                          !issuedToObject?.buildingSiteAddress && buttonSubmit
                        }
                        helperText={
                          !issuedToObject?.buildingSiteAddress && buttonSubmit
                            ? "Building site address is required"
                            : ""
                        }
                        id="building-site-address"
                        name="buildingSiteAddress"
                        placeholder="Building Site Address"
                        value={issuedToObject?.buildingSiteAddress || ""}
                        sx={buildingPlacardContractorField}
                        onChange={(event) => handleIssuedToObjectChange(event)}
                        InputProps={{
                          readOnly: manipulationPermission,
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  {/* city village town cell */}
                  <TableRow>
                    {/* <TableCell colSpan={12} sx={tableCellDataStyle}>
                    <TextField
                      id="seat-number"
                      name="cityVillageTown"
                      label="City, Village, Town"
                      value={issuedToObject?.cityVillageTown || ""}
                      sx={buildingPlacardContractorField}
                      onChange={(event) => handleIssuedToObjectChange(event)}
                    />
                  </TableCell> */}
                    <TableCell colSpan={12} sx={tableCellDataStyle}>
                      <FormControl
                        fullWidth
                        variant="filled"
                        error={
                          !issuedToObject?.cityVillageTownType && buttonSubmit
                        }
                      >
                        <InputLabel id="cityVillageTown-label">
                          City, Village, Town Type
                        </InputLabel>
                        <Select
                          readOnly={manipulationPermission}
                          labelId="cityVillageTown-label"
                          id="cityVillageTown"
                          name="cityVillageTownType"
                          value={
                            cityVillageTownArrayType.find(
                              (option) =>
                                option.id ===
                                issuedToObject?.cityVillageTownType * 1
                            )?.name || issuedToObject?.cityVillageTownType * 1
                          }
                          onChange={handleIssuedToObjectChange}
                          sx={villageCitySelector}
                        >
                          {cityVillageTownArrayType.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText>
                          {!issuedToObject?.cityVillageTownType && buttonSubmit
                            ? "Please select any one of them"
                            : ""}
                        </FormHelperText> */}
                      </FormControl>
                      <TextField
                        // error={!issuedToObject?.cityVillageTown && buttonSubmit}
                        // helperText={
                        // //   !issuedToObject?.cityVillageTown && buttonSubmit
                        //     ? "City, Village, Town is required"
                        //     : ""
                        // }
                        id="seat-number"
                        name="cityVillageTown"
                        placeholder="Enter Name of the above selected"
                        value={issuedToObject?.cityVillageTown}
                        sx={buildingPlacardContractorField}
                        onChange={(event) => handleIssuedToObjectChange(event)}
                        InputProps={{
                          readOnly: manipulationPermission,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box>
            <TableContainer
              sx={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                mt: 4,
              }}
            >
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#212121",
                    }}
                  >
                    <TableCell sx={tableHeadCellStyle}>Issued By</TableCell>
                    <TableCell sx={tableHeadCellStyle} align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {/* person issuing name */}
                    <TableCell sx={tableCellDataStyle}>
                      <TextField
                        error={
                          !issuedByObject?.personIssuingName && buttonSubmit
                        }
                        helperText={
                          !issuedByObject?.personIssuingName && buttonSubmit
                            ? "Person issuing name is required"
                            : ""
                        }
                        id="person-issuing-name"
                        name="personIssuingName"
                        placeholder="Person Issuing"
                        value={issuedByObject?.personIssuingName}
                        sx={buildingPlacardContractorField}
                        onChange={(event) => handleIssuedByObjectChange(event)}
                        InputProps={{
                          readOnly: manipulationPermission,
                        }}
                      />
                    </TableCell>

                    {/* certificate cell  */}
                    <TableCell sx={tableCellDataStyle}>
                      <TextField
                        error={
                          !issuedByObject?.certificateNumber && buttonSubmit
                        }
                        helperText={
                          !issuedByObject?.certificateNumber && buttonSubmit
                            ? "Certificate number is required"
                            : ""
                        }
                        id="certtificate-number"
                        name="certificateNumber"
                        placeholder="Cert. No."
                        value={issuedByObject?.certificateNumber || ""}
                        sx={{
                          ...buildingPlacardContractorField,
                          ".MuiInputBase-input": {
                            textTransform: "uppercase",
                          },
                        }}
                        onChange={(event) => handleIssuedByObjectChange(event)}
                        InputProps={{
                          readOnly: manipulationPermission,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {/* date issue field cell */}
                    <TableCell sx={tableCellDataStyle}>
                      <TextField
                        error={!issuedByObject?.dateIssued && buttonSubmit}
                        helperText={
                          !issuedByObject?.dateIssued && buttonSubmit
                            ? "Date issued is required"
                            : ""
                        }
                        id="date-issued"
                        name="dateIssued"
                        placeholder="Date issued"
                        label="Date issued"
                        inputProps={{
                          max: "9999-12-31",
                        }}
                        value={issuedByObject?.dateIssued.split("T")[0]}
                        sx={{
                          ...buildingPlacardTextfieldDate,
                          width: "100%",
                          borderRadius: 0,
                        }}
                        type="date"
                        onChange={(event) => handleIssuedByObjectChange(event)}
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: manipulationPermission,
                        }}
                      />
                    </TableCell>
                    <TableCell sx={tableCellDataStyle}>
                      <TextField
                        error={!issuedByObject?.phoneNumber && buttonSubmit}
                        helperText={
                          !issuedByObject?.phoneNumber && buttonSubmit
                            ? "Phone number is required"
                            : ""
                        }
                        id="seat-number"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        value={issuedByObject?.phoneNumber || ""}
                        sx={buildingPlacardContractorField}
                        onChange={(event) => handleIssuedByObjectChange(event)}
                        InputProps={{
                          readOnly: manipulationPermission,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>

        <CardActions
          sx={{
            mt: 1,
            ml: 0,
            mr: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
            "& > :not(:first-of-type)": {
              m: 0,
            },
            flexWrap: "wrap",
            [`@media screen and (max-width: 1024px)`]: {
              justifyContent: "center",
            },
          }}
        >
          {/* email button */}
          <Button
            sx={{
              ...buildingPlacardDownloadButton,
              width: "286px",
              backgroundColor: "#32363F",
              [`@media screen and (max-width: 1024px)`]: {
                width: "100%",
              },
              "&:disabled": {
                color: "white",
              },
            }}
            disabled={manipulationPermission}
            onClick={handleSendEmailToApplicant}
          >
            Email to Applicant
            {emailButtonLoader ? <CircularProgressLoader /> : ""}
          </Button>

          {/* pdf download button */}
          {loaderButton === false ? (
            <Button
              sx={{
                ...buildingPlacardDownloadButton,
                width: "286px",
                backgroundColor: "#32363F",
                [`@media screen and (max-width: 1024px)`]: {
                  width: "100%",
                },
                "&:disabled": { color: "white" },
              }}
              disabled={manipulationPermission}
              onClick={() => {
                fireToasterContext.fireToasterHandler(
                  false,
                  "Please save information first"
                );
              }}
            >
              DownLoad Building Placard
            </Button>
          ) : (
            <PDFDownloadLink
              document={<BuildingPlacardPdf data={buildingPlacardData} />}
              fileName={`Building-Placard-Detail-${
                buildingPlacardData?.siteInfo?.id
              }-${Date.now()}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                sx={{
                  ...buildingPlacardDownloadButton,
                  width: "286px",
                  backgroundColor: "#32363F",
                  [`@media screen and (max-width: 1024px)`]: {
                    width: "100%",
                  },
                }}
              >
                DownLoad Building Placard
              </Button>
            </PDFDownloadLink>
          )}

          <Button
            disabled={btnLoading || manipulationPermission}
            sx={{
              ...buildingPlacardFormSubmitButton,
              [`@media screen and (max-width: 1024px)`]: {
                width: "100%",
              },
              backgroundColor: btnLoading
                ? "grey"
                : manipulationPermission || "#d52027",
              ":disabled": {
                color: "white",
                backgroundColor: manipulationPermission ? "#d52027" : "gray",
              },
            }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
            {btnLoading ? <CircularProgressLoader /> : ""}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default BusinessPlacard;
