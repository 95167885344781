import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  InputBase,
  Typography,
  Grid,
  TableCell,
  Box,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Paper,
  TableRow,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import Loader from "../Loader/Loader";
import { DeleteOutline, EditOutlined, Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "../../redux/store";
import CustomPagination from "../../hooks/useTablePagination";
import {
  tableAvatar,
  tableAvatarCell,
  tableCellBackgroundColor,
  // tableIconBox,
  // tableIconButton,
} from "./muiUserComponentStyles";
import {
  StyledTableRow,
  miniScrollBarStyles,
  rolesTableChip,
  styledTableCell,
  tableBodyTypos,
  tableLoaderBox,
  userHeading,
} from "../../utils/muiComponentStyles";
import { getUsersList, updateUserStatus } from "../../redux/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import ToasterContext from "../../utils/context/toasterContext";
import DeleteUserDialog from "./DeleteUserDialog";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  // const [rowId, setRowId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [rowId, setRowId] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredList, setFilteredList] = useState([]);
  const [toBeSearched, setToBeSearched] = useState("");
  const fireToasterContext = useContext(ToasterContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    dispatch(getUsersList(handleApiRes));
  }, []);
  let { usersList } = useSelector((state) => state.userReducer);

  useEffect(() => {
    setFilteredList(usersList);
  }, [usersList]);

  const handleApiRes = (response) => {
    if (response.status) {
      setLoading(false);
    }
  };
  const handleUpdateStatusApiRes = (response) => {
    if (response.status) {
      fireToasterContext.fireToasterHandler(true, response.message);
    } else {
      fireToasterContext.fireToasterHandler(false, response.message);
    }
  };

  const handleMenuClick = (event, id) => {
    setRowId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleStatusChange = (status) => {
    dispatch(updateUserStatus(rowId, status, handleUpdateStatusApiRes));
  };

  const filterUsers = (users, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return users.filter((user) => {
      const { firstName, lastName, phoneNumber, roles } = user;
      let fullName = firstName + " " + lastName;
      return (
        fullName.toLowerCase().includes(lowerCaseSearchTerm) ||
        phoneNumber.toLowerCase().includes(lowerCaseSearchTerm) ||
        roles.some((role) =>
          role.name.toLowerCase().includes(lowerCaseSearchTerm)
        )
      );
    });
  };

  // Filter the original list of users (filteredList) based on the search term (toBeSearched)
  const filteredData = filterUsers(filteredList, toBeSearched);

  const handleCreateNewUser = () => {
    navigate("/users/create-new-user");
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditUser = (data) => {
    localStorage.setItem("selectedUser", JSON.stringify(data));
    navigate("/users/update-user-details");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleDeleteUser = (data) => {
    setSelectedUser(data);
    setOpenModal(true);
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
    setOpenModal(false);
  };

  return (
    <Box>
      {openModal && (
        <DeleteUserDialog
          selecteduser={selectedUser}
          openDeleteDialog={openModal}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      <Grid spacing={2} container sx={{ my: 2 }}>
        <Grid item xs={12} md={8} sm={8} lg={10}>
          <InputBase
            disabled={loading}
            onChange={(e) => setToBeSearched(e.target.value)}
            sx={{
              width: "100%",
              height: "38px",
              border: "1px solid rgba(169, 169, 169, 0.6)",
              borderRadius: 2,
              backgroundColor: "background.bgSearchBar",
              fontFamily: "Poppins",
            }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            startAdornment={
              <Search
                sx={{
                  color: "rgba(151, 151, 151, 0.68);",
                  paddingLeft: "3px",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={4} sm={4} lg={2}>
          <Button
            disabled={loading}
            onClick={handleCreateNewUser}
            disableRipple
            sx={{
              backgroundColor: "background.bgLayoutB",
              width: "100%",
              borderRadius: "10px",
              "&:hover": { backgroundColor: "background.bgLayoutB" },
              "&:disabled": {
                backgroundColor: "rgba(224, 224, 224, 1)",
              },
            }}
          >
            <Typography sx={userHeading} variant="userHeadingBoldButton">
              Create user
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          maxHeight: 640,
          borderRadius: "10px",
          [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
          ...miniScrollBarStyles,
        }}
      >
        <Table
          sx={{ overflowX: "auto" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead sx={tableCellBackgroundColor}>
            <TableRow>
              <TableCell sx={tableCellBackgroundColor}>
                <Typography sx={userHeading} variant="userTableHeadingBold">
                  Name
                </Typography>
              </TableCell>

              <TableCell sx={{ ...tableCellBackgroundColor, width: "300px" }}>
                <Typography sx={userHeading} variant="userTableHeadingBold">
                  Phone Number
                </Typography>
              </TableCell>
              <TableCell sx={tableCellBackgroundColor}>
                <Typography sx={userHeading} variant="userTableHeadingBold">
                  Roles
                </Typography>
              </TableCell>
              <TableCell sx={tableCellBackgroundColor}>
                <Typography sx={userHeading} variant="userTableHeadingBold">
                  Muncipalities
                </Typography>
              </TableCell>
              <TableCell sx={tableCellBackgroundColor}>
                <Typography sx={userHeading} variant="userTableHeadingBold">
                  Status
                </Typography>
              </TableCell>
              <TableCell sx={tableCellBackgroundColor}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell align="right" sx={styledTableCell}>
                  {" "}
                  <Box sx={{ ...tableLoaderBox, justifyContent: "flex-end" }}>
                    <Loader />
                  </Box>
                </TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
              </TableRow>
            ) : filteredData.length < 1 ? (
              <TableRow>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styledTableCell, width: "30%" }}
                >
                  <Typography sx={tableBodyTypos} variant="tableBodyCellName">
                    No users found
                  </Typography>
                </TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
                <TableCell
                  sx={styledTableCell}
                  component="th"
                  scope="row"
                ></TableCell>
              </TableRow>
            ) : (
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow hover key={row.id}>
                    <TableCell sx={styledTableCell} component="th" scope="row">
                      <Box sx={tableAvatarCell}>
                        <Avatar sx={tableAvatar}>
                          {row?.firstName.slice(0, 1).toUpperCase()}
                        </Avatar>
                        <Typography
                          sx={tableBodyTypos}
                          variant="tableBodyCellName"
                        >{`${row.firstName} ${row.lastName}`}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={styledTableCell} component="th" scope="row">
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row.phoneNumber}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ ...styledTableCell, width: 220 }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row?.roles.map((role, idx) => (
                          <Box key={idx} sx={rolesTableChip}>
                            {role.name}
                          </Box>
                        ))}
                      </Typography>
                    </TableCell>
                    <TableCell sx={styledTableCell} component="th" scope="row">
                      <Typography
                        sx={tableBodyTypos}
                        variant="tableBodyCellName"
                      >
                        {row?.municipalities?.map((muni, idx) => (
                          <Box key={idx} sx={rolesTableChip}>
                            {muni.name}
                          </Box>
                        ))}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={styledTableCell}>
                      <Button
                        disableRipple
                        variant="outlined"
                        onClick={(e) => handleMenuClick(e, row.id)}
                        sx={{ borderRadius: "10px", width: 100 }}
                      >
                        <Typography
                          sx={tableBodyTypos}
                          variant="tableBodyCellName"
                        >
                          {row?.active === true ? "active" : "inActive"}
                        </Typography>
                      </Button>
                    </TableCell>
                    <TableCell
                      sx={styledTableCell}
                      component="th"
                      scope="row"
                      align="right"
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton
                          sx={{
                            "&.MuiButtonBase-root": {
                              backgroundColor: "background.bgLayoutA",
                              color: "white",
                              height: "36px",
                              width: "36px",
                            },
                          }}
                          onClick={() => {
                            handleEditUser(row);
                          }}
                        >
                          <EditOutlined />
                        </IconButton>
                        <IconButton
                          sx={{
                            "&.MuiButtonBase-root": {
                              backgroundColor: "background.bgLayoutB",
                              color: "white",
                              height: "36px",
                              width: "36px",
                            },
                          }}
                          onClick={() => handleDeleteUser(row)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </span>
                    </TableCell>
                    {/* <TableCell
                      align="right"
                      sx={{
                        ...styledTableCell,
                      }}
                    >
                      <Box sx={tableIconBox}>
                        <IconButton
                          sx={tableIconButton}
                          onClick={() => {
                            handleEditUser(row);
                          }}
                        >
                          <EditOutlined sx={{ color: "white" }} />
                        </IconButton>
                      </Box>
                      <Box sx={tableIconBox}>
                        <IconButton
                          sx={tableIconButton}
                          onClick={() => {
                            handleDeleteUser(row);
                          }}
                        >
                          <DeleteOutline sx={{ color: "white" }} />
                        </IconButton>
                      </Box>
                    </TableCell> */}
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <MenuItem onClick={() => handleStatusChange("true")}>
          <Typography sx={tableBodyTypos} variant="tableBodyCellName">
            activate
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleStatusChange("false")}>
          <Typography sx={tableBodyTypos} variant="tableBodyCellName">
            deactivate
          </Typography>
        </MenuItem>
      </Menu>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};

export default Index;
