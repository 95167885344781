import React from "react";
import Index from "../components/applicationRecord/RecordInspection/Index";
const RecordInspection = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default RecordInspection;
