import React, { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import AppRouter from "./routes/index";
import "react-toastify/dist/ReactToastify.css";
import ThemeContext from "./utils/themeContext";
import { ToastContainer, toast } from "react-toastify";
import ToasterContext from "./utils/context/toasterContext";
import { darkTheme, lightTheme } from "./utils/muiDualTheme";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

const App = () => {
  const [loader, showLoader] = useState(false);
  const [showToaster, setShowToaster] = useState();
  const themeMode = localStorage.getItem("themeMode");
  const [isDarkTheme, setIsDarkTheme] = useState(themeMode);
  useEffect(() => {
    setIsDarkTheme(themeMode);
  }, []);
  const setTheme = () => {
    if (themeMode == "true") {
      localStorage.setItem("themeMode", false);
      setIsDarkTheme(false);
    } else {
      localStorage.setItem("themeMode", true);
      setIsDarkTheme(true);
    }
  };
  const toastOption = {
    autoClose: 10000,
    position: "top-right",
    closeOnClick: true,
    hideProgressBar: false,
    pauseOnHover: true,
    progress: undefined,
    draggable: true,
    theme: "colored",
  };
  useEffect(() => {
    localStorage.removeItem("permissions");
    async function permissionsData() {
      if (localStorage.getItem("token")) {
        try {
          showLoader(true);
          const permissionsResponse = await axios.get(
            process.env.REACT_APP_HOST_API_KEY +
              "v1/permissions/permissions-for-loggedin-user",
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          localStorage.setItem("permissions", permissionsResponse.data.data);
          if (
            permissionsResponse.data.data.length < 1 &&
            location.pathname === "/404"
          ) {
            window.location.href = "/login";
          }
          showLoader(false);
        } catch (error) {
          showLoader(false);
          localStorage.removeItem("token");
          localStorage.removeItem("permissions");
          window.location.href = "/login";
        }
      }
    }
    permissionsData();
  }, []);
  const fireToasterHandler = (value, message) => {
    if (value) {
      toast.success(message, toastOption);
    } else {
      toast.error(message, toastOption);
    }
    setShowToaster(value);
  };
  return (
    <div className="App">
      {loader ? null : (
        <>
          <ThemeProvider
            theme={
              isDarkTheme == true || isDarkTheme == "true"
                ? createTheme(darkTheme)
                : createTheme(lightTheme)
            }
          >
            <CssBaseline />
            <ToastContainer />
            <ThemeContext.Provider
              value={{
                isDarkTheme: isDarkTheme,
                setTheme: setTheme,
              }}
            >
              <ToasterContext.Provider
                value={{
                  isSuccess: showToaster,
                  fireToasterHandler: fireToasterHandler,
                }}
              >
                <AppRouter />
              </ToasterContext.Provider>
            </ThemeContext.Provider>
          </ThemeProvider>
        </>
      )}
    </div>
  );
};
export default App;
