import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Roles from "../pages/Roles";
import Login from "../pages/auth/Login";
import PublicRoute from "./PublicRoute";
import AllUsers from "../pages/users/AllUsers";
import NotMatch from "../pages/NotMatch";
import Dashboard from "../pages/Dashboard";
import AddUser from "../pages/users/AddUser";
import ProtectedRoute from "./ProtectedRoute";

import Contractors from "../pages/contractor/Contractors";
import EditContractor from "../pages/contractor/EditContractor";
import AddContractor from "../pages/contractor/addContractor";
import Municipalities from "../pages/Municipalities";
import ApplicationForm from "../pages/ApplicationForm";
import RolesPermissions from "../pages/RolesPermissions";
import UpdateUser from "../pages/users/UpdateUserDetails";
import Reports from "../pages/Reports";
import UserRolesPermissions from "../pages/UserRolesPermissions";
import Applicants from "../pages/applicants";
import ApplicationDetail from "../pages/AppicationDetail";
import RecordInspection from "../pages/RecordInspection";
import Media from "../pages/Media";
import BuildingPlacard from "../pages/BuildingPlacard";
import OccupencyLetter from "../pages/OccupencyLetter";
import PermitFee from "../pages/PermitFee";
import PaymentDone from "../pages/PaymentDone";
import EmailTracked from "../pages/EmailTracked";

const index = () => {
  const permissions = localStorage.getItem("permissions");
  const permissionArray = permissions ? permissions.split(",") : [];
  return (
    <Router>
      <Routes>
        <Route path="/404" element={<NotMatch />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
        <Route exact path="/" element={<ProtectedRoute />}>
          {permissionArray.includes("show dashboard") && (
            <Route path="/" element={<Dashboard />} />
          )}
          {permissionArray.includes("show contractors") && (
            <>
              <Route path="/contractors" element={<Contractors />} />
              <Route
                path="/contractors/update-contractors-details"
                element={<EditContractor />}
              />
              <Route
                path="/contractors/add-new-contractor"
                element={<AddContractor />}
              />
            </>
          )}
          {permissionArray.includes("show applications") && (
            <Route path="/applications" element={<Applicants />} />
          )}
          {permissionArray.includes("show municipalities") && (
            <Route path="/municipalities" element={<Municipalities />} />
          )}
          {/* {permissionArray.includes("manipulate application details") && ( */}
          <>
            <Route
              path="/applications/application-detail"
              element={<ApplicationDetail />}
            />
            <Route
              path="/applications/record-inspections"
              element={<RecordInspection />}
            />
            <Route path="/applications/media" element={<Media />} />
            <Route
              path="/applications/building-placard"
              element={<BuildingPlacard />}
            />
            <Route
              path="/applications/occupency-letter"
              element={<OccupencyLetter />}
            />
            <Route path="/applications/permit-fee" element={<PermitFee />} />
            <Route
              path="/applications/payment-mode"
              element={<PaymentDone />}
            />
            <Route
              path="/applications/email-tracked"
              element={<EmailTracked />}
            />
          </>
          {/* )} */}
          {permissionArray.includes("show monthly reports") && (
            <Route path="/reports" element={<Reports />} />
          )}

          <Route path="/users" element={<AllUsers />} />
          <Route path="/users/create-new-user" element={<AddUser />} />
          <Route path="/users/update-user-details" element={<UpdateUser />} />

          <Route path="/roles" element={<Roles />} />
          <Route path="/roles-permissions" element={<RolesPermissions />} />
          <Route path="/user-roles" element={<UserRolesPermissions />} />

          {/* <Route path="/404" element={<NotMatch />} />
          <Route path="*" element={<Navigate replace to="/404" />} /> */}
        </Route>
        <Route exact path="/" element={<PublicRoute />}>
          <Route exact path="/application-form" element={<ApplicationForm />} />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default index;
