import React from "react";
import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import poppinsRegular from "../../../assets/fonts/Poppins-Regular.ttf";
import poppinsBold from "../../../assets/fonts/Poppins-Bold.ttf";
import poppinsMedium from "../../../assets/fonts/Poppins-SemiBold.ttf";
import logo from "../../../assets/icons/logo.png";

// ** custom fonts
Font.register({
  family: "PoppinsFamily",
  fonts: [
    {
      src: poppinsRegular,
    },
    {
      src: poppinsBold,
      fontWeight: "bold",
    },
    { src: poppinsMedium, fontWeight: "semibold" },
  ],
});

const projectTypeList = [
  { id: 1, name: "New Building" },
  { id: 2, name: "Addition" },
  { id: 3, name: "Alteration/Repair" },
  { id: 4, name: "Deck" },
  { id: 5, name: "Demolition" },
  { id: 6, name: "Driveway" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Pool" },
];

// ** Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    paddingBottom: 65,
    paddingHorizontal: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  minContainer: {
    margin: "30",
  },
  mainViewTag: {
    margin: "30px",
  },
  heading: {
    fontFamily: "PoppinsFamily",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#000000",
    margin: "20",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableView: {
    width: "100%",
    border: "1px solid black",
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
  },
  headingText1st: {
    width: "80%",
    padding: "5px",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  headingText2nd: {
    width: "20%",
    padding: "5px",
    // borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  tableBody: {
    width: "100%",
  },
  tableRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  pdfText: {
    fontFamily: "PoppinsFamily",
    fontSize: "12px",
  },
  headingContainer: {
    textAlign: "center",
  },

  subjectSection: {
    textAlign: "left",
    marginTop: "8px",
    marginBottom: "5px",
  },

  fontFamilyStyleBold: {
    fontFamily: "PoppinsFamily",
    fontWeight: "bold",
    fontSize: "14px",
  },
  fontFamilyStyle: {
    fontFamily: "PoppinsFamily",
    fontSize: "10px",
  },
});

// ** Create Document Component
const PermitFeePdf = ({ data }) => {
  const getCurrentFeeForAccumolation = (fee) => {
    if (fee?.manualFee * 1 && fee?.manualFee * 1 > 0) {
      return fee.manualFee * 1;
    } else {
      return 0;
    }
  };

  const getTotalFee = () => {
    // let selectedApplicationInfo = JSON.parse(
    //   localStorage.getItem("selectedApplication")
    // );
    const totalFee = data.reduce(
      (acc, fee) =>
        fee.checked ? acc + getCurrentFeeForAccumolation(fee) : acc,
      0
    );
    return Math.round(totalFee);
  };

  function calculatePayPalCharge(amount) {
    const fixedFee = 0.49; // Fixed fee per transaction
    const rateFee = 0.0299; // Rate fee percentage
    // Calculate the amount to charge
    const chargeAmount = (amount + fixedFee) / (1 - rateFee);
    return chargeAmount.toFixed(2); // Return the result rounded to two decimal places
  }

  return (
    <Document pageMode="fullScreen" pageLayout="oneColumn">
      <Page size="A4" style={styles.page}>
        {/* main container */}
        <View style={styles.mainViewTag}>
          <View style={styles.heading}>
            <Image src={logo} style={{ height: "50px", width: "70px" }} />
            <Text>Application Permit Fee</Text>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <View>
              <Text style={styles.fontFamilyStyleBold}>Permit Number</Text>
              <Text style={styles.fontFamilyStyle}>
                {
                  JSON.parse(localStorage.getItem("selectedApplication"))
                    ?.permitNumber
                }
              </Text>
            </View>
            <View>
              <Text style={styles.fontFamilyStyleBold}>
                Date: {""} {""}{" "}
                {
                  JSON.parse(
                    localStorage.getItem("selectedApplication")
                  )?.createdAt.split("T")[0]
                }
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                padding: "10px",
                width: "100%",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <View style={{ width: "50%" }}>
                <Text style={styles.fontFamilyStyleBold}>Project Type</Text>
                <Text style={styles.fontFamilyStyle}>
                  {
                    projectTypeList.find(
                      (project) =>
                        project.id ===
                        JSON.parse(localStorage.getItem("selectedApplication"))
                          ?.projectType
                    )?.name
                  }
                </Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.fontFamilyStyleBold}>Project Address</Text>
                <Text style={styles.fontFamilyStyle}>
                  {
                    JSON.parse(localStorage.getItem("selectedApplication"))
                      ?.projectAddress
                  }
                </Text>
              </View>
            </View>
            <View
              style={{
                padding: "10px",
                width: "100%",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <View style={{ width: "50%" }}>
                <Text style={styles.fontFamilyStyleBold}>Applicant Name</Text>
                <Text style={styles.fontFamilyStyle}>
                  {
                    JSON.parse(localStorage.getItem("selectedApplication"))
                      ?.applicantName
                  }
                </Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.fontFamilyStyleBold}>Applicant Email</Text>
                <Text style={styles.fontFamilyStyle}>
                  {
                    JSON.parse(localStorage.getItem("selectedApplication"))
                      ?.applicantEmail
                  }
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: "100%" }}>
            <View style={styles.tableView}>
              <View style={styles.tableHead}>
                <Text
                  style={{
                    ...styles.headingText1st,
                    ...styles.pdfText,
                    fontWeight: "bold",
                  }}
                >
                  Fee Name
                </Text>
                <Text
                  style={{
                    ...styles.headingText2nd,
                    ...styles.pdfText,
                    fontWeight: "bold",
                  }}
                >
                  Fee
                </Text>
              </View>
              <View style={styles.tableBody}>
                {data
                  .filter((el) => el.checked)
                  .map((el) => (
                    <View key={el.checked} style={styles.tableRow}>
                      <Text
                        style={{ ...styles.headingText1st, ...styles.pdfText }}
                      >
                        {el?.name}
                      </Text>
                      <Text
                        style={{ ...styles.headingText2nd, ...styles.pdfText }}
                      >
                        $
                        {el?.manualFee * 1
                          ? (el?.manualFee * 1)?.toFixed(2)
                          : 0}
                      </Text>
                    </View>
                  ))}
                <View style={styles.tableRow}>
                  <Text
                    style={{
                      ...styles.headingText1st,
                      ...styles.pdfText,
                      fontWeight: "bold",
                    }}
                  >
                    Total Permit Fee (paid via cash or check)
                  </Text>
                  <Text
                    style={{
                      ...styles.headingText2nd,
                      ...styles.pdfText,
                      fontWeight: "bold",
                    }}
                  >
                    ${Math.round(getTotalFee() * 1)?.toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableRow}>
                  <Text
                    style={{
                      ...styles.headingText1st,
                      ...styles.pdfText,
                      // fontWeight: "bold",
                    }}
                  >
                    Processing fee (if payment via credit/debit card or PayPal)
                  </Text>
                  <Text
                    style={{
                      ...styles.headingText2nd,
                      ...styles.pdfText,
                      // fontWeight: "bold",
                    }}
                  >
                    $
                    {(
                      calculatePayPalCharge(getTotalFee()) - getTotalFee()
                    ).toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableRow}>
                  <Text
                    style={{
                      ...styles.headingText1st,
                      ...styles.pdfText,
                      fontWeight: "bold",
                      fontSize: 13,
                    }}
                  >
                    Total Fee inclusive of total permit fees and processing fees
                    (if payment via credit/debit card or PayPal)
                  </Text>
                  <Text
                    style={{
                      ...styles.headingText2nd,
                      ...styles.pdfText,
                      fontWeight: "bold",
                      fontSize: 13,
                    }}
                  >
                    ${calculatePayPalCharge(getTotalFee())}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
export default PermitFeePdf;
