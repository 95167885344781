import React, { useEffect, useState } from "react";
import Index from "../components/applicationForm/Index";
import Cautionary from "../pages/Cautionary";

const Role = () => {
  const [isCautionary, setIsCautionary] = useState(true);
  const handleAgreeButton = () => {
    setIsCautionary(false);
    window.scroll(0, 0);
  };

  useEffect(() => {
    setIsCautionary(true);
  }, []);

  return (
    <>
      {isCautionary ? (
        <Cautionary handleAgreeButton={handleAgreeButton} />
      ) : (
        <Index />
      )}
    </>
  );
};

export default Role;
