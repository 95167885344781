const nl = {
  // sidenav menu
  groupPermissions: `Groepsmachtigingen`,
  userGroup: `Gebruikers groep`,
  dashboard: `Dashboard`,
  // alarms: `Alarmen`,
  alarms: `Waarschuwingen`,
  rooms: `Kamers`,
  // procedures: `werkwijze`,
  procedures: `Evenementen`,
  // templates: `Sjablonen`,
  templates: `Evenement-sjablonen`,
  devices: `Apparaten`,
  devicesList: `Apparaten lijst`,
  inputs: `Invoer apparaten`,
  outputs: `Uitvoerapparaten`,
  categories: `Categorieën`,
  // report keywords
  logs: `Geschiedenis`,
  history: `Geschiedenis`,
  generateReport: `Rapporten genereren`,
  alertsdemo: `Waarschuwingen demo`,
  profiles: `Steder`,
  profile: `Steder`,
  license: `Licentie`,
  users: `Gebruikers`,
  logout: `Uitloggen`,
  reports: `rapporten`,
  busyMode: `Drukke modus`,
  contactType: "Contact Type",
  busyModeTime: `Tijd in bezetmodus`,
  selectedLocations: `Geselecteerde Locaties`,

  // Routes changing keywords
  eventtemplates: `Evenementen Sjablonen`,
  inputdevices: `Invoer apparaten`,
  outputdevices: `Uitvoerapparaten`,
  historyreports: `Geschiedenis rapporten`,
  generatereports: `Rapporten genereren`,
  alertdemo: `Alert-demo`,
  locations: `Locaties`,
  groupspermissions: `GROEPEN TOESTEMMINGEN`,
  usergroups: `gebruikersgroepen`,
  alerts: `Waarschuwingen`,
  allusers: `Alle gebruikers`,
  userRoles: `Gebruikersrollen`,
  userroles: `Gebruikersrollen`,

  // generics keywords
  name: `Naam`,
  room: `Kamer`,
  selectAll: `Selecteer alles`,
  selectedInputs: `Geselecteerde ingangen:`,
  selectedOutputs: `geselecteerde uitgangen:`,
  note: `Opmerking:`,
  areYouSure: `Weet je zeker dat je wilt verwijderen`,
  cancel: `Annuleren`,
  event: `Evenement`,

  // dashboard Keywords for changing
  welcome: `Welkom bij`,
  projectName: `Soft Circles Template`,

  // dashboard cards keywords for changing
  firstCard: `First Card`,
  secondCard: `Tweede kaart`,
  thirdCard: `Vierde kaart`,
  fourthCard: `Vierde kaart`,
  fifthCard: `Vijfde kaart`,
  sixthCard: `Zesde kaart`,

  // alarms table keywords for changing
  // generateAlarm: `Genereer alarmen`,
  generateAlarm: `Alarm genereren`,
  noRoomAlarm: `Er zijn geen kameralarmprocedures beschikbaar`,
  activeDay: `Actieve dag`,
  activeNight: `Actieve nacht`,
  procedureActive: `Werkwijze is actief`,
  noAlarmProcedure: `Er is geen alarmprocedure beschikbaar`,
  clearAlarms: `Wis alarmen`,
  updateProcedure: `Procedure-instelling bijwerken`,

  // logs page changing keywords
  searchLog: `Zoeken...`,
  startDate: `Begin datum`,
  startTime: `Starttijd`,
  endDate: `Eind datum`,
  endTime: `Eindtijd`,
  search: `Zoeken`,
  date: `Datum`,
  text: `Tekst`,
  category: `Categorie`,
  generalLogs: `Algemene logboeken`,
  roomLogs: `Kamer logboeken`,
  alertReceivedLogs: `Meld ontvangen logboeken`,
  lockLogs: `Logboeken vergrendelen`,
  result: `Resultaat`,
  helpLogs: `Help-logboeken`,

  // procedures page changing keyword
  searchProcedure: `Zoeken...`,
  // createProcedure: `Procedure maken`,
  createProcedure: `Maak evenement aan`,
  noProcedure: `Geen procedure beschikbaar`,

  // create procedure file changes keywords
  createProcedureTitle: `Create Procedure`,
  alarmSound: `Alarm geluid`,
  alarmIntensity: `Alarmintensiteit`,
  audioProcedure: `Audio Procedure`,
  selectedAllInput: `Alle ingangen geselecteerd:`,
  selectedAllOutput: `Alle uitgangen geselecteerd:`,
  rules: `Reglement`,
  start: `Begin`,
  end: `Einde`,
  smsNotification: `SMS-melding`,
  whatsappNotification: `Whatsapp-melding`,
  emailNotification: `E-mail notificatie`,
  sendNotificationAfter: "Melding verzenden na",
  // edit procedure file changes keywords
  // editProcedure: `Procedure bewerken`,
  editProcedure: `Evenement bewerken`,
  saveProcedure: `Evenement opslaan`,

  // delete procedure file changes keywords
  // deleteProcedure: `Procedure verwijderen`,
  deleteProcedure: `Evenement verwijderen`,

  // profile page chenging keywords
  searchProfile: `Zoeken...`,
  createProfile: `Maak een profiel aan`,
  createLocation: `Locatie maken`,
  dayToNight: `Dag tot nacht`,
  nightToDay: `Nacht tot dag`,
  noProfile: `Geen Steder beschikbaar`,
  parentProfileId: "Bovenliggende locatie",
  escalationGroup: `Escalatie groep`,
  groupEscalationTimeInMinutes: `Escalatie tijd`,
  addEscalationGroup: `Escalatiegroep toevoegen`,
  removeEscalationGroup: `Escalatiegroep verwijderen`,

  // create profile page changing keywords
  selectedRooms: "Geselecteerde kamers:",
  dayToNightTime: `Dag tot nacht tijd`,
  nightToDayTime: `Nacht naar dag tijd`,
  secondryEscalationProfileId: `
  Tecundair escalatieStederId`,
  tertiaryEscalationProfileId: `
  Tertiair escalatieStederId`,
  secondryEscalationTimeInMinutes: `
  Escalatie tijd`,
  tertiaryEscalationTimeInMinutes: `
  Escalatie tijd`,
  selectedUsers: `geselecteerde gebruikers`,

  // edit profile page changing keywords
  editProfile: "Bewerk profiel",
  saveLocation: "Locatie opslaan",
  editLocation: "Locatie bewerken",
  selectRooms: `Selecteer Kamers`,

  // delete profile page changing keywords
  deleteProfile: "Verwijder profiel",
  deleteLocation: "Locatie verwijderen",
  deleteLocationOf: "Locatie van verwijderen",
  high: "Hoog",
  medium: `Medium`,
  low: `Laag`,

  // room page list
  noRoom: `geen kamer beschikbaar`,
  searchRoom: `Zoeken...`,
  createRoom: `Ruimte creëren`,
  createroom: `Ruimte creëren`,
  rtspURL: `Rtsp-URL`,
  alarmURL: `Alarm-URL`,

  // create room changing keywords
  cameraViewAddress: `Adres cameraweergave`,
  doorTopic: `Deur onderwerp`,
  doorPayload: `Laadvermogen deur`,
  timeTriggerEnabled: `Tijdtrigger ingeschakeld`,
  timeTriggerTime: `Trigger-tijd`,
  timeTriggerTrigger: `Triggernaam`,
  audioSetupEnabled: `Audio-instellingen ingeschakeld`,
  cameraIpAddress: `het IP-adres van de camera`,
  cameraUsername: `camera gebruikersnaam`,
  cameraPassword: `camera wachtwoord`,
  selectedProfiles: `Geselecteerde locaties`,
  escalationTime: "Escalatie tijd",

  // update room file changing keywords
  editRoom: `Kamer bewerken`,
  saveRoom: `Veilige kamer`,

  // delete room changing keywords
  deleteRoom: `Kamer verwijderen`,

  // device listing page changing keywords
  searchDevices: `zoeken...`,
  createDevice: `Apparaat maken`,
  deviceType: `Soort apparaat`,
  noDeviceAvailable: `Geen apparaat beschikbaar`,

  // create device file changing keywords
  type: `Type`,
  ipAddress: `IP adres`,
  userName: `gebruikersnaam`,
  password: `Wachtwoord`,
  topic: `Onderwerp`,

  // edit device file changing keywords
  editDevice: `Apparaat bewerken`,
  saveDevice: `Apparaat opslaan`,

  // delete device file changing keywords
  deleteDevice: `Apparaat verwijderen`,

  // inputs file changing keywords
  searchInputs: `Zoeken...`,
  createInput: `invoerapparaat maken`,
  triggers: `Triggers`,
  noInput: `Geen invoer beschikbaar`,

  // create inputs changing keywords
  trigger: `Trigger`,
  device: `Apparaat`,

  // edit input file changing keywords
  editInput: `invoerapparaat bewerken`,
  saveInput: `Sla invoerapparaat op`,

  // delete input file changing keywords
  deleteInput: `invoerapparaat verwijderen`,

  // output file changing keywords
  searchOutput: `Zoeken...`,
  createOutput: `Uitvoerapparaat maken`,
  noOutput: `Geen uitvoer beschikbaar`,

  // edit output file changing keywords
  // edit output name change into edit output device
  // editOutput: `Uitvoer bewerken`,
  editOutput: `uitvoerapparaat bewerken`,
  saveOutput: `Sla uitvoerapparaat op`,

  // delete output file changing keywords
  // deleteOutput: `Uitvoer verwijderen`,
  deleteOutput: `Uitvoerapparaat verwijderen`,

  // category file changing keywords
  noCategory: "Geen categorie beschikbaar",
  priority: "Prioriteit",
  searchCategory: "Zoeken",

  // create category file changing keywords
  createCategory: `Categorie maken`,

  // edit category file changing keywords
  editCategory: `Categorie bewerken`,
  saveCategory: `Categorie opslaan`,

  // License file changing keywords
  // License file changing keywords
  companyName: `Bedrijfsnaam`,
  licenseValidTill: `Licentie geldig tot`,
  numberOfUsers: `aantal gebruikers`,
  numberOfRooms: `Aantal kamers`,
  numberOfDevices: `Aantal apparaten`,
  numberOfInputs: `Antall innganger`,
  numberOfProcedures: `Aantal procedures`,
  uploadLicenseFile: `Licentiebestand uploaden`,
  chooseFile: `Kies bestand`,
  noFileChosen: `Geen bestand gekozen`,
  generatePreLicense: `Pre-licentie genereren`,

  // users list files changing keywords
  searchUser: `Zoeken...`,
  createUser: `Gebruikers aanmaken`,
  noUserAvailable: `Geen gebruiker beschikbaar`,
  roles: `Rollen`,
  rolesList: "Rollen lijst",
  groups: `Groepen`,
  groupsList: `Groepen lijst`,
  status: `Toestand`,

  // create user files changing keywords
  firstName: `Voornaam`,
  lastName: `Achternaam`,
  selectedRoles: `geselecteerde rollen`,
  selectedGroups: `geselecteerde groepen`,
  email: `E-mail`,
  phoneNumber: `Telefoonnummer`,
  whatsapp: `Whatsapp`,
  sameForWhatsapp: `Idem voor Whatsapp`,

  // edit user files changing keywords
  editUser: `bewerk gebruiker`,
  saveUser: `Gebruiker opslaan`,
  active: `Actief`,
  inActive: `InActief`,

  // role files changing keywords
  searchRole: `Zoeken...`,
  createRole: `Rol maken`,
  createGroup: `Groep maken`,
  noRoleAvailable: `Geen groep beschikbaar`,
  description: `Beschrijving`,

  // edit role file changing keywords
  editRole: `Rol bewerken`,
  saveRole: `Rol opslaan`,
  editGroup: `Groep bewerken`,
  saveGroup: `Groep opslaan`,

  // edit role file changing keywords
  deleteRole: `Rol verwijderen`,
  deleteGroup: `Groep verwijderen`,

  // rolepermissions file changing keywords
  submit: `Indienen`,
  rolePermissions: `Rollen Machtigingen`,
  rolespermissions: `Rollen Machtigingen`,

  // userRolepermissions file changing keywords
  userRolePermission: `Gebruiker/Groep`,

  // templates files changing keywords
  searchTemplates: `Zoek...`,
  // createTemplate: `Sjabloon maken`,
  createTemplate: `evenementsjabloon maken`,
  noTemplatesAvailable: `Geen sjablonen beschikbaar`,

  // edit template file keyword
  // editTemplate: `Sjabloon bewerken`,
  editTemplate: `evenementsjabloon bewerken`,
  saveTemplate: `Evenementsjabloon opslaan`,

  // create template file keyword
  // addProcedure: `Werkwijze toevoegen`,
  addProcedure: `Voeg evenement toe`,

  // delete template file keyword
  deleteTemplate: `gebeurtenissjabloon verwijderen`,

  // generall reports
  filter: `Filter`,
  export: `Exporteren`,
  normal: "Normaal",
  warning: "Waarschuwing",
  alarm: "Alarm",
  apply: "Toepassen",
  raised: "Verhoogd",
  accepted: "Geaccepteerd",
  resolved: "Opgelost",

  // alert demo keywords
  selectLocation: "Selecteer een locatie",
  duration: "duur",
  counter: "balie",
  selectedEvents: "geselecteerde evenementen",

  // report file keywords
  monthly: "Maandelijks",
  weekly: "Wekelijks",
  daily: "Dagelijks",
  sunday: `Zondag`,
  monday: "Maandag",
  tuesday: "Dinsdag",
  wednesday: "Woensdag",
  thursday: "Donderdag  ",
  friday: "Vrijdag",
  saturday: "Zaterdag",
  yearMonthDay: "Jaar, maand en dag",
  yearMonthEndDate: "Jaar, Maand en Einddatum",
  yearMonth: "Jaar en Maand",

  //groups file keywords
  noGroup: `geen groepen beschikbaar`,
  members: `Leden`,
};

export default nl;
