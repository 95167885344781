import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  // FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "../../Loader/Loader";
import {
  applicationCardStyle,
  recordInspectionTypo,
  textfield,
} from "../../../utils/muiComponentStyles";
import { savePaymentButton } from "./PaymentTypeStyle";
import { useDispatch, useSelector } from "../../../redux/store";
import {
  getSingleApplicationData,
  updatePaymentMethod,
  getApplicantStatus,
} from "../../../redux/slices/dashboard/applicantsSlice";
import ToasterContext from "../../../utils/context/toasterContext";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";

const projectTypeList = [
  { id: 1, name: "New Building" },
  { id: 2, name: "Addition" },
  { id: 3, name: "Alteration/Repair" },
  { id: 4, name: "Deck" },
  { id: 5, name: "Demolition" },
  { id: 6, name: "Driveway" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Pool" },
];

const Index = () => {
  const dispatch = useDispatch();
  let selectedApplicationInfo = JSON.parse(
    localStorage.getItem("selectedApplication")
  );
  const permissions = localStorage.getItem("permissions");
  const permissionArray = permissions ? permissions.split(",") : [];
  const [manipulationPermission, setManipulationPermission] = useState(false);
  const fireToasterContext = useContext(ToasterContext);
  const [loading, setLoading] = useState(true);
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [chequeNumberField, setChequeNumberField] = useState("");
  const [showChequeNumberField, setShowChequeNumberField] = useState(false);

  let { application } = useSelector((state) => state.applicantsReducer);

  useEffect(() => {
    setLoading(false);
    if (!permissionArray.includes("manipulate application details")) {
      setManipulationPermission(true);
    } else {
      setManipulationPermission(false);
    }
    dispatch(
      getSingleApplicationData(selectedApplicationInfo?.id, handleApiResponse)
    );
  }, []);

  useEffect(() => {
    if (application?.paymentDone) {
      setPaymentStatus("Paid");
      setPaymentMethod(application?.paymentMethod);
      if (application?.paymentMethod === "Cheque") {
        setShowChequeNumberField(true);
        setChequeNumberField(application?.chequeNumber);
      } else {
        setShowChequeNumberField(false);
      }
    } else {
      setPaymentStatus("Unpaid");
    }
  }, [application]);

  const handleApiResponse = (data) => {
    if (!data.status) {
      setLoading(false);
    }
  };

  const handleChangePaymentStatus = (event) => {
    setShowChequeNumberField(false);
    setPaymentMethod(null);
    setPaymentStatus(event.target.value);
  };

  const handleChangePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
    if (event.target.name === "Cheque") {
      setShowChequeNumberField(true);
    } else {
      setShowChequeNumberField(false);
    }
  };

  const handleCheckBookNumber = (event) => {
    const value = event.target.value;
    // Use a regular expression to only allow numbers
    const numericValue = value.replace(/[^0-9]/g, "");

    setChequeNumberField(numericValue);
  };

  const handleUpdatePaymentMethodResponse = (data) => {
    setSubmitButtonLoader(false);
    if (data.status) {
      dispatch(getSingleApplicationData(selectedApplicationInfo?.id));
      dispatch(getApplicantStatus(selectedApplicationInfo?.id));
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "payment method changed successfully"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data.message ? data.message : "Something went wrong"
      );
    }
  };

  const handleSavePayment = () => {
    if (showChequeNumberField && !chequeNumberField) {
      fireToasterContext.fireToasterHandler(
        false,
        "Please fill check number field"
      );
      return;
    }
    setSubmitButtonLoader(true);
    let finalPaymentStatus = {
      id: application?.id,
      status: paymentStatus === "Paid" ? true : false,
      paymentMethod: paymentStatus?.includes("Paid") && paymentMethod,
      chequeNumber: chequeNumberField,
    };
    dispatch(
      updatePaymentMethod(finalPaymentStatus, handleUpdatePaymentMethodResponse)
    );
  };

  return (
    <Box>
      <Card>
        <CardHeader
          disableTypography
          title={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>Permit Fee Detail</Box>
              <Box>
                Status: {selectedApplicationInfo?.status} | Permit Fee:
                {(selectedApplicationInfo?.totalAmount * 1)?.toFixed(2)}
              </Box>
            </Box>
          }
          sx={applicationCardStyle}
        />
        {loading ? (
          <Box
            sx={{
              height: 180,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              mr: "88px",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Box component="div" sx={{ my: 3, mx: 3 }}>
            <Typography sx={{ ...recordInspectionTypo, textAlign: "center" }}>
              Permit No: {selectedApplicationInfo?.permitNumber}
            </Typography>

            <Grid container spacing={2} sx={{ mt: 1, wordWrap: "break-word" }}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>Project Type</Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {
                    projectTypeList.find(
                      (project) =>
                        project.id === selectedApplicationInfo?.projectType
                    )?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>
                  Project Address
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {selectedApplicationInfo?.projectAddress}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>
                  Applicant Name
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {selectedApplicationInfo?.applicantName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Typography sx={recordInspectionTypo}>
                  Applicant Email
                </Typography>
                <Typography sx={recordInspectionTypo} variant="subText">
                  {selectedApplicationInfo?.applicantEmail}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        <Divider />
        <Box component="div" sx={{ my: 3, mx: 3 }}>
          <Typography sx={recordInspectionTypo}>Payment </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {/* <Typography>Payment Type</Typography> */}
              <FormControl>
                <Typography
                  id="demo-controlled-radio-buttons-group"
                  sx={recordInspectionTypo}
                  // sx={{ color: "green !important", fontWeight: "bold", }}
                >
                  Payment Status
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={paymentStatus}
                  onChange={handleChangePaymentStatus}
                >
                  <FormControlLabel
                    disabled={manipulationPermission}
                    value="Unpaid"
                    control={<Radio checked={paymentStatus === "Unpaid"} />}
                    label="Unpaid"
                  />
                  <FormControlLabel
                    disabled={manipulationPermission}
                    value="Paid"
                    control={<Radio checked={paymentStatus === "Paid"} />}
                    label="Paid"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              {paymentStatus?.includes("Paid") && (
                <Stack>
                  <FormControl>
                    <Typography
                      id="demo-controlled-radio-buttons-group"
                      sx={recordInspectionTypo}
                      // sx={{ color: "green !important", fontWeight: "bold", }}
                    >
                      Payment Method
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={paymentMethod}
                      onChange={handleChangePaymentMethod}
                    >
                      <FormControlLabel
                        disabled={manipulationPermission}
                        control={<Radio checked={paymentMethod === "Cash"} />}
                        label="Cash"
                        name="Cash"
                        value="Cash"
                      />
                      <FormControlLabel
                        disabled={manipulationPermission}
                        control={<Radio checked={paymentMethod === "Cheque"} />}
                        label="Check"
                        name="Cheque"
                        value="Cheque"
                      />
                      <FormControlLabel
                        disabled={manipulationPermission}
                        // value={paymentMethod === "Card"}
                        control={<Radio checked={paymentMethod === "Card"} />}
                        label="Credit Card"
                        name="Card"
                        value="Card"
                      />
                      <FormControlLabel
                        disabled={manipulationPermission}
                        // value={paymentMethod === "Paypal"}
                        control={<Radio checked={paymentMethod === "Paypal"} />}
                        label="Paypal"
                        name="Paypal"
                        value="Paypal"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              )}
              {showChequeNumberField && (
                <Stack sx={{ my: 2 }}>
                  <TextField
                    name="chequeNumber"
                    value={chequeNumberField}
                    onChange={handleCheckBookNumber}
                    label="Check Number"
                    inputProps={{ min: 1 }}
                    sx={textfield}
                    InputProps={{ readOnly: manipulationPermission }}
                  />
                </Stack>
              )}
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => handleSavePayment()}
              disabled={submitButtonLoader || manipulationPermission}
              sx={{
                ...savePaymentButton,
                [`@media screen and (max-width: 1024px)`]: {
                  width: "100%",
                },
                backgroundColor: submitButtonLoader
                  ? "grey"
                  : manipulationPermission || "#d52027",
                ":disabled": {
                  color: "white",
                  backgroundColor: manipulationPermission ? "#d52027" : "grey",
                },
              }}
            >
              Save
              {submitButtonLoader && <CircularProgressLoader />}
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Index;
