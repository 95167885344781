import { CircularProgress } from "@mui/material";
import React from "react";
const circularProgressLoader = {
  color: "#D52027",
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-12px",
  marginLeft: "-12px",
};

const CircularProgressLoader = () => {
  return <CircularProgress size={24} sx={circularProgressLoader} />;
};

export default CircularProgressLoader;
