import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Routes from "./routesList";
import { sidebarDrawer } from "../utils/muiComponentStyles";
import { useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SubMenu from "./SubMenu";

const useStyles = makeStyles({
  drawer: {
    width: "255px",
    justifyContent: "center",
  },
  drawerSmall: {
    width: "0px",
    justifyContent: "center",
  },
});

function Sidebar() {
  const testClasses = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("md"));
  const drawerClass = isMatch ? testClasses.drawer : testClasses.drawerSmall;
  return (
    <Box sx={sidebarDrawer}>
      <nav className={drawerClass}>
        <Drawer sx={sidebarDrawer} variant="permanent" anchor="left">
          <List sx={sidebarDrawer}>
            {Routes.map((obj, index) => (
              <SubMenu sidebarData={obj} key={index} />
            ))}
          </List>
        </Drawer>
      </nav>
    </Box>
  );
}

export default Sidebar;
