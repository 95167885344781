export const rolePermissionHeading = {
  "&.MuiTypography-rolePermissionTableHeadingButtonBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-rolePermissionTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-rolePermissionHeadingBoldButton": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-rolePermissionTableCell": {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-rolePermissionTableCellName": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};
export const tableHeadColor = {
  backgroundColor: "background.bgLayoutA",
};
export const tableHeadColor_minWidth = {
  backgroundColor: "background.bgLayoutA",
  minWidth: "250px",
};
export const miniScrollBarStyles = {
  // [`::-webkit-scrollbar`]: {
  //   width: "10px",
  //   height: "14px",
  // },
  // [`::-webkit-scrollbar-corner`]: {
  //   background: "rgba(0, 0, 0, 0.5)",
  // },
  // [`::-webkit-scrollbar-thumb`]: {
  //   backgroundColor: "#c5c5c5",
  //   borderRadius: "20px",
  //   border: "1px solid #f6f6f6",
  // },

  // [`::-webkit-scrollbar-track `]: {
  //   ["-webkit-box-shadow"]:
  //     "inset 0 0 8px rgba(0, 0, 0, 0.3)",
  //   // -webkit-border-radius: 5px;
  //   // border-radius: 5px;
  // },

  [`::-webkit-scrollbar`]: {
    width: "10px",
    height: "10px",
    backgroundColor: "#F5F5F5",
  },
  [`::-webkit-scrollbar-track `]: {
    [`-webkit-box-shadow`]: "inset 0 0 6px rgba(0,0,0,0.3)",
    borderRadius: "10px",
    backgroundColor: "#F5F5F5",
  },
  [`::-webkit-scrollbar-thumb`]: {
    borderRadius: "10px",
    [`-webkit-box-shadow`]: "inset 0 0 6px rgba(0,0,0,.3)",
    backgroundColor: "#c5c5c5",
  },
};
