import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dispatch } from "../store";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const initialState = {
  emailTrackList: [],
};

const slice = createSlice({
  name: "emailTrack",
  initialState,
  reducers: {
    getEmailTrackList(state, action) {
      state.emailTrackList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAllEmailTrackRecord(handleApiResponse) {
  let selectedApplication = JSON.parse(
    localStorage.getItem("selectedApplication")
  );
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/reports/email-reports?applicationId=${selectedApplication?.id}`,
        { headers }
      );
      if (handleApiResponse) {
        handleApiResponse(response.data);
      }
      dispatch(slice.actions.getEmailTrackList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}
