import React from "react";
import Index from "../../components/users/UpdateUsers";
function UpdateUserDetails() {
  return (
    <div>
      <Index />
    </div>
  );
}

export default UpdateUserDetails;
