import React from "react";
import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import poppinsRegular from "../../../assets/fonts/Poppins-Regular.ttf";
import poppinsBold from "../../../assets/fonts/Poppins-Bold.ttf";
import poppinsMedium from "../../../assets/fonts/Poppins-SemiBold.ttf";
import GreatVibeFont from "../../../assets/fonts/Great_Vibes/GreatVibes-Regular.ttf";

// ** custom fonts
Font.register({
  family: "PoppinsFamily",
  fonts: [
    {
      src: poppinsRegular,
    },
    {
      src: poppinsBold,
      fontWeight: "bold",
    },
    { src: poppinsMedium, fontWeight: "semibold" },
  ],
});
Font.register({
  family: "GreatVibeFamily",
  fonts: [
    {
      src: GreatVibeFont,
    },
  ],
});

// ** Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
  },
  minContainer: {
    margin: "30",
  },

  pdfText: {
    fontFamily: "PoppinsFamily",
    fontSize: "11px",
  },
  headingContainer: {
    textAlign: "center",
  },

  subjectSection: {
    textAlign: "left",
    marginTop: "8px",
    marginBottom: "5px",
  },
});

const buildingTypeList = [
  { id: 1, name: "Single Family" },
  { id: 2, name: "Duplex" },
  { id: 3, name: "Multi-Family" },
  { id: 4, name: "Commercial-Industrial" },
  { id: 5, name: "Garage/Shed" },
  { id: 6, name: "Does not apply" },
];

// ** Create Document Component
const MyDocument = ({ data }) => {
  function getBuildingType(selectedProject) {
    return buildingTypeList?.find((option) => option?.id === selectedProject)
      ?.name;
  }

  const dateTimeApiformat = (currentDate) => {
    // Split the input date into date and time components
    const [datePart] = currentDate.split("T");
    // Extract date components (year, month, day)
    const [yyyy, mm, dd] = datePart.split("-");
    // Extract time components (hours, minutes)
    // const [hh, min] = timePart.split(":");
    // // Determine whether it's AM or PM based on the hour
    // const isAM = parseInt(hh, 10) < 12;
    // // Format the date
    const formattedDate = `${mm}/${dd}/${yyyy}`;
    // // Format the time in 12-hour format with AM/PM
    // const formattedTime = `${hh % 12 || 12}:${min} ${isAM ? "AM" : "PM"}`;

    return `${formattedDate} `;
  };

  return (
    <Document pageMode="fullScreen" pageLayout="oneColumn">
      <Page size="A4" style={styles.page}>
        {/* main container */}
        <View style={styles.minContainer}>
          {/* heading container */}
          <View style={styles.headingContainer}>
            <Text
              style={{
                fontFamily: "PoppinsFamily",
                fontWeight: "medium",
                fontSize: "28px",
                textTransform: "uppercase",
                marginBottom: "7px",
              }}
            >
              Certificate of occupancy
            </Text>
            <Text
              style={{
                fontFamily: "PoppinsFamily",
                fontSize: "14px",
                fontWeight: "medium",
              }}
            >
              Town of Clarno
            </Text>
            <Text style={{ fontFamily: "PoppinsFamily", fontSize: "11px" }}>
              Building Inspection Department
            </Text>
          </View>

          {/* subject section */}

          <View style={styles.subjectSection}>
            <Text style={{ fontFamily: "PoppinsFamily", fontSize: "16px" }}>
              {dateTimeApiformat(data?.date)}
            </Text>
            <Text style={styles.pdfText}>{data?.ownerName}</Text>
            {/* <Text style={styles.pdfText}>Don Gerber</Text> */}
            {/* <Text style={styles.pdfText}>311 5th Street</Text> */}
            <Text
              style={{
                fontFamily: "PoppinsFamily",
                fontSize: "12px",
                width: "50%",
              }}
            >
              {data?.address},
            </Text>
            <Text
              style={{
                fontFamily: "PoppinsFamily",
                fontSize: "12px",
                width: "50%",
              }}
            >
              {
                JSON.parse(localStorage.getItem("selectedApplication"))
                  ?.ownerCity
              }{" "}
              ,
              {
                JSON.parse(localStorage.getItem("selectedApplication"))
                  ?.ownerState
              }
              ,
              {
                JSON.parse(localStorage.getItem("selectedApplication"))
                  ?.ownerZip
              }
            </Text>
          </View>

          {/* subject detail section */}
          <View style={styles.subjectSection}>
            <Text style={styles.pdfText}>
              Proiect Site: {data?.projectSite}
            </Text>
            <Text style={styles.pdfText}>
              Permit Number: {data?.permitNumber}
            </Text>
            <Text style={styles.pdfText}>
              Project Details: {getBuildingType(data?.buildingType)}
            </Text>
          </View>

          {/* subject notes */}
          <View style={styles.subjectSection}>
            <Text
              style={{
                fontFamily: "PoppinsFamily",
                fontSize: "11px",
              }}
            >
              {data?.description}
            </Text>
          </View>

          {/* bottom */}
          <View style={styles.subjectSection}>
            <Text
              style={{
                fontFamily: "PoppinsFamily",
                fontSize: "11px",
                marginBottom: "15px",
              }}
            >
              Sincerely,
            </Text>
          </View>

          <View style={styles.subjectSection}>
            <Text
              style={{
                fontFamily: "GreatVibeFamily",
                fontSize: "24px",
                fontWeight: "medium",
              }}
            >
              {/* {data?.generatedBy} */}
              Ryan Lindsey
            </Text>
          </View>

          {/* contact information */}
          <View style={styles.subjectSection}>
            {/* <Text style={styles.pdfText}>
              {data?.generatedBy}, {data?.generatedByRole}
            </Text>
            <Text style={styles.pdfText}>{data?.company}</Text>
            <Text style={styles.pdfText}>{data?.phoneNumber}</Text>
            <Text style={styles.pdfText}>{data?.email}</Text> */}
            <Text style={styles.pdfText}>Ryan Lindsey, Building Official</Text>
            <Text style={styles.pdfText}>3C Inspect LLC</Text>
            <Text style={styles.pdfText}>(608) 558-6055</Text>
            <Text style={styles.pdfText}>3Cinspect@gmail.com</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default MyDocument;
