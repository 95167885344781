import React from "react";
import Sidebar from "./Sidebar";
import { Grid } from "@mui/material";
import { layoutChildrenBox, leftbarStyles } from "../utils/muiComponentStyles";
import Topbar from "./Topbar";
import RightSidebar from "./RightSidebar";
import { useLocation } from "react-router-dom";

const index = ({ children }) => {
  const classes = leftbarStyles();
  const location = useLocation();

  return (
    <div className={classes.root}>
      <Topbar />
      <Sidebar />
      <Grid container sx={layoutChildrenBox}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {children}
        </Grid>
      </Grid>
      {(location.pathname === "/applications/application-detail" ||
        location.pathname === "/applications/record-inspections" ||
        location.pathname === "/applications/media" ||
        location.pathname === "/applications/building-placard" ||
        location.pathname === "/applications/occupency-letter" ||
        location.pathname === "/applications/permit-fee" ||
        location.pathname === "/applications/payment-mode" ||
        location.pathname === "/applications/email-tracked") && (
        <RightSidebar />
      )}
    </div>
  );
};

export default index;
