import React from "react";
import {
  GridView,
  ArrowUpward,
  ArrowDownward,
  Circle,
  Apartment,
  CreditCard,
  DoorFront,
  Email,
} from "@mui/icons-material";
import Roles from "../pages/Roles";
import Dashboard from "../pages/Dashboard";
import RolesPermissions from "../pages/RolesPermissions";
import UserRolesPermissions from "../pages/UserRolesPermissions";
import AllUsers from "../pages/users/AllUsers";
import Contractors from "../pages/contractor/Contractors";
// import Applicants from "../pages/applicants";
import Reports from "../pages/Reports";
import Municipalities from "../pages/Municipalities";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import EngineeringIcon from "@mui/icons-material/Engineering";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import EmailTracked from "../pages/EmailTracked";

const permissions = localStorage.getItem("permissions");
const isSuperAdmin = localStorage.getItem("isSuperAdmin");
const permissionsArray = permissions?.length > 0 ? permissions.split(",") : [];

const Routes = [
  {
    path: "/",
    sidebarName: "dashboard",
    component: Dashboard,
    icon: <GridView />,
  },
  {
    path: "/reports",
    sidebarName: "Permit Reports",
    components: Reports,
    icon: <InsertDriveFileRoundedIcon />,
  },
  // {
  //   path: "/applications",
  //   sidebarName: "Applications",
  //   component: Applicants,
  //   icon: <InsertDriveFileRoundedIcon />,
  // },
  {
    path: "/municipalities",
    sidebarName: "Municipalities",
    component: Municipalities,
    icon: <GavelRoundedIcon />,
  },
  {
    path: "/users",
    sidebarName: "Users",
    components: AllUsers,
    icon: <AccountCircleIcon />,
  },

  {
    sidebarName: "roles",
    icon: <GridView />,
    iconClosed: <ArrowUpward />,
    iconOpened: <ArrowDownward />,
    children: [
      {
        path: "/roles",
        sidebarName: "rolesList",
        components: Roles,
        icon: <Circle sx={{ height: 12, width: 12 }} />,
      },
      {
        path: "/roles-permissions",
        sidebarName: "rolePermissions",
        components: RolesPermissions,
        icon: <Circle sx={{ height: 12, width: 12 }} />,
      },
      {
        path: "/user-roles",
        sidebarName: "userRoles",
        components: UserRolesPermissions,
        icon: <Circle sx={{ height: 12, width: 12 }} />,
      },
    ],
  },
  {
    path: "/contractors",
    sidebarName: "Contractors",
    component: Contractors,
    icon: <EngineeringIcon />,
  },
];

export const RightSidebarData = [
  {
    path: "/applications/application-detail",
    sidebarName: "Application Details",
    component: Dashboard,
    icon: <DoorFront />,
  },
  {
    path: "/applications/record-inspections",
    sidebarName: "Record Inspections",
    component: Dashboard,
    icon: <InsertDriveFileRoundedIcon />,
  },
  {
    path: "/applications/media",
    sidebarName: "Media",
    component: Dashboard,
    icon: <InsertDriveFileRoundedIcon />,
  },
  {
    path: "/applications/building-placard",
    sidebarName: "Building Placard",
    component: Dashboard,
    icon: <Apartment />,
  },
  {
    path: "/applications/occupency-letter",
    sidebarName: "Occupency Letter",
    component: Dashboard,
    icon: <InsertDriveFileRoundedIcon />,
  },
  {
    path: "/applications/permit-fee",
    sidebarName: "Permit Fee",
    component: Dashboard,
    icon: <CreditCard />,
  },
  {
    path: "/applications/payment-mode",
    sidebarName: "Payment",
    component: Dashboard,
    icon: <CreditCard />,
  },
  {
    path: "/applications/email-tracked",
    sidebarName: "Email Record",
    component: EmailTracked,
    icon: <Email />,
  },
];

let routesWithPermissions = [];
if (permissionsArray.includes("show dashboard")) {
  routesWithPermissions.push({
    path: "/",
    sidebarName: "dashboard",
    component: Dashboard,
    icon: <GridView />,
  });
}

if (permissionsArray.includes("show monthly reports")) {
  routesWithPermissions.push({
    path: "/reports",
    sidebarName: "Permit Reports",
    components: Reports,
    icon: <InsertDriveFileRoundedIcon />,
  });
}

if (permissionsArray.includes("show municipalities")) {
  routesWithPermissions.push({
    path: "/municipalities",
    sidebarName: "Municipalities",
    component: Municipalities,
    icon: <GavelRoundedIcon />,
  });
}

if (permissionsArray.includes("show users")) {
  routesWithPermissions.push({
    path: "/all-users",
    sidebarName: "Users",
    components: AllUsers,
    icon: <AccountCircleIcon />,
  });
}
// if (permissionsArray.includes("show applications")) {
//   routesWithPermissions.push({
//     path: "/applications",
//     sidebarName: "Applications",
//     component: Applicants,
//     icon: <InsertDriveFileRoundedIcon />,
//   });
// }

if (permissionsArray.includes("show contractors")) {
  routesWithPermissions.push({
    path: "/contractors",
    sidebarName: "Contractors",
    component: Contractors,
    icon: <EngineeringIcon />,
  });
}

let navigations;
if (isSuperAdmin === "true") {
  navigations = Routes;
} else {
  navigations = routesWithPermissions;
}

export default navigations;
