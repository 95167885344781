import React from "react";

const NotMatch = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src="/assets/error/404.svg" alt="error" width={450} height={450} />
    </div>
  );
};

export default NotMatch;
