const nb = {
  // side nav item changing keywords
  groupPermissions: `Gruppetillatelser`,
  userGroup: `Brukergruppe`,
  dashboard: `Dashbord`,
  // alarms: `Alarmer`,
  alarms: `Varsler`,
  rooms: `Rom`,
  // procedures: `Prosedyrer`,
  procedures: `arrangementer`,
  // templates: `Maler`,
  templates: `Eventmaler`,
  devices: `Enheter`,
  devicesList: `Enhetsliste`,
  inputs: `Inndataenheter`,
  outputs: `Utgangsenheter`,
  categories: `Kategorier`,
  // reports keywords
  logs: `Historie`,
  history: `Historie`,
  generateReport: `Generer rapporter`,
  alertsdemo: `Varslingsdemo`,
  profiles: `Locaties`,
  profile: `Locatie`,
  license: `Tillatelse`,
  users: `Brukere`,
  logout: `Logg ut`,
  busyMode: `Opptatt modus`,
  contactType: `Kontakt type`,
  busyModeTime: `Opptatt modus tid`,
  selectedLocations: `Valgte steder`,

  // Routes changing keywords
  eventtemplates: `Eventmaler`,
  inputdevices: `Inndataenheter`,
  outputdevices: `Utgangsenheter`,
  historyreports: `Historierapporter`,
  generatereports: `Generer rapporter`,
  alertdemo: `Varslingsdemo`,
  locations: `Steder`,
  groupspermissions: `GRUPPER TILLATELSER`,
  usergroups: `Brukergrupper`,
  alerts: `Varsler`,
  allusers: `Alle brukere`,
  reports: `Rapporter`,
  userRoles: `Brukerroller`,
  userroles: `Brukerroller`,

  // generics keywords
  name: `Navn`,
  room: `Rom`,
  selectAll: `Velg alle`,
  selectedInputs: `Valgte innganger:`,
  selectedOutputs: `Valgte utganger:`,
  note: `Merk:`,
  areYouSure: `Er du sikker på at du vil slette`,
  cancel: `Avbryt`,
  event: `Begivenhet`,

  // dashboard Keywords for changing
  welcome: `Velkommen til`,
  projectName: `Soft Circles Template`,

  // dashboard cards keywords for changing
  firstCard: `Første kort`,
  secondCard: `Andre kort`,
  thirdCard: `Tredje kort`,
  fourthCard: `Fjerde kort`,
  fifthCard: `Femte kort`,
  sixthCard: `Sjette kort`,

  // alarms table keywords for changing
  // generateAlarm: `Generer alarmer`,
  generateAlarm: `Generer varsel`,
  noRoomAlarm: `Ingen romalarmprosedyrer er tilgjengelige`,
  activeDay: `Aktiv dag`,
  activeNight: `Aktiv natt`,
  procedureActive: `Prosedyren er aktiv`,
  noAlarmProcedure: `Ingen alarmprosedyre er tilgjengelig`,
  clearAlarms: `Fjern alarmer`,
  updateProcedure: `Oppdater prosedyreinnstilling`,

  // logs page changing keywords
  searchLog: `Søk...`,
  startDate: `Start dato`,
  startTime: `Starttid`,
  endDate: `Slutt dato`,
  endTime: `Sluttid`,
  search: `Søk`,
  date: `Dato`,
  text: `Tekst`,
  category: `Kategori`,
  generalLogs: `Generelle logger`,
  roomLogs: `Romlogger`,
  alertReceivedLogs: `Logger mottatt varsel`,
  lockLogs: `Lås logger`,
  result: "Resultat",
  helpLogs: `Hjelpelogger`,

  // procedures page changing keyword
  searchProcedure: `Søk...`,
  // createProcedure: `Lag prosedyre`,
  createProcedure: `opprette arrangement`,
  noProcedure: `Ingen prosedyre tilgjengelig`,

  // create procedure file changes keywords
  createProcedureTitle: `Create Procedure`,
  alarmSound: `Alarm lyd`,
  alarmIntensity: `Alarmintensitet`,
  audioProcedure: `Lyd prosedyre`,
  selectedAllInput: `Valgte alle innganger:`,
  selectedAllOutput: `Valgte alle utganger:`,
  rules: `Regler`,
  start: `Start`,
  end: `Slutt`,
  smsNotification: `SMS-varsling`,
  whatsappNotification: `Whatsapp-varsling`,
  emailNotification: `E-postvarsling`,
  sendNotificationAfter: "Send melding etter",
  // edit procedure file changes keywords
  editProcedure: `Rediger hendelse`,
  saveProcedure: `Lagre hendelse`,

  // delete procedure file changes keywords
  // deleteProcedure: `Slett prosedyre`,
  deleteProcedure: `Slett hendelse`,

  // profile page changing keywords
  searchProfile: `Søk...`,
  createProfile: `Opprett en profil`,
  createLocation: `Opprett plassering`,
  dayToNight: `Dag til natt`,
  nightToDay: `Natt til dag`,
  noProfile: `Ingen Locaties tilgjengelig`,
  parentProfileId: "Foreldre plassering",
  escalationGroup: `Eskaleringsgruppe`,
  groupEscalationTimeInMinutes: `Eskaleringstid`,
  addEscalationGroup: `Legg til eskaleringsgruppe`,
  removeEscalationGroup: `Fjern eskaleringsgruppe`,

  // create profile page changing keywords
  selectedRooms: "Utvalgte rom:",
  dayToNightTime: `Dag til natt`,
  nightToDayTime: `Natt til dag tid`,
  secondryEscalationProfileId: `
  Sekundær eskaleringsLocatie-ID`,
  tertiaryEscalationProfileId: `
  Tertiær eskaleringsLocatie-ID`,
  secondryEscalationTimeInMinutes: `
  Eskaleringstid`,
  tertiaryEscalationTimeInMinutes: `
  Eskaleringstid`,
  selectedUsers: `Utvalgte brukere`,

  // edit profile page changing keywords
  editProfile: "Rediger profil",
  saveLocation: "Lagre plassering",
  editLocation: "Rediger plassering",
  selectRooms: `Velg rom`,

  // delete profile page changing keywords
  deleteProfile: "Slett profil",
  deleteLocation: "Slett plassering",
  deleteLocationOf: "Slett plassering av",

  // room page list
  noRoom: `ingen rom tilgjengelig`,
  searchRoom: `Søk...`,
  createRoom: `Lag rom`,
  createroom: `Lag rom`,
  rtspURL: `Rtsp URL`,
  alarmURL: `Alarm URL`,

  // create room changing keywords
  cameraViewAddress: `Kameravisningsadresse`,
  doorTopic: `Dør tema`,
  doorPayload: `Dør nyttelast`,
  timeTriggerEnabled: `Tidstrigger aktivert`,
  timeTriggerTime: `Utløsertid`,
  timeTriggerTrigger: `Utløsernavn`,
  audioSetupEnabled: `Lydoppsett aktivert`,
  cameraIpAddress: `kameraets IP-adresse`,
  cameraUsername: `kamera brukernavn`,
  cameraPassword: `kamerapassord`,
  selectedProfiles: `Valgte steder`,
  escalationTime: "Eskaleringstid",

  // update room file changing keywords
  editRoom: `Oppdater rommet`,
  saveRoom: `Lagre rom`,

  // delete room changing keywords
  deleteRoom: `Slett rom`,

  // device listing page changing keywords
  searchDevices: `Søk...`,
  createDevice: `Opprett enhet`,
  deviceType: `Enhetstype`,
  noDeviceAvailable: `Ingen enhet tilgjengelig`,

  // create device file changing keywords
  type: `Type`,
  ipAddress: `IP adresse`,
  userName: `brukernavn`,
  password: `Passord`,
  topic: `Emne`,

  // edit device file changing keywords
  editDevice: `Rediger enhet`,
  saveDevice: `Lagre enhet`,

  // delete device file changing keywords
  deleteDevice: `Slett enhet`,

  // inputs file changing keywords
  searchInputs: `Søk...`,
  createInput: `opprette inndataenhet`,
  triggers: `Utløsere`,
  noInput: `Ingen inngang tilgjengelig`,

  // create inputs changing keywords
  trigger: `Utløsere`,
  device: `Enhet`,

  // edit input file changing keywords
  editInput: `redigere inndataenhet`,
  saveInput: `Lagre inndataenhet`,

  // delete input file changing keywords
  deleteInput: `slett inndataenhet`,

  // output file changing keywords
  searchOutput: `Søk...`,
  createOutput: `Opprett utdataenhet`,
  noOutput: `Ingen utgang tilgjengelig`,

  // edit output file changing keywords
  editOutput: `redigere utdataenhet`,
  saveOutput: `Lagre utdataenhet`,

  // delete output file changing keywords
  deleteOutput: `Slett utdataenhet`,

  // category file changing keywords\
  noCategory: `Ingen kategori tilgjengelig`,
  priority: "Prioritet",
  searchCategory: "Søk",
  high: "Høy",
  medium: `Medium`,
  low: `Lav`,

  // create category file changing keywords
  createCategory: `Opprett kategori`,

  // edit category file changing keywords
  editCategory: `Rediger kategori`,
  saveCategory: `Lagre kategori`,

  // License file changing keywords
  companyName: `Selskapsnavn`,
  licenseValidTill: `Lisens gyldig til`,
  numberOfUsers: `antall brukere`,
  numberOfRooms: `Antall rom`,
  numberOfDevices: `Antall enheter`,
  numberOfInputs: `Antall innganger`,
  numberOfProcedures: `Antall prosedyrer`,
  uploadLicenseFile: `Last opp lisensfil`,
  chooseFile: `Velg Fil`,
  noFileChosen: `Ingen fil valgt`,
  generatePreLicense: `Generer forhåndslisens`,

  // users list files changing keywords
  searchUser: `Søk...`,
  createUser: `Opprett brukere`,
  noUserAvailable: `Ingen bruker tilgjengelig`,
  roles: `Roller`,
  rolesList: "Rolleliste",
  groups: `Grupper`,
  groupsList: `Gruppeliste`,
  status: `Status`,

  // create user files changing keywords
  firstName: `Fornavn`,
  lastName: `Etternavn`,
  selectedRoles: `Utvalgte roller`,
  selectedGroups: `Utvalgte grupper`,
  email: `E-post`,
  phoneNumber: `Telefonnummer`,
  whatsapp: `Whatsapp`,
  sameForWhatsapp: `Samme for WhatsApp`,

  // edit user files changing keywords
  editUser: `Rediger bruker`,
  saveUser: `Lagre bruker`,
  active: `Aktiv`,
  inActive: `Inaktiv`,

  // role files changing keywords
  searchRole: `Zoek...`,
  createRole: `Opprett rolle`,
  createGroup: `Lag gruppe`,
  noRoleAvailable: `Ingen gruppe tilgjengelig`,
  description: `Beskrivelse`,

  // edit role file changing keywords
  editRole: `Rediger rolle`,
  saveRole: `Lagre rolle`,
  editGroup: `Rediger gruppe`,
  saveGroup: `Lagre gruppe`,

  // delete role file changing keywords
  deleteRole: `Slett rolle`,
  deleteGroup: `Slett gruppe`,

  // rolepermissions file changing keywords
  submit: `Sende inn`,
  rolePermissions: `Rolletillatelser`,
  rolespermissions: `Rolletillatelser`,

  // userRolepermissions file changing keywords
  userRolePermission: `Bruker/gruppe`,

  // templates files changing keywords
  searchTemplates: `Søk...`,
  // createTemplate: `Lag mal`,
  createTemplate: `lage hendelsesmal`,
  noTemplatesAvailable: `Ingen maler tilgjengelig`,

  //edit template file keyword
  // edit templates to edit event template
  // editTemplate: `Rediger mal`,
  editTemplate: `rediger hendelsesmal`,
  saveTemplate: `Lagre hendelsesmal`,

  // create template file keyword
  // addProcedure: `Legg til prosedyre`,
  addProcedure: `Legg til hendelse`,

  // delete template file keyword
  // deleteTemplate: `Slett mal`,
  deleteTemplate: `slett hendelsesmal`,

  // generall reports
  filter: `Filter`,
  export: `Eksport`,
  normal: "Normal",
  warning: "Advarsel",
  alarm: "Alarm",
  apply: "Søke om",
  raised: "Oppvokst",
  accepted: "Akseptert",
  resolved: "Løst",

  // alert demo keywords
  selectLocation: "Velg Sted",
  duration: "varighet",
  counter: "disk",
  selectedEvents: "Utvalgte hendelser",

  // report file keywords
  monthly: "Månedlig",
  weekly: "Ukentlig",
  daily: "Daglig",
  sunday: `Søndag`,
  monday: "Mandag",
  tuesday: "Tirsdag",
  wednesday: "onsdag",
  thursday: "Torsdag",
  friday: "Fredag",
  saturday: "Lørdag",
  yearMonthDay: "År, måned og dag",
  yearMonthEndDate: "År, måned og sluttdato",
  yearMonth: "År og Måned",

  //groups file keywords
  noGroup: `ingen grupper tilgjengelig`,
  members: `Medlemmer`,
};

export default nb;
