import React from "react";
import Index from "../components/applicationRecord/applicationDetailForm/ApplicationDetailForm";
const ApplicationDetail = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default ApplicationDetail;
