import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Container,
  Box,
  FormControlLabel,
  CardContent,
  Card,
  Typography,
  DialogActions,
  Button,
  Grid,
  Divider,
  Paper,
  Autocomplete,
  Chip,
  CardHeader,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useLocales from "../../hooks/useLocales";
import { createUser } from "../../redux/slices/UserSlice";
import { getRolesList } from "../../redux/slices/RoleSlice";
import { useDispatch, useSelector } from "../../redux/store";
import toasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  applicationCardStyle,
  styledChip,
  textfield,
  userHeading,
} from "../../utils/muiComponentStyles";
import { getAllMuncipalities } from "../../redux/slices/muncipalitySlice";

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userObj, setUserObj] = useState({
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "+1",
  });
  const fireToasterContext = useContext(toasterContext);
  const [loader, setLoader] = useState(false);
  const { translate } = useLocales();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectAllRoles, setSelectAllRoles] = useState(false);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
  const [selectAllMunicipalities, setSelectAllMunicipalities] = useState(false);
  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    dispatch(getRolesList());
    dispatch(getAllMuncipalities());
  }, []);

  useEffect(() => {
    //Cleaning up states each time dialog opens
    setUserObj({});
  }, []);

  let { muncipalityList } = useSelector((state) => state.muncipalityReducer);
  let { rolesList } = useSelector((state) => state.roleReducer);

  const handleToggleSelectAllRoles = () => {
    setSelectAllRoles((prev) => {
      if (!prev) setSelectedRoles([...rolesList]);
      else setSelectedRoles([]);
      return !prev;
    });
  };

  const handleToggleSelectAllMuni = () => {
    setSelectAllMunicipalities((prev) => {
      if (!prev) setSelectedMunicipalities([...muncipalityList]);
      else setSelectedMunicipalities([]);
      return !prev;
    });
  };

  const handleCreateUser = () => {
    setLoader(true);
    let roleIds = selectedRoles.map((el) => el.id);
    let muncipalityIds = selectedMunicipalities.map((el) => el.id);
    let finalUserObj = { ...userObj };
    finalUserObj.roleIds = roleIds;
    finalUserObj.muncipalityIds = muncipalityIds;
    dispatch(createUser(finalUserObj, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "User created"
      );
      navigate("/users");
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleChangeUserData = (event) => {
    let userObjTemp = { ...userObj };
    userObjTemp[event.target.name] = event.target.value;
    setUserObj(userObjTemp);
  };

  return (
    <Container>
      <Card fullWidth>
        <CardHeader
          disableTypography
          title="Create User Detail"
          sx={applicationCardStyle}
        />
        <CardContent sx={{ mt: 2, mx: 2 }}>
          <ValidatorForm
            style={{ width: "100%" }}
            onSubmit={handleCreateUser}
            onError={(errors) => {
              console.log(errors),
                fireToasterContext.fireToasterHandler(
                  false,
                  "Please fill all the required fields"
                );
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextValidator
                  sx={textfield}
                  fullWidth
                  id="outlined-basic"
                  label={translate("firstName")}
                  name="firstName"
                  variant="outlined"
                  type="text"
                  value={userObj?.firstName}
                  onChange={handleChangeUserData}
                  validators={["required"]}
                  errorMessages={["First name is required"]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  sx={textfield}
                  fullWidth
                  id="outlined-basic"
                  label={translate("lastName")}
                  name="lastName"
                  variant="outlined"
                  type="text"
                  value={userObj?.lastName}
                  onChange={handleChangeUserData}
                  validators={["required"]}
                  errorMessages={["Last name is required"]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  sx={textfield}
                  fullWidth
                  id="outlined-basic"
                  label={translate("phoneNumber")}
                  name="phoneNumber"
                  variant="outlined"
                  type="number"
                  value={userObj?.phoneNumber}
                  onChange={handleChangeUserData}
                  validators={["required"]}
                  errorMessages={["Phone Number is required"]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  sx={textfield}
                  fullWidth
                  id="outlined-basic"
                  label={translate("password")}
                  name="password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  value={userObj?.password}
                  onChange={handleChangeUserData}
                  validators={["required"]}
                  errorMessages={["Please Enter the Password"]}
                  helperText="Password must be at least 8 characters long"
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    autocomplete: "new-password",
                    form: {
                      autocomplete: "off",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  id="autocomplete_with_select_all"
                  multiple
                  noOptionsText="No options available"
                  options={rolesList}
                  getOptionLabel={(option) => option.name}
                  limitTags={10}
                  disableCloseOnSelect
                  selectOnFocus
                  freeSolo={false}
                  value={selectedRoles}
                  onChange={(_e, value, reason) => {
                    if (reason === "clear" || reason === "removeOption")
                      setSelectAllRoles(false);
                    if (
                      reason === "selectOption" &&
                      value.length === rolesList.length
                    )
                      setSelectAllRoles(true);
                    setSelectedRoles(value);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                        sx={{ ...styledChip, backgroundColor: "#ebebeb" }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      variant="outlined"
                      label={translate("roles")}
                      sx={textfield}
                      validators={["required"]}
                      errorMessages={["User Role is required"]}
                      value={selectedRoles}
                    />
                  )}
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                      <>
                        {rolesList.length < 1 ? (
                          <Typography variant="inputOutputList">
                            No Roles Found
                          </Typography>
                        ) : (
                          <Paper {...restPaperProps}>
                            <Box
                              onMouseDown={(e) => e.preventDefault()} // prevent blur
                              pl={1.5}
                              py={0.5}
                            >
                              <FormControlLabel
                                onClick={(e) => {
                                  e.preventDefault(); // prevent blur
                                  handleToggleSelectAllRoles();
                                }}
                                sx={{
                                  color: "#404041",
                                }}
                                label={translate("selectAll")}
                                control={
                                  <Checkbox
                                    id="select-all-checkbox"
                                    checked={selectAllRoles}
                                    sx={{
                                      color: "#404041", // Change the color of the Checkbox when unchecked
                                      "&.Mui-checked": {
                                        color: "#404041", // Change the color of the Checkbox when checked
                                      },
                                    }}
                                  />
                                }
                              />
                            </Box>
                            <Divider />
                            <Typography
                              variant="autoCompleteTypo"
                              sx={userHeading}
                            >
                              {children}
                            </Typography>
                          </Paper>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  id="autocomplete_for_municipalities"
                  multiple
                  noOptionsText="No options available"
                  options={muncipalityList} // Use the municipalities list as options
                  getOptionLabel={(option) => option.name}
                  limitTags={10}
                  disableCloseOnSelect
                  selectOnFocus
                  freeSolo={false}
                  value={selectedMunicipalities}
                  onChange={(_e, value) => {
                    if (value.length !== muncipalityList.length) {
                      setSelectAllMunicipalities(false);
                    }
                    if (value.length === muncipalityList.length) {
                      setSelectAllMunicipalities(true);
                    }
                    if (value.length === 0) {
                      setSelectAllMunicipalities(false);
                    }
                    setSelectedMunicipalities(value);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                        sx={{ ...styledChip, backgroundColor: "#ebebeb" }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      variant="outlined"
                      label={translate("Municipalities")}
                      sx={textfield}
                      // validators={["required"]}
                      // errorMessages={["Municipality is required"]}
                      value={selectedMunicipalities}
                    />
                  )}
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                      <>
                        {muncipalityList.length < 1 ? (
                          <Typography variant="inputOutputList">
                            No Municipalities Found
                          </Typography>
                        ) : (
                          <Paper {...restPaperProps}>
                            <Box
                              onMouseDown={(e) => e.preventDefault()}
                              pl={1.5}
                              py={0.5}
                            >
                              <FormControlLabel
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleToggleSelectAllMuni();
                                }}
                                sx={{
                                  color: "#404041",
                                }}
                                label={translate("selectAll")}
                                control={
                                  <Checkbox
                                    id="select-all-municipalities-checkbox"
                                    checked={selectAllMunicipalities}
                                    sx={{
                                      color: "#404041",
                                      "&.Mui-checked": {
                                        color: "#404041",
                                      },
                                    }}
                                  />
                                }
                              />
                            </Box>
                            <Divider />
                            <Typography
                              variant="autoCompleteTypo"
                              sx={userHeading}
                            >
                              {children}
                            </Typography>
                          </Paper>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
            </Grid>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                px: 0,
                mt: 2,
              }}
            >
              <Button
                disableRipple
                type="submit"
                sx={
                  loader
                    ? {
                        width: "177px",
                        padding: "10px",
                        background: "#979797",
                        borderRadius: "10px",
                        "&:hover": { backgroundColor: "#979797" },
                        // "&:disabled": {
                        //   backgroundColor: "#979797",
                        // },
                      }
                    : {
                        width: "177px",
                        padding: "10px",
                        backgroundColor: "background.bgLayoutB",
                        borderRadius: "10px",
                        "&:hover": {
                          backgroundColor: "background.bgLayoutB",
                          boxShadow: 2,
                        },
                        "&:disabled": {
                          backgroundColor: "#979797",
                        },
                      }
                }
              >
                <Typography sx={userHeading} variant="userHeadingBoldButton">
                  create User
                </Typography>
                {loader ? <CircularProgressLoader /> : ""}
              </Button>
            </DialogActions>
          </ValidatorForm>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AddUser;
