export const applicationTitleTypography = {
  "&.MuiTypography-root": {
    marginTop: "30px",
    fontSize: "40px",
    fontWeight: "bold",
    color: "#414042",
  },
};

export const autocompleteMuncipality = {
  "&.MuiAutocomplete-root": {
    marginTop: "20px",
    backgroundColor: "white",
    color: "#414042",
    border: "1px solid gray",
    ".Mui-focused fieldset": {
      border: "1px solid gray",
    },
  },
};

export const textfieldStyle = {
  width: "100%",
  marginTop: "20px",
  border: "none",
  backgroundColor: "white",
  input: {
    color: "#414042",
    fontFamily: "Poppins",
    "&::placeholder": {
      color: "#414042",
      // opacity: 1,
    },
  },
  label: {
    color: "#414042 !important",
    fontFamily: "Poppins",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      fontFamily: "Poppins",
      border: "none",
    },
    "& label": {
      color: "red",
    },
    "&:hover fieldset": {
      borderColor: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "none",
    },
  },
};

export const applicationWisconsinTypography = {
  "&.MuiTypography-root": {
    marginTop: "30px",
    fontSize: "30px",
    fontWeight: "bold",
    color: "#414042",
  },
};

export const applicationWisconsinTypographySubtitle = {
  "&.MuiTypography-root": {
    marginTop: "30px",
    fontSize: "15px",
    color: "#414042",
  },
};

export const applicationFormTitleField = {
  "&.MuiFormControl-root": {
    width: "100%",
    height: "auto",
    backgroundColor: "white",
    fontSize: "10px",
    label: {
      color: "#414042 !important",
      fontFamily: "Poppins",
    },
    input: {
      "&[type=number]": {
        mozAppearance: "textfield",
      },
      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "&::placeholder": {
        color: "#414042",
        // opacity: 1,
        // fontWeight: "bold",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "none",
      },
    },
  },
};

export const applicationFormJobDescriptionField = {
  "&.MuiFormControl-root": {
    width: "100%",
    backgroundColor: "white",
    label: {
      color: "#414042 !important",
      fontFamily: "Poppins",
    },
    textarea: {
      "&::placeholder": {
        color: "#414042 !important",
        // opacity: 1,
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "none",
      },
    },
  },
};

export const textfieldDropdownStyle = {
  width: "100%",
  border: "none",
  backgroundColor: "white",
  input: {
    color: "#414042",
    fontFamily: "Poppins",
    "&::placeholder": {
      color: "#414042",
      // opacity: 1,
    },
  },
  label: {
    color: "#414042 !important",
    fontFamily: "Poppins",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      fontFamily: "Poppins",
      border: "none",
    },
    "& label": {
      color: "red",
    },
    "&:hover fieldset": {
      borderColor: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "none",
    },
  },
};

export const applicationFormSearchButton = {
  "&.MuiButton-root": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#32363F",
    width: "100%",
    height: "100%",
    color: "white",
    gap: "10px",
    fontFamily: "Poppins-semibold",
    fontSize: "16px",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "black",
      boxShadow: 2,
      transition: ".7s ease-in",
    },
  },
};
export const applicationDetailFormSearchButton = {
  "&.MuiButton-root": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#32363F",
    width: "100%",
    height: "100%",
    color: "white",
    gap: "10px",
    fontFamily: "Poppins-semibold",
    fontSize: "16px",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "black",
      boxShadow: 2,
      transition: ".7s ease-in",
    },
  },
};

export const applicationFormCreateButton = {
  "&.MuiButton-root": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#32363F",
    height: "100%",
    color: "white",
    gap: "10px",
    fontFamily: "Poppins-semibold",
    fontSize: "14px",
    // marginTop: "30px",
    borderRadius: 0,
    " &:hover": {
      backgroundColor: "black",
      boxShadow: 2,
      transition: ".7s ease-in",
    },
  },
};

export const tableHeadCell = {
  "&.MuiTableCell-root": {
    fontSize: "15px",
    color: "#414042",
    fontWeight: 600,
  },
};

export const tableDataRow = {
  "&.MuiTableRow-root": {
    cursor: "pointer",
    color: "yellow",
    "&:last-child": {
      borderBottom: "2px solid black",
    },
  },
};

export const tableDataCell = {
  "&.MuiTableCell-root": {
    fontSize: "15px",
    color: "#414042",
    fontWeight: 500,
  },
};

export const applicationFormSubmitButton = {
  "&.MuiButton-root": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#32363F",
    height: "100%",
    color: "white",
    gap: "10px",
    padding: "10px",
    fontFamily: "Poppins-semibold",
    fontSize: "14px",
    marginTop: "30px",
    borderRadius: 0,
    width: "200px",
    "&:hover": {
      backgroundColor: "black",
      boxShadow: 2,
      transition: ".7s ease-in",
    },
    "&:disabled": {
      backgroundColor: "grey",
      boxShadow: 2,
      transition: ".7s ease-in",
    },
  },
};

export const saveButton = {
  "&.MuiButton-root": {
    height: "100%",
    color: "white",
    padding: "10px",
    backgroundColor: "#D52027",
    fontFamily: "Poppins-semibold",
    fontSize: "14px",
    // marginTop: "30px",
    borderRadius: 0,
    width: "200px",
    "&:hover": {
      backgroundColor: "red",
      transition: ".7s ease-in",
      boxShadow: 2,
    },
  },
};
export const cancelButton = {
  "&.MuiButton-root": {
    backgroundColor: "#32363F",
    height: "100%",
    color: "white",
    padding: "10px",
    fontFamily: "Poppins-semibold",
    fontSize: "14px",
    // marginTop: "30px",
    borderRadius: 0,
    width: "200px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "black",
      boxShadow: 2,
      transition: ".7s ease-in",
    },
  },
};
