import React from "react";
import Index from "../../components/contractors/Index";
const Contractors = () => {
  return (
    <React.Fragment>
      <Index />
    </React.Fragment>
  );
};

export default Contractors;
