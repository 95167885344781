import React from "react";
import Index from "../../src/components/applicationRecord/emailTracked/emailLayout/Index";

const EmailTracked = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default EmailTracked;
