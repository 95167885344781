import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dispatch } from "../store";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const initialState = {
  muncipalityList: [],
  applicationFormMunicipalityList: [],
};

const slice = createSlice({
  name: "muncipality",
  initialState,
  reducers: {
    getMuncipalityList(state, action) {
      state.muncipalityList = action.payload.data;
    },
    getApplicationFormMunicipalityList(state, action) {
      state.applicationFormMunicipalityList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAllMuncipalities(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/muncipalities`,
        { headers }
      );
      if (handleApiRes) {
        handleApiRes(response.data);
      }
      dispatch(slice.actions.getMuncipalityList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getApplicationFormMunicipalities(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/muncipalities/municipalities-for-application-form`,
        { headers }
      );
      if (handleApiRes) {
        handleApiRes(response.data);
      }
      dispatch(slice.actions.getApplicationFormMunicipalityList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function createMuncipality(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/muncipalities`,
        data,
        { headers }
      );
      if (handleApiRes) {
        handleApiRes(response.data);
      }

      dispatch(getAllMuncipalities());
    } catch (error) {
      if (handleApiRes) {
        handleApiRes(error.response.data);
      }
    }
  };
}
export function updateMuncipality(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/muncipalities?id=${data.id}`,
        data,
        { headers }
      );
      if (handleApiRes) {
        handleApiRes(response.data);
      }

      dispatch(getAllMuncipalities());
    } catch (error) {
      if (handleApiRes) {
        handleApiRes(error.response.data);
      }
    }
  };
}

export function deleteMuncipality(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/muncipalities?id=${data}`,
        { headers }
      );
      if (handleApiRes) {
        handleApiRes(response.data);
      }

      dispatch(getAllMuncipalities());
    } catch (error) {
      if (handleApiRes) {
        handleApiRes(error.response.data);
      }
    }
  };
}
